import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './QuestionSetInfoPage2.module.css'; // CSS Modules import
import { getToken } from '../../../utils/auth';
import answerOnIcon from '../../../assets/images/questiongenerator/bulb_on.png';
import answerOffIcon from '../../../assets/images/questiongenerator/bulb_off.png';
import OnStatus from '../../../assets/images/questiongenerator/on.png';
import OffStatus from '../../../assets/images/questiongenerator/off.png';
import SpinningLogo from '../../../components/SpinningLogo';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const QuestionSetInfoPage2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);
  const [category, setCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAnswers, setShowAnswers] = useState(true);
  const [questionCount, setQuestionCount] = useState(3);
  
  const data = location.state || {
    coverLetter: '',
    coverLetterId: null,
    skills: [],
    experiences: []
  };

  const categories = [
    '자기소개', '직무 관련', '기술 및 역량', '경력 및 경험',
    '지원 동기 및 회사 관련 질문', '학력 및 교육 배경', '팀워크 및 커뮤니케이션',
    '문제 해결 및 사고력', '윤리 및 가치관', '리더십 및 팀 관리'
  ];

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleGenerateQuestions = async () => {
    if (!category) {
      alert('질문 카테고리를 선택해주세요.');
      return;
    }

    setLoading(true); // 로딩 시작
    const token = getToken();

    try {
      const requestData = {
        coverLetter: location.state?.coverLetter || '',
        coverLetterId: location.state?.coverLetterId || null,
        skills: location.state?.skills || [],
        experiences: location.state?.experiences || [],
        category: category,
        questionCount: questionCount
      };

      const response = await fetch(`${BACKEND_URL}/tool2/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify(requestData)
      });

      const result = await response.json();
      
      if (!response.ok) {
        throw new Error(result.message || '질문 생성에 실패했습니다.');
      }

      if (result.success === false) {
        if (result.message === '토큰이 부족합니다. 충전이 필요합니다.') {
          navigate('/payment');
        }
        throw new Error(result.message);
      }
      
      setQuestionsAndAnswers(result.questions || []);
    } catch (error) {
      console.error('Error generating questions:', error);
      alert(error.message || '질문 생성 중 오류가 발생했습니다.');
    } finally {
      setLoading(false); // 로딩 종료
    }
  };

  const handleSaveQuestions = async () => {
    const token = getToken();

    try {
      if (!category || !Array.isArray(questionsAndAnswers) || questionsAndAnswers.length === 0) {
        alert('저장할 데이터가 없습니다.');
        return;
      }

      const response = await fetch(`${BACKEND_URL}/tool2/save`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify({
          category: category,
          questions: questionsAndAnswers,
        }),
      });

      const result = await response.json();

      if (result.success) {
        alert('질문이 성공적으로 저장되었습니다.');
        navigate('/mypage/profile');
      } else {
        alert(result.message || '질문 저장에 실패했습니다.');
      }
    } catch (error) {
      console.error('Error saving questions:', error);
      alert('질문 저장에 실패했습니다.');
    }
  };

  const handleBackClick = () => {
    navigate('/tools/questions-setInfo', { state: data });
  };

  const toggleAnswersVisibility = () => {
    setShowAnswers(!showAnswers);
  };

  return (
    <div className={styles.questionSetInfoPage}>
      <h2>면접 예상질문 생성기</h2>
      <p>질문 카테고리 선택 (2/2 Step)</p>
      <div className={styles.categorySelector}>
        <select value={category} onChange={handleCategoryChange} className={styles.select}>
          <option value="">생성하고 싶은 질문 유형을 선택해주세요.</option>
          {categories.map((cat, index) => (
            <option key={index} value={cat}>{cat}</option>
          ))}
        </select>
      </div>
      <div className={styles.countSelector}>
        <p>생성할 질문 개수</p>
        <div className={styles.countButtons}>
          <button 
            className={`${styles.countButton} ${questionCount === 3 ? styles.selected : ''}`}
            onClick={() => setQuestionCount(3)}
          >
            3개
          </button>
          <button 
            className={`${styles.countButton} ${questionCount === 6 ? styles.selected : ''}`}
            onClick={() => setQuestionCount(6)}
          >
            6개
          </button>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button onClick={handleBackClick} className={styles.backButton}>
          돌아가기
        </button>
        <button onClick={handleGenerateQuestions} disabled={loading || !category} className={styles.generateButton}>
          질문 생성하기
        </button>
      </div>
      {questionsAndAnswers.length > 0 && (
        <div className={styles.questionsContainer}>
          <div className={styles.toggleButtonContainer}>
            <img
              src={showAnswers ? OnStatus : OffStatus}
              alt="Toggle Answers"
              className={styles.toggleAnswersButton}
              onClick={toggleAnswersVisibility}
            />
          </div>
          {questionsAndAnswers.map((qa, index) => (
            <div key={index} className={styles.questionAnswerPair}>
              <p className={styles.question}><strong>{index + 1}. {qa.question}</strong></p>
              <div className={styles.answer}>
                <img src={showAnswers ? answerOnIcon : answerOffIcon} alt="Answer Icon" className={styles.answerIcon} />
                {showAnswers && <span>{qa.answer}</span>}
              </div>
            </div>
          ))}
          <button onClick={handleSaveQuestions} className={styles.saveButton}>질문 저장</button>
        </div>
      )}
      
      {loading && (
        <div className={styles.loadingOverlay}>
          <SpinningLogo text="면접 예상 질문을 생성하고 있습니다..." />
        </div>
      )}
    </div>
  );
};

export default QuestionSetInfoPage2;
