import React, { useState } from 'react';
import { ResponsiveRadar } from '@nivo/radar';
import { motion } from 'framer-motion';
import { FaStar, FaExclamationTriangle, FaLightbulb, FaRobot } from 'react-icons/fa';
import './ResumeEvaluationResult.css';
import axios from 'axios';
import { getToken } from '../../../utils/auth';

const ResumeEvaluationResult = ({ evaluation, readOnly, onReset }) => {
  const [isSaved, setIsSaved] = useState(false);
  const [saving, setSaving] = useState(false);

  const scoreCategories = [
    { key: 'relevance', label: '관련성', color: '#4CAF50' },
    { key: 'achievements', label: '성과', color: '#2196F3' },
    { key: 'skills', label: '기술', color: '#9C27B0' },
    { key: 'education', label: '교육', color: '#FF9800' },
    { key: 'presentation', label: '구성', color: '#F44336' }
  ];

  const aiScores = [
    { name: '맞춤법', score: Math.round((evaluation.scores.presentation / 15) * 100), color: '#4CAF50' },
    { name: '표절', score: Math.round((evaluation.scores.relevance / 25) * 100), color: '#2196F3' },
    { name: '평가', score: Math.round((evaluation.scores.achievements / 25) * 100), color: '#9C27B0' }
  ];

  const handleSave = async () => {
    if (isSaved) return;
    
    setSaving(true);
    try {
      const token = getToken();
      
      // _id를 제외한 데이터만 전송
      const { _id, ...evaluationWithoutId } = evaluation;
      
      // 전송할 데이터 확인
      console.log('Sending evaluation data:', evaluationWithoutId);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/resume-evaluation/save`,
        { evaluation: evaluationWithoutId },
        { headers: { 
          'x-auth-token': token,
          'Content-Type': 'application/json'
        }}
      );

      if (response.data.success) {
        setIsSaved(true);
        alert('평가가 저장되었습니다.');
      }
    } catch (error) {
      console.error('평가 저장 실패:', error.response?.data || error);
      alert(error.response?.data?.error || '평가 저장에 실패했습니다.');
    } finally {
      setSaving(false);
    }
  };

  return (
    <motion.div className="evaluation-result-container">
      <div className="result-header">
        <div className="header-content">
          <h2>{evaluation.title}</h2>
          <p className="field-text">{evaluation.field}</p>
        </div>
        <div className={`fit-badge ${evaluation.fitForRole.replace(/\s+/g, '-').toLowerCase()}`}>
          {evaluation.fitForRole}
        </div>
      </div>

      <div className="ai-score-section">
        <div className="section-header">
          <FaRobot className="section-icon" />
          <h3>AI 종합 분석</h3>
        </div>
        <div className="score-circles">
          {aiScores.map((item, index) => (
            <motion.div 
              key={item.name} 
              className="score-circle-wrapper"
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.2 * index, duration: 0.5 }}
            >
              <div className="circle-progress" style={{
                background: `conic-gradient(
                  ${item.color} ${item.score * 3.6}deg,
                  #f0f0f0 ${item.score * 3.6}deg
                )`
              }}>
                <div className="circle-inner">
                  <span className="score-value">{item.score}%</span>
                </div>
              </div>
              <span className="score-label">{item.name}</span>
            </motion.div>
          ))}
        </div>
      </div>

      <div className="scores-section">
        <div className="detailed-scores">
          {scoreCategories.map((category, index) => (
            <motion.div 
              key={category.key}
              className="score-bar-container"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.1 * index }}
            >
              <div className="score-label">
                <span>{category.label}</span>
                <span className="score-value">{evaluation.scores[category.key]}</span>
              </div>
              <div className="score-bar-wrapper">
                <motion.div 
                  className="score-bar"
                  style={{ backgroundColor: category.color }}
                  initial={{ width: 0 }}
                  animate={{ width: `${(evaluation.scores[category.key] / 25) * 100}%` }}
                  transition={{ duration: 0.8, delay: 0.2 * index }}
                />
              </div>
            </motion.div>
          ))}
        </div>
        
        <div className="total-score-section">
          <div className="total-score-card">
            <h3>종합 평가</h3>
            <div className="score-display">
              <div className="score-number">{evaluation.totalScore}</div>
              <div className="score-label">점</div>
            </div>
          </div>
          <div className="radar-chart-container">
            <ResponsiveRadar
              data={[{
                category: "평가 점수",
                관련성: evaluation.scores.relevance,
                성과: evaluation.scores.achievements,
                기술: evaluation.scores.skills,
                교육: evaluation.scores.education,
                구성: evaluation.scores.presentation,
              }]}
              keys={scoreCategories.map(c => c.label)}
              indexBy="category"
              maxValue={25}
              margin={{ top: 50, right: 60, bottom: 40, left: 60 }}
              curve="linearClosed"
              borderWidth={2}
              gridLevels={5}
              colors={scoreCategories.map(c => c.color)}
              theme={{
                background: "transparent",
                textColor: "#4A5568",
                fontSize: 12
              }}
            />
          </div>
        </div>
      </div>

      <div className="feedback-grid">
        <motion.div 
          className="feedback-card strengths"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <div className="card-header">
            <FaStar className="card-icon" />
            <h3>강점</h3>
          </div>
          <ul>
            {evaluation.strengths.map((strength, index) => (
              <motion.li 
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3 + index * 0.1 }}
              >
                {strength}
              </motion.li>
            ))}
          </ul>
        </motion.div>

        <motion.div 
          className="feedback-card improvements"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
        >
          <div className="card-header">
            <FaExclamationTriangle className="card-icon" />
            <h3>개선사항</h3>
          </div>
          <ul>
            {evaluation.areasForImprovement.map((area, index) => (
              <motion.li 
                key={index}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.4 + index * 0.1 }}
              >
                {area}
              </motion.li>
            ))}
          </ul>
        </motion.div>

        <motion.div 
          className="feedback-card suggestions"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          <div className="card-header">
            <FaLightbulb className="card-icon" />
            <h3>개선 제안</h3>
          </div>
          <ul>
            {evaluation.suggestedImprovements.map((suggestion, index) => (
              <motion.li 
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 + index * 0.1 }}
              >
                {suggestion}
              </motion.li>
            ))}
          </ul>
        </motion.div>
      </div>

      {!readOnly && (
        <div className="action-buttons">
          <motion.button 
            className="primary-button save-button"
            onClick={handleSave}
            disabled={saving || isSaved}
          >
            {isSaved ? '저장됨' : (saving ? '저장 중...' : '평가 저장하기')}
          </motion.button>

          <motion.button 
            className="secondary-button reset-button"
            onClick={onReset}
          >
            새로운 평가하기
          </motion.button>
        </div>
      )}
    </motion.div>
  );
};

export default ResumeEvaluationResult;
