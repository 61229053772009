import React, { useState, useEffect } from 'react';
import { 
  FaHome, 
  FaSearch, 
  FaComments, 
  FaUser, 
  FaBell, 
  FaSignOutAlt, 
  FaBars, 
  FaChevronDown, 
  FaChevronUp, 
  FaCoins, 
  FaSignInAlt, 
  FaUserCog,
  FaTools,
  FaPencilAlt,
  FaCheckSquare,
  FaCamera,
  FaQuestionCircle,
  FaProjectDiagram,
  FaHandshake,
  FaUserTie,
  FaFileAlt
} from 'react-icons/fa';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Sidebar.css';
import AlertPopup from './AlertPopup';
import { getToken, removeToken } from '../utils/auth';
import namelogo from '../assets/images/logos/namelogo.svg';
import starLogo from '../assets/images/logos/starlogo.png';

// 환경 변수에서 백엔드 URL 가져오기
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [isToolsOpen, setIsToolsOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [tokens, setTokens] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = getToken();
    setIsLoggedIn(!!token);

    if (token) {
      const fetchUserData = async () => {
        try {
          const response = await fetch(`${BACKEND_URL}/user`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': token,
            },
          });

          if (response.ok) {
            const data = await response.json();
            setUsername(data.username);
            setTokens(data.tokens);
          } else {
            console.error('Failed to fetch user data');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      fetchUserData();
    }
  }, [location]);

  const toggleToolsMenu = () => {
    setIsToolsOpen(!isToolsOpen);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/auth/logout`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': getToken(),
        },
      });

      if (response.ok) {
        removeToken();
        alert('로그아웃되었습니다.');
        navigate('/');
      } else {
        alert('로그아웃 실패. 다시 시도해주세요.');
      }
    } catch (error) {
      console.error('Error during logout:', error);
      alert('서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  const handleTokenClick = () => {
    navigate('/payment');
  };

  // 현재 경로에 따라 아이콘 색상을 결정하는 함수 수정
  const getIconColor = (path) => {
    if (path === '/tools') {
      return location.pathname.startsWith('/tools/') ? '#2E74FF' : 'white';
    }
    if (path === '/main') {
      return location.pathname === '/main' ? '#2E74FF' : 'white';
    }
    return location.pathname.startsWith(path) ? '#2E74FF' : 'white';
  };

  // 취업도구 메뉴 클릭 핸들러 수정
  const handleToolsClick = () => {
    setIsToolsOpen(!isToolsOpen);
  };

  // 현재 경로가 해당 메뉴의 경로와 일치하는지 확인하는 함수
  const isActive = (path) => {
    if (path === '/tools') {
      return false;
    }
    if (path === '/main') {
      return location.pathname === '/main';
    }
    return location.pathname.startsWith(path);
  };

  return (
    <div className={`sidebar ${isOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <div className="sidebar-logo">
        <img 
          src={starLogo} 
          alt="Menu" 
          onClick={toggleSidebar}
          className="sidebar-menu-icon"
        />
        {isOpen && (
          <img 
            src={namelogo} 
            alt="Jobmaker" 
            onClick={() => navigate('/main')} 
            style={{ cursor: 'pointer', height: '30px', marginLeft: '10px' }}
          />
        )}
      </div>
      <ul className="sidebar-menu">
        <li>
          <Link to="/main" className={isActive('/main') ? 'active' : ''}>
            <FaHome style={{ color: getIconColor('/main') }} />
            {isOpen && <span>홈</span>}
          </Link>
        </li>
        <li>
          <div 
            className={`sidebar-menu-item ${isActive('/tools') ? 'active' : ''}`} 
            onClick={handleToolsClick}
          >
            <FaTools style={{ color: getIconColor('/tools') }} />
            {isOpen && <span>취업도구</span>}
            {isOpen && (isToolsOpen ? <FaChevronUp /> : <FaChevronDown />)}
          </div>
          {isToolsOpen && (
            <ul className="sidebar-submenu">
              <li>
                <Link 
                  to="/tools/resume-builder" 
                  className={location.pathname === '/tools/resume-builder' ? 'active' : ''}
                >
                  <FaFileAlt />
                  {isOpen && <span>이력서 생성기</span>}
                </Link>
              </li>
              <li>
                <Link 
                  to="/tools/resume-generator/setinfo"
                  className={location.pathname.startsWith('/tools/resume-generator') ? 'active' : ''}
                >
                  <FaPencilAlt />
                  {isOpen && <span>자소서 생성기</span>}
                </Link>
              </li>
              <li>
                <Link 
                  to="/tools/resume-evaluation"
                  className={location.pathname === '/tools/resume-evaluation' ? 'active' : ''}
                >
                  <FaCheckSquare />
                  {isOpen && <span>자소서 평가</span>}
                </Link>
              </li>
              <li>
                <Link 
                  to="/tools/photo-generator"
                  className={location.pathname === '/tools/photo-generator' ? 'active' : ''}
                >
                  <FaCamera />
                  {isOpen && <span>증명사진 생성기</span>}
                </Link>
              </li>
              <li>
                <Link 
                  to="/tools/questions-setInfo"
                  className={location.pathname === '/tools/questions-setInfo' ? 'active' : ''}
                >
                  <FaQuestionCircle />
                  {isOpen && <span>면접예상질문 생성기</span>}
                </Link>
              </li>
              <li>
                <Link 
                  to="/tools/tech-tree-generator"
                  className={location.pathname === '/tools/tech-tree-generator' ? 'active' : ''}
                >
                  <FaProjectDiagram />
                  {isOpen && <span>테크트리 생성기</span>}
                </Link>
              </li>
              <li>
                <Link 
                  to="/tools/job-matching"
                  className={location.pathname === '/tools/job-matching' ? 'active' : ''}
                >
                  <FaHandshake />
                  {isOpen && <span>잡 매칭</span>}
                </Link>
              </li>
              <li>
                <Link 
                  to="/tools/ai-interviewer"
                  className={location.pathname === '/tools/ai-interviewer' ? 'active' : ''}
                >
                  <FaUserTie />
                  {isOpen && <span>AI 면접관</span>}
                </Link>
              </li>
            </ul>
          )}
        </li>
        <li>
          <Link to="/recruit/info" className={isActive('/recruit') ? 'active' : ''}>
            <FaSearch style={{ color: getIconColor('/recruit') }} />
            {isOpen && <span>채용정보</span>}
          </Link>
        </li>
        <li>
          <Link to="/community" className={isActive('/community') ? 'active' : ''}>
            <FaComments style={{ color: getIconColor('/community') }} />
            {isOpen && <span>커뮤니티</span>}
          </Link>
        </li>
        <li>
          <div 
            className={`sidebar-menu-item ${isPopupOpen ? 'active' : ''}`} 
            onClick={togglePopup}
          >
            <FaBell style={{ color: isPopupOpen ? '#2E74FF' : 'white' }} />
            {isOpen && <span>나의 알림</span>}
          </div>
        </li>
      </ul>
      <div className="sidebar-user-info">
        {isLoggedIn ? (
          <>
            <div className="sidebar-username">
              <FaUser />
              <span>{username} 님</span>
            </div>
            <div className={`sidebar-tokens ${location.pathname === '/payment' ? 'active' : ''}`} onClick={handleTokenClick}>
              <FaCoins />
              <span>{tokens}</span>
            </div>
            <div className="sidebar-settings">
              <Link 
                to="/mypage" 
                className={`sidebar-mypage-btn ${location.pathname === '/mypage' ? 'active' : ''}`}
              >
                <FaUserCog />
                {isOpen && <span>마이페이지</span>}
              </Link>
              <button className="sidebar-logout-btn" onClick={handleLogout}>
                <FaSignOutAlt />
                {isOpen && <span>로그아웃</span>}
              </button>
            </div>
          </>
        ) : (
          <button className={`sidebar-login-btn ${location.pathname === '/login' ? 'active' : ''}`} onClick={() => navigate('/login')}>
            <FaSignInAlt />
            {isOpen && <span>로그인하여 취직하러가기!</span>}
          </button>
        )}
      </div>
      {isPopupOpen && <AlertPopup onClose={togglePopup} />}
    </div>
  );
};

export default Sidebar;
