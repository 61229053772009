import React, { useState, useEffect } from 'react';
import { 
  FaUsers, 
  FaComments, 
  FaFlag,
  FaUser, 
  FaSignOutAlt, 
  FaBars, 
  FaChartLine,
  FaEnvelope,
  FaBullhorn,
  FaChevronUp,
  FaChevronDown
} from 'react-icons/fa';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './AdminSidebar.css';
import AlertPopup from './AlertPopup';
import { getToken, removeToken } from '../utils/auth';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const AdminSidebar = ({ isOpen, toggleSidebar }) => {
  const [isStatsOpen, setStatsOpen] = useState(false);
  const [isCommunicationOpen, setCommunicationOpen] = useState(false);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = getToken();
        const response = await fetch(`${BACKEND_URL}/user`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUsername(data.username);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/auth/logout`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': getToken(),
        },
      });

      if (response.ok) {
        removeToken();
        alert('로그아웃되었습니다.');
        navigate('/');
      } else {
        alert('로그아웃 실패. 다시 시도해주세요.');
      }
    } catch (error) {
      console.error('Error during logout:', error);
      alert('서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  return (
    <div className={`sidebar ${isOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <div className="sidebar-logo">
        <FaBars onClick={toggleSidebar} />
        {isOpen && (
          <span onClick={() => navigate('/admin')} style={{ cursor: 'pointer' }}>
            관리자 페이지
          </span>
        )}
      </div>

      <ul className="sidebar-menu">
        {/* 구현된 기능들 */}
        <li>
          <Link to="/admin/users" className={location.pathname === '/admin/users' ? 'active' : ''}>
            <FaUsers />
            {isOpen && <span>사용자 관리</span>}
          </Link>
        </li>
        <li>
          <Link to="/admin/community" className={location.pathname === '/admin/community' ? 'active' : ''}>
            <FaComments />
            {isOpen && <span>게시글 관리</span>}
          </Link>
        </li>
        <li>
          <Link to="/admin/reports" className={location.pathname === '/admin/reports' ? 'active' : ''}>
            <FaFlag />
            {isOpen && <span>신고 관리</span>}
          </Link>
        </li>

        {/* 통계 메뉴 */}
        <li>
          <div className="sidebar-menu-item" onClick={() => setStatsOpen(!isStatsOpen)}>
            <FaChartLine />
            {isOpen && <span>통계</span>}
            {isOpen && (isStatsOpen ? <FaChevronUp /> : <FaChevronDown />)}
          </div>
          {isStatsOpen && (
            <ul className="sidebar-submenu">
              <li>
                <Link to="/admin/stats/visitors">
                  {isOpen && <span>유입 통계</span>}
                </Link>
              </li>
              <li>
                <Link to="/admin/stats/members">
                  {isOpen && <span>신규 회원</span>}
                </Link>
              </li>
              <li>
                <Link to="/admin/stats/revenue">
                  {isOpen && <span>매출 통계</span>}
                </Link>
              </li>
            </ul>
          )}
        </li>

        {/* 고객소통 메뉴 */}
        <li>
          <div className="sidebar-menu-item" onClick={() => setCommunicationOpen(!isCommunicationOpen)}>
            <FaEnvelope />
            {isOpen && <span>고객소통</span>}
            {isOpen && (isCommunicationOpen ? <FaChevronUp /> : <FaChevronDown />)}
          </div>
          {isCommunicationOpen && (
            <ul className="sidebar-submenu">
              <li>
                <Link to="/admin/communication/customer-email" className={location.pathname === '/admin/communication/customer-email' ? 'active' : ''}>
                  {isOpen && <span>고객메일 전송</span>}
                </Link>
              </li>
              <li>
                <Link to="/admin/communication/notice">
                  {isOpen && <span>공지사항 등록</span>}
                </Link>
              </li>
              <li>
                <Link to="/admin/communication/notification">
                  {isOpen && <span>고객알림 전송</span>}
                </Link>
              </li>
            </ul>
          )}
        </li>
      </ul>

      <div className="sidebar-user-info">
        <div className="sidebar-username">
          <FaUser />
          <span>{username} 님</span>
        </div>
        <div className="sidebar-settings">
          <button className="sidebar-logout-btn" onClick={handleLogout}>
            <FaSignOutAlt />
            {isOpen && <span>로그아웃</span>}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;