import React from 'react';
import starLogo from '../assets/images/logos/starlogo.png';
import './SpinningLogo.css';

const SpinningLogo = ({ text }) => {
    return (
        <div className="spinning-logo-container">
            <img src={starLogo} alt="Loading" className="spinning-logo" />
            {text && <p className="loading-text">{text}</p>}
        </div>
    );
};

export default SpinningLogo; 