export const userDetailSchema = {
  major: '',
  experience: '',
  employmentType: '',
  preferredRegion: '',
  careerStatus: '',
  education: '',
  yearsOfExperience: '',
  recentPosition: '',
  recentCompany: '',
  careerHighlights: [],
  skills: [],
  certifications: [],
  minSalary: 20000000,
  maxSalary: 45000000,
  startDate: new Date().toISOString().split('T')[0],
  workHours: '09:00-18:00',
  remoteWork: '유연 근무 불가능',
  portfolioLinks: [],
  isNewGraduate: false,
  graduationYear: '',
  gpa: '',
  majorExperience: ''
};

export const initialUserDetailState = { ...userDetailSchema };

export const validateUserDetail = (userDetail) => {
  const errors = {};
  
  if (!userDetail.major?.trim()) errors.major = '관심분야를 입력해주세요';
  if (!userDetail.experience?.trim()) errors.experience = '경력을 선택해주세요';
  if (!userDetail.employmentType?.trim()) errors.employmentType = '고용 형태를 선택해주세요';
  if (!userDetail.preferredRegion?.trim()) errors.preferredRegion = '선호 지역을 선택해주세요';
  if (!userDetail.education?.trim()) errors.education = '최종 학력을 선택해주세요';
  
  if (userDetail.careerStatus === '경력') {
    if (!userDetail.yearsOfExperience?.trim()) {
      errors.yearsOfExperience = '경력 연수를 입력해주세요';
    }
    if (!userDetail.recentPosition?.trim()) {
      errors.recentPosition = '최근 직책을 입력해주세요';
    }
    if (!userDetail.recentCompany?.trim()) {
      errors.recentCompany = '최근 회사를 입력해주세요';
    }
  }
  
  if (userDetail.isNewGraduate) {
    if (!userDetail.graduationYear?.trim()) {
      errors.graduationYear = '졸업연도를 입력해주세요';
    }
    if (!userDetail.gpa?.trim()) {
      errors.gpa = '학점을 입력해주세요';
    }
  }
  
  if (userDetail.maxSalary <= userDetail.minSalary) {
    errors.salary = '최대 급여는 최소 급여보다 커야 합니다';
  }
  
  if (userDetail.skills?.length === 0) {
    errors.skills = '최소 1개 이상의 스킬을 입력해주세요';
  }
  
  return errors;
};

export const sanitizeUserDetail = (data) => {
  return {
    ...data,
    major: data.major?.trim(),
    experience: data.experience?.trim(),
    employmentType: data.employmentType?.trim(),
    preferredRegion: data.preferredRegion?.trim(),
    careerStatus: data.careerStatus?.trim(),
    education: data.education?.trim(),
    yearsOfExperience: data.yearsOfExperience?.trim(),
    recentPosition: data.recentPosition?.trim(),
    recentCompany: data.recentCompany?.trim(),
    skills: data.skills?.filter(skill => skill.trim() !== '') || [],
    certifications: data.certifications?.filter(cert => cert.trim() !== '') || [],
    careerHighlights: data.careerHighlights?.filter(highlight => highlight.trim() !== '') || [],
    portfolioLinks: data.portfolioLinks?.filter(link => link.trim() !== '') || [],
    minSalary: Number(data.minSalary) || 20000000,
    maxSalary: Number(data.maxSalary) || 45000000
  };
};
