import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import AdminSidebar from '../components/AdminSidebar';

const AdminLayout = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="admin-layout">
      <AdminSidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className={`page-content ${isOpen ? 'content-open' : 'content-closed'}`}>
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;
