import '../styles/ResumeTemplate.css';

const ResumeTemplate = ({ type, data }) => {
  if (!data || !data.basicInfo || !type) {
    return <div>이력서 데이터가 올바르지 않습니다.</div>;
  }

  // 필수 필드 검증
  const requiredFields = ['name', 'email', 'phone'];
  const missingFields = requiredFields.filter(field => !data.basicInfo[field]);
  
  if (missingFields.length > 0) {
    return <div>필수 정보가 누락되었습니다: {missingFields.join(', ')}</div>;
  }

  const today = new Date().toLocaleDateString('ko-KR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <div className="resume-template">
      <div className={`resume-container ${type}`}>
        <div className="resume-title">
          <h1>이 력 서</h1>
          <p className="date">작성일: {today}</p>
        </div>

        <table className="info-table">
          <tbody>
            <tr>
              <td rowSpan={4} className="photo-cell">
                {data.basicInfo.photo ? (
                  <img 
                    src={data.basicInfo.photo} 
                    alt="증명사진" 
                    className="profile-photo"
                  />
                ) : (
                  <div className="photo-placeholder">3x4</div>
                )}
              </td>
              <th width="15%">성명</th>
              <td width="35%">{data.basicInfo.name || ''}</td>
              <th width="15%">생년월일</th>
              <td width="35%">{data.basicInfo.birthDate || ''}</td>
            </tr>
            <tr>
              <th>휴대전화</th>
              <td>{data.basicInfo.phone || ''}</td>
              <th>이메일</th>
              <td>{data.basicInfo.email || ''}</td>
            </tr>
            <tr>
              <th>주소</th>
              <td colSpan={3}>{data.basicInfo.address || ''}</td>
            </tr>

          </tbody>
        </table>

        {data.education && data.education.length > 0 && (
          <div className="section">
            <h2>학력사항</h2>
            <table className="content-table">
              <thead>
                <tr>
                  <th width="25%">재학기간</th>
                  <th width="30%">학교명</th>
                  <th width="25%">전공</th>
                  <th width="20%">졸업여부</th>
                </tr>
              </thead>
              <tbody>
                {data.education.map((edu, index) => (
                  <tr key={index}>
                    <td>{edu.startDate} ~ {edu.endDate || '현재'}</td>
                    <td>{edu.school}</td>
                    <td>{edu.major}</td>
                    <td>{edu.status || '졸업'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {data.experience && data.experience.length > 0 && (
          <div className="section">
            <h2>경력사항</h2>
            <table className="content-table">
              <thead>
                <tr>
                  <th width="25%">근무기간</th>
                  <th width="25%">회사명</th>
                  <th width="20%">직위</th>
                  <th width="30%">담당업무</th>
                </tr>
              </thead>
              <tbody>
                {data.experience.map((exp, index) => (
                  <tr key={index}>
                    <td>{exp.startDate} ~ {exp.endDate || '현재'}</td>
                    <td>{exp.company}</td>
                    <td>{exp.position}</td>
                    <td className="text-left">{exp.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {data.certificates && data.certificates.length > 0 && (
          <div className="section">
            <h2>자격증</h2>
            <table className="content-table">
              <thead>
                <tr>
                  <th width="35%">자격증명</th>
                  <th width="35%">발급기관</th>
                  <th width="30%">취득일자</th>
                </tr>
              </thead>
              <tbody>
                {data.certificates.map((cert, index) => (
                  <tr key={index}>
                    <td>{cert.name}</td>
                    <td>{cert.issuer}</td>
                    <td>{cert.acquisitionDate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {data.skills && data.skills.length > 0 && (
          <div className="section">
            <h2>보유기술</h2>
            <table className="content-table">
              <thead>
                <tr>
                  <th width="30%">분야</th>
                  <th width="70%">기술스택</th>
                </tr>
              </thead>
              <tbody>
                {data.skills.map((skill, index) => (
                  <tr key={index}>
                    <td>{skill.category}</td>
                    <td className="text-left">{skill.items.join(', ')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResumeTemplate; 