import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import './SavedCommon.css';

const SavedInterviews = () => {
  const [interviews, setInterviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const mockInterviews = [
      { title: '인터뷰 1', date: '2024.07.20' },
      { title: '인터뷰 2', date: '2024.07.20' },
    ];

    setTimeout(() => {
      setInterviews(mockInterviews);
      setLoading(false);
    }, 1000);
  }, []);

  if (loading) {
    return <div className="loading-state">로딩 중...</div>;
  }

  return (
    <div className="saved-container">
      <div className="saved-header">
        <Link to="/mypage" className="back-link">
          <FaArrowLeft className="back-icon" />
          <span>돌아가기</span>
        </Link>
        <h2>AI 면접 기록</h2>
      </div>
      
      {interviews.length === 0 ? (
        <div className="empty-state">
          <p>저장된 면접 기록이 없습니다.</p>
        </div>
      ) : (
        <div className="item-grid">
          {interviews.map((interview, index) => (
            <div className="item-card" key={index}>
              <div className="card-header">
                <span className="card-title">{interview.title}</span>
              </div>
              <span className="card-date">{interview.date}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SavedInterviews;
