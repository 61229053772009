import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken } from '../../utils/auth';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const CommunityManagement = () => {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [error, setError] = useState(null);

    const fetchPosts = async (page) => {
        try {
            const response = await axios.get(`${BACKEND_URL}/admin/posts?page=${page}`, {
                headers: {
                    'x-auth-token': getToken()
                }
            });
            setPosts(response.data.posts);
            setTotalPages(response.data.totalPages);
        } catch (err) {
            setError('게시글을 불러오는데 실패했습니다.');
            console.error(err);
        }
    };

    const handleDelete = async (postId) => {
        if (window.confirm('이 게시글을 삭제하시겠습니까?')) {
            try {
                await axios.delete(`${BACKEND_URL}/admin/posts/${postId}`, {
                    headers: {
                        'x-auth-token': getToken()
                    }
                });
                fetchPosts(currentPage);
            } catch (err) {
                setError('게시글 삭제에 실패했습니다.');
                console.error(err);
            }
        }
    };

    useEffect(() => {
        fetchPosts(currentPage);
    }, [currentPage]);

    return (
        <div className="community-management">
            <h2>커뮤니티 관리</h2>
            {error && <p className="error">{error}</p>}
            
            <table>
                <thead>
                    <tr>
                        <th>제목</th>
                        <th>작성자</th>
                        <th>작성일</th>
                        <th>관리</th>
                    </tr>
                </thead>
                <tbody>
                    {posts.map(post => (
                        <tr key={post._id}>
                            <td>{post.title}</td>
                            <td>{post.user ? post.user.username : '탈퇴한 사용자'}</td>
                            <td>{new Date(post.createdAt).toLocaleDateString()}</td>
                            <td>
                                <button onClick={() => handleDelete(post._id)}>
                                    삭제
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="pagination">
                {Array.from({ length: totalPages }, (_, i) => (
                    <button
                        key={i + 1}
                        onClick={() => setCurrentPage(i + 1)}
                        className={currentPage === i + 1 ? 'active' : ''}
                    >
                        {i + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default CommunityManagement; 