import React, { useState } from 'react';
import axios from 'axios';
import { getToken } from '../../../utils/auth';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const CustomerEmailPage = () => {
  const [emailData, setEmailData] = useState({
    subject: '',
    content: '',
    target: 'all'
  });
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResult(null);

    try {
      const response = await axios.post(
        `${BACKEND_URL}/admin/email/send`,
        emailData,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
          }
        }
      );

      setResult({
        success: true,
        message: response.data.message
      });
      
      // 폼 초기화
      setEmailData({
        subject: '',
        content: '',
        target: 'all'
      });
    } catch (error) {
      setResult({
        success: false,
        message: error.response?.data?.message || '이메일 전송에 실패했습니다.'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-page">
      <h1>고객 이메일 전송</h1>
      {result && (
        <div className={`alert ${result.success ? 'alert-success' : 'alert-error'}`}>
          {result.message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="email-form">
        <div className="form-group">
          <label>수신 대상</label>
          <select 
            value={emailData.target}
            onChange={(e) => setEmailData({...emailData, target: e.target.value})}
          >
            <option value="all">전체 회원</option>
            <option value="active">활성 회원</option>
            <option value="inactive">휴면 회원</option>
          </select>
        </div>
        <div className="form-group">
          <label>제목</label>
          <input 
            type="text"
            value={emailData.subject}
            onChange={(e) => setEmailData({...emailData, subject: e.target.value})}
          />
        </div>
        <div className="form-group">
          <label>내용</label>
          <textarea 
            value={emailData.content}
            onChange={(e) => setEmailData({...emailData, content: e.target.value})}
          />
        </div>
        <button type="submit" disabled={loading}>전송하기</button>
      </form>
    </div>
  );
};

export default CustomerEmailPage 