import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './QuestionSetInfoPage1.module.css'; // CSS Modules import
import { FaFileAlt, FaLaptopCode, FaBriefcase, FaPlus, FaTimes, FaArrowRight, FaPencilAlt } from 'react-icons/fa';
import axios from 'axios';
import { getToken } from '../../../utils/auth';

const QuestionSetInfoPage1 = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialData = location.state?.formData || {
    coverLetter: '',
    skills: '',
    experiences: ''
  };

  const initialSkillsList = location.state?.formData.skills || [];
  const initialExperiencesList = location.state?.formData.experiences || [];

  const [formData, setFormData] = useState(initialData);
  const [skillsList, setSkillsList] = useState(initialSkillsList);
  const [experiencesList, setExperiencesList] = useState(initialExperiencesList);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [inputType, setInputType] = useState('text'); // 'text' 또는 'coverLetter'
  const [coverLetters, setCoverLetters] = useState([]);
  const [selectedCoverLetter, setSelectedCoverLetter] = useState('');

  useEffect(() => {
    const fetchCoverLetters = async () => {
      try {
        const token = getToken();
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/tool1/coverletters`, {
          headers: { 'x-auth-token': token },
          params: { status: 'published' }
        });
        
        const publishedCoverLetters = response.data.filter(
          letter => letter.infoStatus === 'published' && letter.contentStatus === 'published'
        );
        
        setCoverLetters(publishedCoverLetters);
      } catch (error) {
        console.error('자소서 목록 불러오기 실패:', error);
      }
    };

    fetchCoverLetters();
  }, []);

  const handleCoverLetterSelect = async (coverLetterId) => {
    try {
      const token = getToken();
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/getCoverletter/${coverLetterId}`, {
        headers: {
          'x-auth-token': token
        }
      });

      const coverLetter = response.data.coverLetter;
      if (!coverLetter.content || coverLetter.content.length === 0) {
        alert('선택한 자기소개서의 내용이 없습니다.');
        return;
      }

      const resumeContent = coverLetter.content
        .map(item => `[문항]\n${item.question}\n\n[답변]\n${item.answer}`)
        .join('\n\n---\n\n');

      setFormData(prev => ({
        ...prev,
        coverLetter: resumeContent,
        coverLetterId: coverLetterId // coverLetterId 저장 추가
      }));
      setSelectedCoverLetter(coverLetterId);
    } catch (error) {
      console.error('자기소개서 불러오기 실패:', error);
      alert('자기소개서를 불러오는데 실패했습니다.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleAddSkill = () => {
    if (formData.skills.trim()) {
      setSkillsList(prevList => [...prevList, formData.skills]);
      setFormData(prevState => ({ ...prevState, skills: '' }));
    }
  };

  const handleAddExperience = () => {
    if (formData.experiences.trim()) {
      setExperiencesList(prevList => [...prevList, formData.experiences]);
      setFormData(prevState => ({ ...prevState, experiences: '' }));
    }
  };

  const handleKeyPress = (e, fieldType) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (fieldType === 'skills') {
        handleAddSkill();
      } else if (fieldType === 'experiences') {
          handleAddExperience();
      }
    }
  };

  const handleDeleteSkill = (index) => {
    setSkillsList(prevList => prevList.filter((_, i) => i !== index));
  };

  const handleDeleteExperience = (index) => {
    setExperiencesList(prevList => prevList.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const allFieldsFilled = formData.coverLetter.trim() !== '' &&
                            skillsList.length > 0 &&
                            experiencesList.length > 0;
    setIsButtonDisabled(!allFieldsFilled);
  }, [formData, skillsList, experiencesList]);

  const handleNextClick = () => {
    const dataToSend = {
      coverLetter: formData.coverLetter,
      coverLetterId: selectedCoverLetter,
      skills: skillsList,
      experiences: experiencesList
    };
    navigate('/tools/questions-setCategory', { state: { formData: dataToSend } });
  };

  const handleInputTypeChange = (type) => {
    setInputType(type);
    if (type === 'text') {
      // 직접 입력으로 전환할 때 자기소개서 내용 초기화
      setFormData(prevState => ({
        ...prevState,
        coverLetter: ''
      }));
      setSelectedCoverLetter('');
    }
  };

  return (
    <div className={styles.questionSetInfoPage}>
      <h2>면접 예상질문 생성기</h2>
      <p>정보 입력 (1/2 Step)</p>
      <div className={styles.inputTypeSelector}>
        <div 
          className={`${styles.inputOption} ${inputType === 'text' ? styles.selected : ''}`}
          onClick={() => handleInputTypeChange('text')}
        >
          <div className={styles.inputOptionHeader}>
            <FaPencilAlt className={styles.inputOptionIcon} />
            <h3>직접 입력</h3>
          </div>
          <p>자기소개서 내용을 직접 입력합니다.</p>
        </div>
        <div 
          className={`${styles.inputOption} ${inputType === 'coverLetter' ? styles.selected : ''}`}
          onClick={() => handleInputTypeChange('coverLetter')}
        >
          <div className={styles.inputOptionHeader}>
            <FaFileAlt className={styles.inputOptionIcon} />
            <h3>저장된 자기소개서</h3>
          </div>
          <p>저장된 자기소개서를 선택합니다.</p>
        </div>
      </div>

      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <FaFileAlt className={styles.cardIcon} />
            <span className={styles.cardTitle}>자기소개서 업로드</span>
          </div>
          {inputType === 'text' ? (
            <textarea
              name="coverLetter"
              value={formData.coverLetter}
              onChange={handleChange}
              placeholder="자기소개서를 입력해주세요."
              maxLength="4000"
              className={styles.textarea}
            />
          ) : (
            <div className={styles.coverLetterCards}>
              {coverLetters.length === 0 ? (
                <div className={styles.noCoverletter}>
                  <p>저장된 자소서가 없습니다.</p>
                  <button onClick={() => navigate('/tools/resume-generator/setinfo')}>
                    자소서 작성하러 가기
                  </button>
                </div>
              ) : (
                coverLetters.map((letter) => (
                  <div 
                    key={letter._id} 
                    className={`${styles.coverLetterCard} ${selectedCoverLetter === letter._id ? styles.selected : ''}`}
                    onClick={() => handleCoverLetterSelect(letter._id)}
                  >
                    <div className={styles.cardHeader}>
                      <h4>{letter.title}</h4>
                      <span>{letter.companyName}</span>
                    </div>
                    <div className={styles.cardBody}>
                      <p>{letter.jobTitle}</p>
                      <p>{letter.jobCategory}</p>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
        
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <FaLaptopCode className={styles.cardIcon} />
            <span className={styles.cardTitle}>보유 기술 및 역량</span>
          </div>
          <div className={styles.inputGroup}>
            <input
              type="text"
              name="skills"
              value={formData.skills}
              onChange={handleChange}
              onKeyPress={(e) => handleKeyPress(e, 'skills')}
              placeholder="예시) ○○ 자격증 보유"
              maxLength="30"
              className={styles.input}
            />
            <button type="button" onClick={handleAddSkill} className={styles.addButton}>
              <FaPlus />
            </button>
          </div>
          <div className={styles.tagList}>
            {skillsList.map((skill, index) => (
              <span key={index} className={styles.tag}>
                {skill}
                <button type="button" onClick={() => handleDeleteSkill(index)}>
                  <FaTimes />
                </button>
              </span>
            ))}
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <FaBriefcase className={styles.cardIcon} />
            <span className={styles.cardTitle}>경력 사항</span>
          </div>
          <div className={styles.inputGroup}>
            <input
              type="text"
              name="experiences"
              value={formData.experiences}
              onChange={handleChange}
              onKeyPress={(e) => handleKeyPress(e, 'experiences')}
              placeholder="예시) it기업에서 인턴 5개월"
              maxLength="30"
              className={styles.input}
            />
            <button type="button" onClick={handleAddExperience} className={styles.addButton}>
              <FaPlus />
            </button>
          </div>
          <div className={styles.tagList}>
            {experiencesList.map((experience, index) => (
              <span key={index} className={styles.tag}>
                {experience}
                <button type="button" onClick={() => handleDeleteExperience(index)}>
                  <FaTimes />
                </button>
              </span>
            ))}
          </div>
        </div>
      </div>
      <button 
        type="button" 
        className={`${styles.nextBtn} ${isButtonDisabled ? styles.disabled : ''}`} 
        onClick={handleNextClick} 
        disabled={isButtonDisabled}
      >
        다음 단계로 <FaArrowRight />
      </button>
    </div>
  );
};

export default QuestionSetInfoPage1;
