import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import QuestionResponse from './QuestionResponse';
import './ResumeGeneratorPage2.css';
import { getToken } from '../../../utils/auth';
import { FiRefreshCw, FiTrash2 } from 'react-icons/fi';
import SpinningLogo from '../../../components/SpinningLogo';

// 환경 변수에서 백엔드 URL 가져오기
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const predefinedQuestions = [
  '장단점에 관하여',
  '지원동기에 관하여',
  '입사 후 포부에 관하여',
  '나의 가치관에 관하여',
  '문제해결능력에 관하여',
  '의사소통능력에 관하여',
  '팀워크와 리더십에 관하여',
  '자기주도적 태도에 관하여',
  '직접 입력'
];

const ResumeGeneratorPage2 = () => {
  const { coverLetterId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [questions, setQuestions] = useState([{ text: '', isCustom: false }]);
  const [responses, setResponses] = useState([]);
  const [loadingIndex, setLoadingIndex] = useState(null);

  useEffect(() => {
    if (location.state && location.state.content) {
      const initialQuestions = location.state.content.map(item => ({ text: item.question, isCustom: true }));
      const initialResponses = location.state.content.map(item => item.answer);
      setQuestions(initialQuestions);
      setResponses(initialResponses);
    } else {
      const fetchCoverLetter = async () => {
        try {
          const token = getToken();
          if (!token) {
            throw new Error('No token found');
          }

          const response = await axios.get(`${BACKEND_URL}/tool1/${coverLetterId}`, {
            headers: {
              'x-auth-token': token
            }
          });

          const coverLetterData = response.data;

          if (coverLetterData && coverLetterData.content) {
            const initialQuestions = coverLetterData.content.map(item => ({ text: item.question, isCustom: true }));
            const initialResponses = coverLetterData.content.map(item => item.answer);
            setQuestions(initialQuestions);
            setResponses(initialResponses);
          }
        } catch (error) {
          console.error('Error fetching cover letter:', error);
        }
      };

      fetchCoverLetter();
    }
  }, [coverLetterId, location.state]);

  const handleQuestionChange = (index, event) => {
    const newQuestions = [...questions];
    if (event.target.value === '직접 입력') {
      newQuestions[index] = { text: '', isCustom: true };
    } else {
      newQuestions[index] = { text: event.target.value, isCustom: false };
    }
    setQuestions(newQuestions);
  };

  const handleCustomQuestionChange = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].text = event.target.value;
    setQuestions(newQuestions);
  };

  // 추가된 핸들러: 사용자가 응답을 수정할 때 상태 업데이트
  const handleResponseChange = (index, event) => {
    const newResponses = [...responses];
    newResponses[index] = event.target.value;
    setResponses(newResponses);
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { text: '', isCustom: false }]);
    setResponses([...responses, '']); // 새로운 질문 추가 시 응답도 추가
  };

  const handleGenerateDraft = async (index) => {
    setLoadingIndex(index);
    try {
      const token = getToken();
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.post(`${BACKEND_URL}/tool1/generate/${coverLetterId}`, {
        question: questions[index].text
      }, {
        headers: {
          'x-auth-token': token
        }
      });

      if (!response.data.success) {
        alert(response.data.message);
        if (response.data.message === '토큰이 부족합니다. 충전이 필요합니다.') {
          navigate('/payment');
        }
      } else {
        const newResponses = [...responses];
        newResponses[index] = response.data.coverLetter;
        setResponses(newResponses);
      }
    } catch (error) {
      console.error('Error generating cover letter:', error);
    } finally {
      setLoadingIndex(null);
    }
  };

  // 초안 재생성을 위한 함수
  const handleRegenerateDraft = async (index) => {
    setLoadingIndex(index); // 로딩 상태 설정
    try {
      const token = getToken(); // 세션 스토리지에서 JWT 토큰을 가져옵니다.
      if (!token) {
        throw new Error('No token found'); // 토큰이 없으면 오류를 발생시킵니다.
      }

      // JWT 토큰을 포함하여 서버에 POST 요청을 보냅니다.
      const response = await axios.post(`${BACKEND_URL}/tool1/generate/${coverLetterId}`, {
        question: questions[index].text
      }, {
        headers: {
          'x-auth-token': token
        }
      });

      if (!response.data.success) {
        alert(response.data.message);
        if (response.data.message === '토큰 부족합니다. 충전이 필요합니다.') {
          navigate('/payment'); // 결제 페이지로 리다이렉트
        }
      } else {
        const newResponses = [...responses];
        newResponses[index] = response.data.coverLetter; // 응답 데이터를 responses 상태에 저장합니다.
        setResponses(newResponses); // 상태 업데이트
      }
    } catch (error) {
      console.error('Error regenerating cover letter:', error); // 오류 처리
    } finally {
      setLoadingIndex(null); // 로딩 상태 해제
    }
  };

  // 질문과 응답 삭제 시 상태를 업데이트하는 함수
  const handleDelete = (index) => {
    const confirmDelete = window.confirm('정말 자기소개서 초안을 삭제하시겠습니까?');
    if (confirmDelete) {
    const newQuestions = [...questions];
    const newResponses = [...responses];
    newQuestions.splice(index, 1);
    newResponses.splice(index, 1);
    setQuestions(newQuestions);
    setResponses(newResponses);
    }
  };

  const handleNext = async () => {
    if (responses.length === 0) {
      alert('초안을 최소한 하나 이상 작성해야 합니다.');
      return;
    }
    try {
      const token = getToken();
      if (!token) {
        throw new Error('No token found');
      }

      const content = questions.map((q, i) => ({
        question: q.text,
        answer: responses[i] || ''
      }));

      await axios.post(`${BACKEND_URL}/tool1/write/save/${coverLetterId}`, {
        content,
        isTemporary: false
      }, {
        headers: {
          'x-auth-token': token
        }
      });

      navigate(`/coverletter/view/${coverLetterId}`);
    } catch (error) {
      console.error('Error saving cover letter content:', error);
      alert(error.response?.data?.message || '저장 중 오류가 발생했습니다.');
    }
  };

  const handleSaveTemporarily = async () => {
    try {
      const token = getToken();
      if (!token) {
        throw new Error('No token found');
      }

      const content = questions.map((q, i) => ({
        question: q.text,
        answer: responses[i]
      }));

      await axios.post(`${BACKEND_URL}/tool1/write/save/${coverLetterId}`, {
        content,
        isTemporary: true
      }, {
        headers: {
          'x-auth-token': token
        }
      });

      navigate('/mypage/profile');
    } catch (error) {
      console.error('Error saving cover letter content temporarily:', error);
    }
  };

  const handleBack = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${BACKEND_URL}/tool1/${coverLetterId}`, {
        headers: {
          'x-auth-token': token,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.data) {
        alert('자기소개서를 불러오는 데 실패했습니다.');
        return;
      }
  
      const coverLetter = response.data;
  
      // Pass cover letter data via location.state
      navigate(`/tools/resume-generator/setinfo/${coverLetterId}`, {
        state: {
          coverLetter: coverLetter,
        },
      });
    } catch (error) {
      console.error('Error fetching cover letter:', error);
      alert('오류 발생했습니다.');
    }
  };

  return (
    <div className="resume-generator-page2">
      <div className="progress-bar-container">
        <div className="progress-bar">
          <div className="progress-step" data-label="기본 정보">1</div>
          <div className="progress-step" data-label="본문 작성">2</div>
        </div>
      </div>

      
      <div className="feature-guide">
        <div className="feature-header">
          <span className="feature-icon">💡</span>
          <h3>자기소개서 생성기 사용 가이드</h3>
        </div>
        <div className="feature-steps">
          <div className="feature-step">
            <div className="step-number">1</div>
            <div className="step-content">
              <h4>질문 선택 및 입력</h4>
              <p>미리 준비된 질문을 선택하거나 직접 입력하실 수 있습니다.</p>
              <div className="step-example">
                예시: "지원동기에 관하여" 선택 또는 직접 질문 작성
              </div>
            </div>
          </div>
          <div className="step-arrow">➜</div>
          <div className="feature-step">
            <div className="step-number">2</div>
            <div className="step-content">
              <h4>초안 생성</h4>
              <p><span className="highlight">생성하기</span> 버튼을 클릭하여 AI가 작성한 초안을 확인하세요.</p>
              <div className="step-example">
                맞춤형 자기소개서가 자동으로 생성됩니다.
              </div>
            </div>
          </div>
          <div className="step-arrow">➜</div>
          <div className="feature-step">
            <div className="step-number">3</div>
            <div className="step-content">
              <h4>상세 수정</h4>
              <p>텍스트를 드래그하여 선택한 부분만 <span className="highlight">상세 수정</span> 가능합니다.</p>
              <div className="step-example">
                원하는 부분만 선택하여 더 구체적으로 수정할 수 있습니다.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="info-message">
        <strong>✨ Pro Tip:</strong> 텍스트를 드래그하여 선택하면 해당 부분만 다시 생성할 수 있습니다. 
        더 구체적이고 맞춤화된 내용을 작성해보세요!
      </div>
      
      {loadingIndex !== null && (
        <div className="loading-overlay">
          <div className="loading-content">
            <SpinningLogo text="자기소개서를 생성하고 있습니다..." />
          </div>
        </div>
      )}
      {questions.map((question, index) => (
          <div className="question-content" key={index}>
            <QuestionResponse
              index={index}
              question={question.text}
              response={responses[index] || ''}
              responses={responses}
              setResponses={setResponses}
              isCustom={question.isCustom}
              isLoading={loadingIndex === index}
              onQuestionChange={handleQuestionChange}
              onCustomQuestionChange={handleCustomQuestionChange}
              onGenerateDraft={handleGenerateDraft}
              onRegenerateDraft={handleRegenerateDraft}
              onDelete={handleDelete}
              onResponseChange={handleResponseChange} // 새로운 핸들러 추가
              predefinedQuestions={predefinedQuestions}
            />
          </div>
      ))}
      {questions.length === 0 && (
        <div className="start-writing-prompt">
          ✨ 첫 번째 자기소개서 항목을 작성해보세요!
        </div>
      )}
      <div className="add-button-container">
        {questions.length === 0 && (
          <div className="add-button-arrow">
            ↓
          </div>
        )}
        <button className="add-button" onClick={handleAddQuestion}>
          {questions.length === 0 ? '자기소개서 작성 시작하기' : '새로운 항목 추가하기'}
          <div className="pulse-effect"></div>
        </button>
      </div>
      <div className="resumepage2-action-buttons">
        <button className="back-action-button" onClick={handleBack}>이전</button>
        <div className="right-buttons">
          <div className="tooltip-container">
            <button className="save-action-button" onClick={handleNext}>
              저장
            </button>
            <span className="tooltip">
              <div className="tooltip-content">
                <div className="tooltip-header">
                  <span className="tooltip-icon">💾</span>
                  <span className="tooltip-title">최종 저장</span>
                </div>
                <div className="tooltip-body">
                  작성을 완료하고 자기소개서를<br />
                  최종 저장합니다.<br /><br />
                  <div className="tooltip-features">
                    <div className="feature-item">
                      <span className="feature-icon">📝</span>
                      마이페이지에서 조회/수정 가능
                    </div>
                    <div className="feature-item">
                      <span className="feature-icon">🤖</span>
                      AI 면접 준비에 활용
                    </div>
                    <div className="feature-item">
                      <span className="feature-icon">✨</span>
                      자기소개서 평가 기능 이용
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </div>
          <div className="tooltip-container">
            <button className="temp-save-action-button" onClick={handleSaveTemporarily}>
              임시저장
            </button>
            <span className="tooltip">
              <div className="tooltip-title">⚡ 임시 저장</div>
              <div className="tooltip-content">
                현재까지 작성된 내용을<br />
                임시로 저장합니다.<br />
                나중에 마이페이지에서 작성할 수 있습니다.
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeGeneratorPage2;