import React from 'react';
import { Link } from 'react-router-dom';
import SocialLoginButton from '../components/SocialLoginButton';
import './SignupPage.css';
import { FaEnvelope } from 'react-icons/fa';
import headerLogo from '../assets/images/logos/headerlogo.svg';

const SignupPage = () => {
  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/google`;
  };

  return (
    <div className="signup-container">
      <div className="signup-page">
        <img src={headerLogo} alt="Jobmaker" className="header-logo" />
        <p className="signup-subtitle">AI 기반 취업 플랫폼에 오신 것을 환영합니다</p>
        <Link to="/email-signup" className="email-signup-btn">
          <FaEnvelope className="icon" />
          <span>이메일로 가입</span>
        </Link>
        <div className="divider">
          <span>또는</span>
        </div>
        <div className="social-login">
          <SocialLoginButton provider="google" onClick={handleGoogleLogin} className="google" />
        </div>
        <div className="login-link">
          이미 회원이신가요? <Link to="/login">로그인</Link>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
