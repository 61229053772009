import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getToken } from '../../utils/auth';
import axios from 'axios';
import './ProfileTab.css';
import UserDetailModal from '../UserDetailModal';
import UserDetailEdit from '../UserDetailEdit';
import { FaFileAlt, FaCamera, FaQuestionCircle, FaUserTie, FaCode, FaClipboardCheck, FaRegFileAlt, FaEnvelope, FaUser, FaArrowRight } from 'react-icons/fa';

// 이미지 파일 import
import defaultAvatar from '../../assets/images/icons/default-avatar.png';
import tokenIcon from '../../assets/images/icons/token-icon.png';
import subscriptionIcon from '../../assets/images/icons/subscription-icon.png';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ProfileTab = ({ userData }) => {
  const [listCounts, setListCounts] = useState({
    resumeCount: 0,
    photoCount: 0,
    questionCount: 0,
    interviewCount: 0,
    techTreeCount: 0,
    resumeEvaluationCount: 0,
    subscribePlan: 'Free',  // 기본값을 'Free'로 설정
  });

  const [showModal, setShowModal] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const [isDetailFilled, setIsDetailFilled] = useState(false);

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showDetailEdit, setShowDetailEdit] = useState(false);

  const [resumes, setResumes] = useState([]);

  // 버로부터 목록 개수 가 함수
  const fetchListCounts = async () => {
    const token = getToken();
    try {
      const response = await fetch(`${BACKEND_URL}/user/getlistcounts`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('받아온 목록 개수 전체:', data);
        console.log('자기소개서 평가 개수:', data.resumeEvaluationsCount);
        
        setListCounts({
          resumeCount: data.coverLettersCount,
          photoCount: data.idPhotosCount,
          questionCount: data.interviewQuestionsCount,
          interviewCount: 0,
          techTreeCount: data.techTreesCount,
          resumeEvaluationCount: data.resumeEvaluationsCount,
          subscribePlan: data.subscribePlan,
        });
      } else {
        console.error('Failed to fetch list counts');
      }
    } catch (error) {
      console.error('Error fetching list counts:', error);
    }
  };

  const fetchUserDetail = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${BACKEND_URL}/user/detail`, {
        headers: { 'x-auth-token': token }
      });
      
      // 서버 응답에서 userDetail이 존재하고 비어있지 않은 경우
      if (response.data.userDetail && Object.keys(response.data.userDetail).length > 0) {
        setUserDetail(response.data.userDetail);
        setIsDetailFilled(true);
      } else {
        setUserDetail(null);
        setIsDetailFilled(false);
      }
    } catch (error) {
      console.error('Error fetching user detail:', error);
      setUserDetail(null);
      setIsDetailFilled(false);
    }
  };

  // 이력서 목록을 가져오는 함수
  const fetchResumes = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${BACKEND_URL}/resume/list`, {
        headers: { 'x-auth-token': token }
      });
      setResumes(response.data);
      setListCounts(prev => ({
        ...prev,
        builderResumeCount: response.data.length
      }));
    } catch (error) {
      console.error('Error fetching resumes:', error);
    }
  };

  useEffect(() => {
    fetchListCounts();
    fetchUserDetail();
    fetchResumes();
  }, []);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    fetchUserDetail(); // 모달이 닫힐 때 사용자 정보를 다시 불러옵니다.
  };

  const handleOpenDetailModal = () => {
    setShowDetailModal(true);
  };

  const handleCloseDetailModal = () => {
    setShowDetailModal(false);
    fetchUserDetail();
  };

  const handleOpenDetailEdit = () => {
    setShowDetailEdit(true);
  };

  const handleCloseDetailEdit = () => {
    setShowDetailEdit(false);
    fetchUserDetail();
  };

  const handleSaveUserDetail = (updatedUserDetail) => {
    setUserDetail(updatedUserDetail);
    setIsDetailFilled(true);
    // fetchUserDetail(); // 이 줄은 제거하거나 주석 처리
  };

  const renderUserDetail = () => {
    if (!userDetail || !isDetailFilled) {
      return (
        <div className="user-detail-empty">
          <p>아직 상세 정보가 입력되지 않았습니다.</p>
          <button className="add-button" onClick={handleOpenDetailModal}>
            상세 정보 입력하기
          </button>
        </div>
      );
    }

    return (
      <div className="user-detail-section">
        <div className="user-detail-header">
          <h3>상세 정보</h3>
          <button className="edit-button" onClick={handleOpenDetailEdit}>
            수정하기
          </button>
        </div>
        <div className="user-detail-grid">
          <DetailItem label="전공" value={userDetail.major} />
          <DetailItem label="경력" value={userDetail.experience} />
          <DetailItem label="직종" value={userDetail.employmentType} />
          <DetailItem label="선호 지역" value={userDetail.preferredRegion} />
          <DetailItem label="경력 상태" value={userDetail.careerStatus} />
          <DetailItem label="학력" value={userDetail.education} />
          <DetailItem label="경력 연수" value={userDetail.yearsOfExperience} />
          <DetailItem label="최근 직무" value={userDetail.recentPosition} />
          <DetailItem label="최근 근무지" value={userDetail.recentCompany} />
          <DetailItem 
            label="희망 연봉" 
            value={`${userDetail.minSalary?.toLocaleString()}원 ~ ${userDetail.maxSalary?.toLocaleString()}원`} 
          />
          <DetailItem label="근무 시작 가능일" value={new Date(userDetail.startDate).toLocaleDateString()} />
          <DetailItem label="근무 가능 시간대" value={userDetail.workHours} />
          <DetailItem label="유연 근무제" value={userDetail.remoteWork} />
          <DetailItem label="보유 기술" value={userDetail.skills?.join(', ')} />
          <DetailItem label="자격증" value={userDetail.certifications?.join(', ')} />
          <DetailItem label="포트폴리오 링크" value={userDetail.portfolioLinks?.join(', ')} />
          <DetailItem label="주요 경력 사항" value={userDetail.careerHighlights?.join(', ')} />
          <DetailItem label="신입 졸업자" value={userDetail.isNewGraduate ? '예' : '아니오'} />
          {userDetail.isNewGraduate && (
            <>
              <DetailItem label="졸업 연도" value={userDetail.graduationYear} />
              <DetailItem label="학점" value={userDetail.gpa} />
              <DetailItem label="전공 경험" value={userDetail.majorExperience} />
            </>
          )}
        </div>
      </div>
    );
  };

  const DetailItem = ({ label, value }) => (
    <div className="detail-item">
      <span className="detail-label">{label}:</span>
      <span className="detail-value">{value || '정보 없음'}</span>
    </div>
  );

  return (
    <div className="profile-tab">
      <div className="user-section">
        <div className="user-info">
          <div className="avatar-container">
            <img src={defaultAvatar} alt="User Avatar" className="avatar" />
            <div className="avatar-edit-badge">
              <FaCamera className="camera-icon" />
            </div>
          </div>
          <div className="user-details">
            <h2>{userData.username} 님</h2>
            <p className="user-email"><FaEnvelope className="detail-icon" /> {userData.email}</p>
            <p className="user-nickname"><FaUser className="detail-icon" /> {userData.nickname}</p>
          </div>
        </div>
        <div className="vertical-line"></div>
        <div className="user-stats">
          <Link to="/payment" className="stat-card token-card">
            <div className="stat-icon-container">
              <img src={tokenIcon} alt="Token" className="stat-icon" />
            </div>
            <div className="stat-details">
              <span className="stat-label">현재 보유한 토큰</span>
              <span className="stat-value">{userData.tokens}</span>
              <span className="stat-action">충전하기 <FaArrowRight className="arrow-icon" /></span>
            </div>
          </Link>
          <Link to="/payment" className="stat-card subscription-card">
            <div className="stat-icon-container">
              <img src={subscriptionIcon} alt="Subscription" className="stat-icon" />
            </div>
            <div className="stat-details">
              <span className="stat-label">구독 상태</span>
              <span className="stat-value">{listCounts.subscribePlan}</span>
              <span className="stat-action">구독하기 <FaArrowRight className="arrow-icon" /></span>
            </div>
          </Link>
        </div>
      </div>
      
      <div className="saved-files-section">
        <div className="saved-files-header">
          <h3>저장한 파일</h3>
        </div>
        <div className="saved-files-grid">
          <Link to="/mypage/saved-builder-resumes" className="file-card">
            <div className="file-icon">
              <FaRegFileAlt />
            </div>
            <div className="file-info">
              <span className="file-label">이력서 생성기</span>
              <span className="file-description">AI로 이력서를 작성해보세요</span>
            </div>
          </Link>
          
          <Link to="/mypage/saved-resumes" className="file-card">
            <div className="file-icon">
              <FaFileAlt />
            </div>
            <div className="file-info">
              <span className="file-label">자소서 생성기</span>
              <span className="file-description">AI로 자기소개서를 작성해보세요</span>
            </div>
          </Link>
          
          <Link to="/mypage/saved-photos" className="file-card">
            <div className="file-icon">
              <FaCamera />
            </div>
            <div className="file-info">
              <span className="file-label">증명사진 생성기</span>
              <span className="file-description">AI로 증명사진을 생성해보세요</span>
            </div>
          </Link>
          
          <Link to="/mypage/saved-questions" className="file-card">
            <div className="file-icon">
              <FaQuestionCircle />
            </div>
            <div className="file-info">
              <span className="file-label">면접예상질문 생성기</span>
              <span className="file-description">AI로 예상 면접 질문을 준비하세요</span>
            </div>
          </Link>
          
          <Link to="/mypage/saved-interviews" className="file-card">
            <div className="file-icon">
              <FaUserTie />
            </div>
            <div className="file-info">
              <span className="file-label">AI 면접 기록</span>
              <span className="file-description">AI와 면접 연습을 해보세요</span>
            </div>
          </Link>
          
          <Link to="/mypage/saved-tech-trees" className="file-card">
            <div className="file-icon">
              <FaCode />
            </div>
            <div className="file-info">
              <span className="file-label">테크트리 생성기</span>
              <span className="file-description">AI로 기술 스택을 분석해보세요</span>
            </div>
          </Link>
          
          <Link to="/mypage/saved-resume-evaluations" className="file-card">
            <div className="file-icon">
              <FaClipboardCheck />
            </div>
            <div className="file-info">
              <span className="file-label">자기소개서 평가</span>
              <span className="file-description">AI로 자기소개서를 평가받아보세요</span>
            </div>
          </Link>
        </div>
      </div>
      {showModal && (
        <UserDetailModal
          onClose={handleCloseModal}
          onSave={fetchUserDetail}
        />
      )}
      {showDetailModal && (
        <UserDetailModal
          onClose={handleCloseDetailModal}
          onSave={handleSaveUserDetail}
        />
      )}
      {showDetailEdit && (
        <UserDetailEdit
          isOpen={showDetailEdit}
          onClose={handleCloseDetailEdit}
          userDetail={userDetail}
          onSave={handleSaveUserDetail}
        />
      )}
      {renderUserDetail()}
    </div>
  );
};

export default ProfileTab;
