import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getToken } from '../../utils/auth';
import { FaArrowLeft } from 'react-icons/fa';
import './SavedCommon.css';
import SpinningLogo from '../SpinningLogo';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const SavedResumeEvaluations = () => {
    const [savedEvaluations, setSavedEvaluations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSavedEvaluations = async () => {
            try {
                const token = getToken();
                const response = await axios.get(`${BACKEND_URL}/resume-evaluation/saved`, {
                    headers: { 'x-auth-token': token }
                });
                console.log('받아온 데이터:', response.data);
                setSavedEvaluations(response.data.evaluations || []);
            } catch (error) {
                console.error('Error fetching saved evaluations:', error);
                setError('저장된 평가를 불러오는 중 오류가 발생했습니다.');
            } finally {
                setLoading(false);
            }
        };

        fetchSavedEvaluations();
    }, []);

    const handleEvaluationClick = (evaluationId) => {
        navigate(`/resume-evaluation/${evaluationId}`);
    };

    const handleDelete = async (evaluationId, event) => {
        event.stopPropagation();
        const confirmDelete = window.confirm('정말로 이 이력서 평가를 삭제하시겠습니까?');
        
        if (confirmDelete) {
            try {
                const token = getToken();
                const response = await axios.delete(`${BACKEND_URL}/resume-evaluation/${evaluationId}`, {
                    headers: { 'x-auth-token': token }
                });

                if (response.status === 200) {
                    alert('이력서 평가가 성공적으로 삭제되었습니다.');
                    setSavedEvaluations(savedEvaluations.filter(evaluation => evaluation._id !== evaluationId));
                }
            } catch (error) {
                console.error('이력서 평가 삭제 오류:', error);
                alert('삭제 중 오류가 발생했습니다.');
            }
        }
    };

    if (loading) return (
        <div className="loading-overlay">
            <SpinningLogo text="로딩 중..." />
        </div>
    );
    if (error) return <div className="empty-state">{error}</div>;
    if (savedEvaluations.length === 0) return <div className="empty-state">저장된 평가가 없습니다.</div>;

    return (
        <div className="saved-container">
            <div className="saved-header">
                <Link to="/mypage" className="back-link">
                    <FaArrowLeft className="back-icon" />
                    <span>돌아가기</span>
                </Link>
                <h2>저장된 이력서 평가</h2>
            </div>
            
            {savedEvaluations.length === 0 ? (
                <div className="empty-state">
                    <p>저장된 평가가 없습니다.</p>
                </div>
            ) : (
                <div className="item-grid">
                    {savedEvaluations.map((evaluation) => (
                        <div 
                            className="item-card" 
                            key={evaluation._id}
                            onClick={() => handleEvaluationClick(evaluation._id)}
                        >
                            <div className="card-header">
                                <span className="card-title">{evaluation.title}</span>
                                <span className="status-badge">{evaluation.field}</span>
                            </div>
                            <span className="card-date">
                                {new Date(evaluation.createdAt).toLocaleDateString()}
                            </span>
                            <div className="card-actions">
                                <button 
                                    className="action-button delete-button"
                                    onClick={(event) => handleDelete(evaluation._id, event)}
                                >
                                    삭제
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SavedResumeEvaluations;