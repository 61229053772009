import React, { useState, useRef } from 'react';
import { getToken } from '../../../utils/auth';
import './QuestionResponse.css';


// 환경 변수에서 백엔드 URL 가져오기
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const QuestionResponse = ({
  index,
  question,
  response,
  isLoading,
  onQuestionChange,
  onCustomQuestionChange,
  onGenerateDraft,
  onRegenerateDraft,
  onDelete,
  onResponseChange,
  predefinedQuestions,
  responses, 
  setResponses 
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); 
  const [selectedText, setSelectedText] = useState('');
  const [selectionStart, setSelectionStart] = useState(0);
  const [selectionEnd, setSelectionEnd] = useState(0);
  const [requirement, setRequirement] = useState('');   // 수정요청사항
  const [revisedTexts, setRevisedTexts] = useState([]);
  const [selectedRevisedText, setSelectedRevisedText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 활성화 상태
  const textAreaRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleSelectQuestion = (selectedQuestion) => {
    onQuestionChange(index, { target: { value: selectedQuestion } });
    setIsDropdownOpen(false); 
  };

  const handleMouseUp = () => {
    const textarea = textAreaRef.current;
    if (textarea.selectionStart !== textarea.selectionEnd) {
      setSelectedText(textarea.value.substring(textarea.selectionStart, textarea.selectionEnd));
      setSelectionStart(textarea.selectionStart);
      setSelectionEnd(textarea.selectionEnd);
    }
  };

  const handleInputChange = (e) => {
    setRequirement(e.target.value);
  };

  const handleRegeneratePart = async () => {
    setIsEditing(true);
    try {
      const token = getToken();
      const response = await fetch(`${BACKEND_URL}/tool1/revise-sentence`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify({
          inputText: selectedText,
          require: requirement
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch the revised text');
      }
  
      const data = await response.json();
      const revisedTextList = data.revisedText;
      setRevisedTexts(revisedTextList);
      setIsModalOpen(true);
  
    } catch (error) {
      console.error('Error regenerating part:', error);
    } finally {
      setIsEditing(false);
    }
  };

  const handleCancel = () => {
    setSelectedText('');
    setRequirement('');
  };

  const handleRevisedTextSelect = (text) => {
    setSelectedRevisedText((prev) => (prev === text ? '' : text));
  };

  const handleApplyChanges = () => {
    // 기존 응답 텍스트에서 드래그된 부분을 수정된 텍스트로 대체
    const newResponse = responses[index].slice(0, selectionStart) // 드래그 시작 전 텍스트
      + selectedRevisedText // 수정된 텍스트
      + responses[index].slice(selectionEnd); // 드래그 종료 후 나머지 텍스트
  
    const newResponses = [...responses];
    newResponses[index] = newResponse;
  
    // 업데이트된 응답 텍스트를 설정
    setResponses(newResponses);
  
    // 모달 닫기
    setIsModalOpen(false);
    setSelectedText('');
    setRequirement('');
  
  };

  const handleCancelChanges = () => {
    // 모달 취소: 상태 초기화 및 모달 닫기
    setIsModalOpen(false);
    setSelectedText('');
    setRequirement('');
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedText('');
    setRequirement('');
  };

  return (
    <div className="question-response-block" onMouseUp={handleMouseUp}>
      <div className="question-number">{index + 1}</div>
      <button className="QuestionResponse-delete-button" onClick={() => onDelete(index)}>삭제하기</button>

      <div className="question-items-group">
        <input
          className="question-text"
          type="text"
          value={question}
          onChange={(e) => onCustomQuestionChange(index, e)}
          placeholder="직접 입력 또는 가이드 질문 선택"
          maxLength="50"
        />
        <button 
          className={`question-dropdown-button ${isDropdownOpen ? 'active' : ''}`} 
          onClick={toggleDropdown}
        >
          <span>{isDropdownOpen ? '▲' : '▼'}</span>
        </button>
        {isDropdownOpen && (
          <ul className="question-dropdown-list">
            {predefinedQuestions.map((q, i) => (
              <li key={i} onClick={() => handleSelectQuestion(q)}>{q}</li>
            ))}
          </ul>
        )}
      </div>

      <button 
        onClick={() => onGenerateDraft(index)} 
        disabled={isLoading}
        className="generate-button"
      >
        {isLoading ? (
          <>
            <span className="button-spinner-dot"></span>
            <span>생성중...</span>
          </>
        ) : (
          '생성'
        )}
      </button>

      <div className="questionResponse-response-container">
        <div className="questionResponse-response-block">
          <textarea
            ref={textAreaRef}
            className="response-textarea"
            value={response}
            onChange={(e) => onResponseChange(index, e)}
            onMouseUp={handleMouseUp}
          />
          <button 
            className="questionresponse-regenerate-button" 
            onClick={() => onRegenerateDraft(index)} 
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <span className="button-spinner-dot"></span>
                <span>재생성중...</span>
              </>
            ) : (
              '재생성'
            )}
          </button>
        </div>

        {selectedText && (
          <div className="questionResponse-edit-section">
            <button 
              className="close-button" 
              onClick={handleCancel}
              aria-label="닫기"
            />
            <div className="edit-section-header">
              <div className="edit-section-title">텍스트 수정하기</div>
              <div className="edit-section-description">
                선택한 문장을 AI가 더 효과적으로 수정해드립니다. 
                원하는 방향으로 수정 요청을 입력해주세요.
              </div>
            </div>
            <textarea
              className="selected-text-area"
              value={selectedText}
              readOnly
            />
            <input
              className="prompt-input"
              type="text"
              value={requirement}
              onChange={handleInputChange}
              placeholder="수정할 점을 적어주세요."
            />
            <div className="questionResponse-edit-buttons">
              <button 
                className="generate-edit-button"
                onClick={handleRegeneratePart}
                disabled={isEditing}
              >
                {isEditing ? (
                  <>
                    <span className="button-spinner-dot"></span>
                    <span>수정중...</span>
                  </>
                ) : (
                  '수정하기'
                )}
              </button>
            </div>
          </div>
        )}

        {/* 모달 창 */}
        {isModalOpen && (
          <div className="questionresponse-modal">
            <button 
              className="questionresponse-modal-close" 
              onClick={handleCloseModal}
              aria-label="닫기"
            />
            <div className="questionresponse-modal-content">
              <h3>수정 내용 확인</h3>
              <div className="questionresponse-modal-body">
                <div className="questionresponse-modal-section">
                  <h4>수정 전 문장</h4>
                  <p>{selectedText}</p>
                </div>
                <div className="questionresponse-modal-section">
                  <h4>수정 후 문장 (추천 문장 3개)</h4>
                  <div className="revised-text-options">
                    {revisedTexts.map((text, idx) => (
                      <div
                        key={idx}
                        className={`revised-text-button ${selectedRevisedText === text ? 'selected' : ''}`}
                        onClick={() => handleRevisedTextSelect(text)}
                        data-option={String.fromCharCode(65 + idx)} // A, B, C로 표시
                      >
                        {text}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="questionresponse-modal-buttons">
                <button className="questionresponse-apply-button" onClick={handleApplyChanges}>적용하기</button>
                <button className="questionresponse-cancel-button" onClick={handleCancelChanges}>취소</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionResponse;
