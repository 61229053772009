import React from 'react';

const VisitorStatsPage = () => {
  return (
    <div className="admin-page">
      <h1>유입 통계</h1>
      <div className="stats-container">
        <div className="stats-card">
          <h3>일일 방문자</h3>
          <p className="stats-number">준비중</p>
        </div>
        <div className="stats-card">
          <h3>주간 방문자</h3>
          <p className="stats-number">준비중</p>
        </div>
        <div className="stats-card">
          <h3>월간 방문자</h3>
          <p className="stats-number">준비중</p>
        </div>
      </div>
    </div>
  );
};

export default VisitorStatsPage; 