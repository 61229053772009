import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken, removeToken } from '../../utils/auth'; // getToken, removeToken 유틸리티 함수 import
import './PrivacyTab.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const PrivacyTab = () => {
  const navigate = useNavigate();
  // 회원 탈퇴 요청
  const handleDeleteAccount = async () => {
    const confirmDelete = window.confirm('정말로 회원 탈퇴를 하시겠습니까? 보유하고 있는 토큰 정보 및 생성했던 데이터가 모두 삭제됩니다.');
    if (confirmDelete) {
      try {
        const token = getToken(); // 토큰을 가져옴
        const response = await fetch(`${BACKEND_URL}/user/deleteUser`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        });

        if (response.ok) {
          removeToken(); // 로컬스토리지에서 토큰 제거
          alert('회원 탈퇴가 성공적으로 완료되었습니다.'); // 탈퇴 성공 메시지
          navigate('/'); // 메인 페이지로 이동
        } else {
          console.error('Failed to delete account');
          alert('회원 탈퇴에 실패했습니다.');
        }
      } catch (error) {
        console.error('Error deleting account:', error);
        alert('회원 탈퇴 중 오류가 발생했습니다.');
      }
    }
  };
  return (
    <div className="privacy-tab">
      <h2>개인정보 처리방침</h2>
      <div className="privacy-content">
        <section>
          <h3>1. 개인정보의 수집 및 이용 목적</h3>
          <p>당사는 다음과 같은 목적으로 개인정보를 수집 및 이용합니다:</p>
          <ul>
            <li>서비스 제공 및 계정 관리</li>
            <li>고객 지원 및 문의 응대</li>
            <li>서비스 개선 및 신규 서비스 개발</li>
          </ul>
        </section>
        <section>
          <h3>2. 수집하는 개인정보 항목</h3>
          <p>당사가 수집하는 개인정보 항목은 다음과 같습니다:</p>
          <ul>
            <li>이름, 이메일 주소, 전화번호</li>
            <li>서비스 이용 기록, 접속 로그, 쿠키</li>
          </ul>
        </section>
        <section>
          <h3>3. 개인정보의 보유 및 이용 기간</h3>
          <p>개인정보는 회원 탈퇴 시 또는 수집 및 이용 목적 달성 시까지 보유 및 이용됩니다.</p>
        </section>
      </div>
      <div className="account-actions">
        <button className="delete-account" onClick={handleDeleteAccount}>회원 탈퇴</button>
      </div>
    </div>
  );
};

export default PrivacyTab;
