import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken } from '../../utils/auth';
import './AdminPage.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const UsersManagementPage = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BACKEND_URL}/admin/users`, {
        headers: {
          'x-auth-token': getToken()
        }
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="admin-page">
      <div className="admin-header">
        <h1 className="admin-title">사용자 관리</h1>
      </div>
      
      <div className="admin-content">
        <div className="admin-search">
          <input 
            type="text" 
            placeholder="사용자 검색..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button onClick={() => setCurrentPage(1)}>검색</button>
        </div>

        <table className="admin-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>이름</th>
              <th>이메일</th>
              <th>가입일</th>
              <th>상태</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user._id}>
                <td>{user._id}</td>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                <td>{user.status}</td>
                <td>
                  <button className="admin-action-btn edit">수정</button>
                  <button className="admin-action-btn delete">삭제</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="admin-pagination">
          <button onClick={() => setCurrentPage(p => Math.max(1, p - 1))}>이전</button>
          <button className="active">{currentPage}</button>
          <button onClick={() => setCurrentPage(p => p + 1)}>다음</button>
        </div>
      </div>
    </div>
  );
};

export default UsersManagementPage; 