import React from 'react';
import CommunityManagement from '../../components/admin/CommunityManagement';
import './AdminPage.css';

const CommunityManagementPage = () => {
  return (
    <div className="admin-page">
      <h1>게시글 관리</h1>
      <CommunityManagement />
    </div>
  );
};

export default CommunityManagementPage; 