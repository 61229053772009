import React, { useRef } from 'react';
import '../styles/components/BasicInfo.css';

const BasicInfo = ({ data, onChange }) => {
  const fileInputRef = useRef(null);

  const basicData = data || {
    name: '',
    email: '',
    phone: '',
    address: '',
    photo: ''
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        alert('이미지 크기는 2MB 이하여야 합니다.');
        return;
      }

      if (!file.type.match('image.*')) {
        alert('이미지 파일만 업로드 가능합니다.');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        onChange({ ...basicData, photo: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="basic-info-section">
      <h3>기본 정보</h3>
      <div className="basic-info-container">
        <div className="photo-upload-section">
          <div 
            className="photo-preview"
            onClick={() => fileInputRef.current.click()}
          >
            {basicData.photo ? (
              <img src={basicData.photo} alt="증명사진" />
            ) : (
              <div className="photo-placeholder">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path d="M12 4v16m8-8H4" />
                </svg>
                <span>사진 추가</span>
              </div>
            )}
          </div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handlePhotoChange}
            accept="image/*"
            style={{ display: 'none' }}
          />
          <button 
            type="button" 
            onClick={() => fileInputRef.current.click()}
            className="photo-upload-button"
          >
            {basicData.photo ? '사진 변경' : '사진 업로드'}
          </button>
        </div>

        <div className="info-fields">
          <div className="form-group">
            <label>이름 *</label>
            <input
              type="text"
              value={basicData.name}
              onChange={(e) => onChange({ ...basicData, name: e.target.value })}
              required
              placeholder="이름을 입력하세요"
            />
          </div>
          <div className="form-group">
            <label>이메일 *</label>
            <input
              type="email"
              value={basicData.email}
              onChange={(e) => onChange({ ...basicData, email: e.target.value })}
              required
              placeholder="이메일을 입력하세요"
            />
          </div>
          <div className="form-group">
            <label>전화번호 *</label>
            <input
              type="tel"
              value={basicData.phone}
              onChange={(e) => onChange({ ...basicData, phone: e.target.value })}
              required
              placeholder="전화번호를 입력하세요"
            />
          </div>
          <div className="form-group">
            <label>주소</label>
            <input
              type="text"
              value={basicData.address}
              onChange={(e) => onChange({ ...basicData, address: e.target.value })}
              placeholder="주소를 입력하세요"
            />
          </div>
          <div className="form-group">
            <label htmlFor="birthDate">생년월일 *</label>
            <input
              type="date"
              id="birthDate"
              value={basicData.birthDate || ''}
              onChange={(e) => onChange({ ...basicData, birthDate: e.target.value })}
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;