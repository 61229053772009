import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import VoiceRecorder from './VoiceRecorder';
import AnswerFeedback from './AnswerFeedback';

const InterviewQuestions = ({ jobField, interviewType }) => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // 임시 질문 데이터 (나중에 API로 대체)
  useEffect(() => {
    const dummyQuestions = [
      "본인의 기술적 강점은 무엇인가요?",
      "팀 프로젝트에서 겪은 어려움과 해결 방법을 설명해주세요.",
      "왜 우리 회사에 지원하셨나요?",
      "5년 후 본인의 모습은 어떨 것 같나요?",
      "최근에 학습한 새로운 기술이 있다면 무엇인가요?"
    ];
    setQuestions(dummyQuestions);
  }, [jobField, interviewType]);

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
      setUserAnswer('');
    }
  };

  const toggleRecording = () => {
    setIsRecording(!isRecording);
    // 음성 녹음 로직 추가 예정
  };

  const handleAnswerSubmit = async () => {
    setIsSubmitting(true);
    try {
      // TODO: API 연동
      // 임시 피드백 데이터
      const mockFeedback = {
        score: 85,
        strengths: "답변이 구체적이고 경험을 바탕으로 설명했습니다.",
        improvements: "기술적인 세부사항을 더 자세히 설명하면 좋을 것 같습니다.",
        recommendations: "관련 기술 스택의 최신 트렌드를 언급하면 더 좋은 인상을 줄 수 있습니다."
      };
      
      setFeedback(mockFeedback);
    } catch (error) {
      console.error('답변 평가 중 오류 발생:', error);
      alert('답변 평가 중 오류가 발생했습니다.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRecordingComplete = (audioBlob) => {
    // TODO: STT API 연동
    console.log('녹음 완료:', audioBlob);
  };

  return (
    <Container>
      {questions.length > 0 && (
        <>
          <ScoreDisplay>
            <Score>{feedback ? feedback.score : '94'}점</Score>
            <ScoreDescription>
              {jobField}의 면접은 전반적으로...
              답변이 구체적이고 경험을 바탕으로 설명했습니다.
            </ScoreDescription>
          </ScoreDisplay>

          <QuestionHistory>
            {questions.map((q, index) => (
              <HistoryItem 
                key={index}
                active={index === currentQuestionIndex}
                completed={index < currentQuestionIndex}
              >
                <QuestionLabel>1번 질문: 데이터 분석과 구체적인 사례</QuestionLabel>
                <AnswerPreview>
                  {userAnswer && index === currentQuestionIndex ? userAnswer : "내 그렇습니다! 잘 부탁드립니다."}
                </AnswerPreview>
              </HistoryItem>
            ))}
          </QuestionHistory>

          <AnswerSection>
            <InputToggle>
              <ToggleButton active={!isRecording}>텍스트로 시작</ToggleButton>
              <ToggleButton active={isRecording}>음성으로 시작</ToggleButton>
            </InputToggle>

            {!isRecording ? (
              <TextInputArea>
                <TextArea
                  value={userAnswer}
                  onChange={(e) => setUserAnswer(e.target.value)}
                  placeholder="텍스트를 입력하세요..."
                />
                <TextInputActions>
                  <ActionButton>텍스트 입력 전</ActionButton>
                  <ActionButton>텍스트 입력 후</ActionButton>
                  <ActionButton>보내기</ActionButton>
                </TextInputActions>
              </TextInputArea>
            ) : (
              <VoiceInputArea>
                <VoiceRecorder onRecordingComplete={handleRecordingComplete} />
                <VoiceInputActions>
                  <ActionButton>다시 녹음하기</ActionButton>
                  <ActionButton>텍스트 수정하기</ActionButton>
                  <ActionButton>보내기</ActionButton>
                </VoiceInputActions>
              </VoiceInputArea>
            )}
          </AnswerSection>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 2rem;
`;

const ScoreDisplay = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 2rem;
  text-align: center;
`;

const Score = styled.div`
  font-size: 3rem;
  font-weight: bold;
  color: #2E74FF;
  margin-bottom: 1rem;
`;

const ScoreDescription = styled.p`
  color: #666;
  line-height: 1.5;
`;

const QuestionHistory = styled.div`
  margin-bottom: 2rem;
`;

const HistoryItem = styled.div`
  background: ${props => props.active ? '#f8f9fa' : 'white'};
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: ${props => props.active ? '2px solid #2E74FF' : '1px solid #ddd'};
`;

const QuestionLabel = styled.div`
  font-weight: 500;
  color: #2E74FF;
  margin-bottom: 0.5rem;
`;

const AnswerPreview = styled.div`
  color: #666;
  font-size: 0.9rem;
`;

const InputToggle = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const ToggleButton = styled.button`
  flex: 1;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  background: ${props => props.active ? '#2E74FF' : '#f8f9fa'};
  color: ${props => props.active ? 'white' : '#666'};
  cursor: pointer;
`;

const TextInputArea = styled.div`
  background: white;
  padding: 1rem;
  border-radius: 10px;
`;

const VoiceInputArea = styled(TextInputArea)``;

const TextInputActions = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const VoiceInputActions = styled(TextInputActions)``;

const ActionButton = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  background: #f8f9fa;
  color: #666;
  cursor: pointer;
  
  &:hover {
    background: #e9ecef;
  }
`;

const AnswerSection = styled.div`
  margin-top: 2rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: vertical;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: #2E74FF;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const SubmitButton = styled.button`
  padding: 1rem;
  background: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  flex: 1;
  cursor: pointer;
  
  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  &:hover:not(:disabled) {
    background: #218838;
  }
`;

const NextButton = styled.button`
  padding: 1rem;
  background: #2E74FF;
  color: white;
  border: none;
  border-radius: 5px;
  flex: 1;
  cursor: pointer;
  
  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  &:hover:not(:disabled) {
    background: #1b5cd9;
  }
`;

export default InterviewQuestions; 