import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { getToken } from '../../utils/auth';
import './SavedCommon.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const SavedQuestions = () => {
  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const token = getToken();
        const response = await fetch(`${BACKEND_URL}/tool2/questions`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setQuestions(data.questions);
        } else {
          console.error('Failed to fetch interview questions');
        }
      } catch (error) {
        console.error('Error fetching interview questions:', error);
      }
    };

    fetchQuestions();
  }, []);

  const handleQuestionClick = async (questionId) => {
    try {
      const token = getToken();
      const response = await fetch(`${BACKEND_URL}/tool2/question/${questionId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      });

      if (response.ok) {
        navigate(`/question/view/${questionId}`);
      } else {
        console.error('Failed to fetch question details');
      }
    } catch (error) {
      console.error('Error fetching question details:', error);
    }
  };

  const handleDelete = async (questionId, event) => {
    event.stopPropagation(); // 부모 요소의 클릭 이벤트 전파 방지

    const confirmDelete = window.confirm('정말로 이 면접 질문을 삭제하시겠습니까?');
    if (confirmDelete) {
      try {
        const token = getToken();
        const response = await fetch(`${BACKEND_URL}/user/deleteInterviewQuestion/${questionId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        });

        if (response.ok) {
          alert('면접 질문이 성공적으로 삭제되었습니다.');
          setQuestions(questions.filter(question => question._id !== questionId));
        } else {
          console.error('Failed to delete interview question');
        }
      } catch (error) {
        console.error('Error deleting interview question:', error);
      }
    }
  };

  if (questions.length === 0) {
    return <div className="empty-state">
      <p>저장된 면접예상질문이 없습니다.</p>
    </div>;
  }

  return (
    <div className="saved-container">
      <div className="saved-header">
        <Link to="/mypage" className="back-link">
          <FaArrowLeft className="back-icon" />
          <span>돌아가기</span>
        </Link>
        <h2>면접예상질문 목록</h2>
      </div>
      
      <div className="item-grid">
        {questions.map((question) => (
          <div 
            className="item-card" 
            key={question._id}
            onClick={() => handleQuestionClick(question._id)}
          >
            <div className="card-header">
              <span className="card-title">{question.category}</span>
            </div>
            <span className="card-date">
              {new Date(question.createdAt).toLocaleDateString('ko-KR', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </span>
            <div className="card-actions">
              <button 
                className="action-button delete-button"
                onClick={(event) => handleDelete(question._id, event)}
              >
                삭제
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SavedQuestions;