import React from 'react';
import '../styles/components/Education.css';

const Education = ({ educations, onChange }) => {
  const handleAdd = () => {
    onChange([...educations, {
      school: '',
      major: '',
      degree: '',
      startDate: new Date(),
      endDate: null,
      current: false
    }]);
  };

  const handleDelete = (index) => {
    const newEducations = educations.filter((_, i) => i !== index);
    onChange(newEducations);
  };

  return (
    <div className="education-section">
      <h3>학력 사항</h3>
      {educations.map((edu, index) => (
        <div key={index} className="education-item">
          <div className="form-row">
            <input
              type="text"
              value={edu.school}
              onChange={(e) => {
                const newEducations = [...educations];
                newEducations[index].school = e.target.value;
                onChange(newEducations);
              }}
              placeholder="학교명"
            />
            <input
              type="text"
              value={edu.major}
              onChange={(e) => {
                const newEducations = [...educations];
                newEducations[index].major = e.target.value;
                onChange(newEducations);
              }}
              placeholder="전공"
            />
          </div>
          <div className="form-row">
            <input
              type="date"
              value={edu.startDate ? new Date(edu.startDate).toISOString().split('T')[0] : ''}
              onChange={(e) => {
                const newEducations = [...educations];
                newEducations[index].startDate = new Date(e.target.value);
                onChange(newEducations);
              }}
            />
            {!edu.current && (
              <input
                type="date"
                value={edu.endDate ? new Date(edu.endDate).toISOString().split('T')[0] : ''}
                onChange={(e) => {
                  const newEducations = [...educations];
                  newEducations[index].endDate = new Date(e.target.value);
                  onChange(newEducations);
                }}
              />
            )}
          </div>
          <input
            type="text"
            value={edu.degree}
            onChange={(e) => {
              const newEducations = [...educations];
              newEducations[index].degree = e.target.value;
              onChange(newEducations);
            }}
            placeholder="학위"
            required
          />
          <button onClick={() => handleDelete(index)} className="delete-button">삭제</button>
        </div>
      ))}
      <button onClick={handleAdd} className="add-button">학력 추가</button>
    </div>
  );
};

export default Education; 