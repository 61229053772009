import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { getToken } from '../utils/auth';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const AdminProtectedRoute = ({ element: Component }) => {
  const [isAdmin, setIsAdmin] = useState(null);
  const token = getToken();

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!token) {
        setIsAdmin(false);
        return;
      }

      try {
        const response = await axios.get(`${BACKEND_URL}/user`, {
          headers: { 'x-auth-token': token },
        });

        // Check if the user role is 'admin'
        if (response.data.role === 'admin') {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
          alert('비정상적인 접근입니다.');
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
        setIsAdmin(false);
      }
    };

    fetchUserInfo();
  }, [token]);

  if (isAdmin === null) {
    // You can show a loading spinner or message while checking the role
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    alert('비정상적인 접근입니다.');
    return <Navigate to="/" />;
  }

  return <Component />;
};

export default AdminProtectedRoute;