import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ResumeTemplate from './components/ResumeTemplate';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import './styles/ResumePreview.css';
import { validateResumeData } from './ResumeBuilderPage';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ResumePreviewPage = () => {
  const navigate = useNavigate();
  const [resumeData, setResumeData] = useState(null);
  const [template, setTemplate] = useState('modern');

  useEffect(() => {
    const previewData = sessionStorage.getItem('previewResumeData');
    if (!previewData) {
      alert('미리보기 데이터가 없습니다.');
      navigate('/tools/resume-builder');
      return;
    }
    const parsedData = JSON.parse(previewData);
    setResumeData(parsedData);
    setTemplate(parsedData.template || 'modern');
  }, [navigate]);

  const handleSave = async () => {
    try {
      const confirmSave = window.confirm(
        '저장 후에는 수정이 불가능합니다. 계속하시겠습니까?'
      );

      if (!confirmSave) {
        return;
      }

      const token = getToken();
      if (!token) {
        alert('로그인이 필요합니다.');
        navigate('/login', { state: { from: window.location.pathname } });
        return;
      }

      if (!resumeData || !template) {
        throw new Error('저장할 데이터가 올바르지 않습니다.');
      }

      validateResumeData(resumeData);

      const saveData = {
        resumeData,
        template,
        status: 'published',
        title: resumeData.title,
        lastModified: new Date().toISOString()
      };

      const resumeId = resumeData._id;
      const endpoint = resumeId 
        ? `${BACKEND_URL}/resume/save/${resumeId}`
        : `${BACKEND_URL}/resume/save`;
      const method = resumeId ? 'put' : 'post';

      const response = await axios({
        method,
        url: endpoint,
        data: saveData,
        headers: {
          'x-auth-token': token,
          'Content-Type': 'application/json'
        }
      });

      if (!response.data || !response.data.resumeId) {
        throw new Error('서버 응답이 올바르지 않습니다.');
      }

      sessionStorage.removeItem('previewResumeData');
      sessionStorage.removeItem('resumeBuilderData');

      alert('이력서가 저장되었습니다.');
      navigate('/mypage/saved-builder-resumes');
    } catch (error) {
      console.error('Error saving resume:', error);
      if (error.response?.data) {
        alert(error.response.data.message || '서버 오류가 발생했습니다.');
      } else {
        alert(error.message || '이력서 저장 중 오류가 발생했습니다.');
      }
    }
  };

  const handleExportWord = () => {
    const doc = new Document({
      sections: [{
        properties: {},
        children: [
          new Paragraph({
            children: [new TextRun({ text: resumeData.basicInfo.name + '의 이력서', bold: true, size: 30 })],
          }),
          new Paragraph({}),
          // 기본 정보
          ...Object.entries(resumeData.basicInfo).map(([key, value]) => 
            new Paragraph({
              children: [new TextRun({ text: `${key}: ${value}` })]
            })
          ),
          // 나머지 섹션들도 유사하게 추가
        ],
      }],
    });
    
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, 'resume.docx');
    });
  };

  const handleTemplateChange = (newTemplate) => {
    setTemplate(newTemplate);
    const updatedData = { ...resumeData, template: newTemplate };
    setResumeData(updatedData);
    sessionStorage.setItem('previewResumeData', JSON.stringify(updatedData));
  };

  const templateInfo = {
    modern: '모던',
    classic: '클래식',
    simple: '심플'
  };

  if (!resumeData) return null;

  return (
    <div className="resume-preview-page">
      <div className="preview-header">
        <h2>이력서 미리보기</h2>
        <div className="preview-actions">
          <button onClick={() => {
            sessionStorage.setItem('resumeBuilderData', JSON.stringify(resumeData));
            navigate('/tools/resume-builder');
          }}>
            되돌아가기
          </button>
          <button onClick={handleSave} className="save-button">
            저장하기
          </button>
          <button onClick={handleExportWord} className="export-button">
            워드로 내보내기
          </button>
          <button onClick={() => window.print()} className="print-button">
            인쇄하기
          </button>
        </div>
      </div>
      <div className="template-selector-container">
        {Object.entries(templateInfo).map(([key, name]) => (
          <button
            key={key}
            className={`template-option ${template === key ? 'active' : ''}`}
            onClick={() => handleTemplateChange(key)}
          >
            {name}
          </button>
        ))}
      </div>
      <div className="preview-content">
        <ResumeTemplate type={template} data={resumeData} />
      </div>
    </div>
  );
};

export default ResumePreviewPage; 