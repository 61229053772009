import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getToken } from '../../../utils/auth';
import styles from './QuestionViewPage.module.css'; // CSS Modules import
import answerOnIcon from '../../../assets/images/questiongenerator/bulb_on.png';
import answerOffIcon from '../../../assets/images/questiongenerator/bulb_off.png';
import OnStatus from '../../../assets/images/questiongenerator/on.png';
import OffStatus from '../../../assets/images/questiongenerator/off.png';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const QuestionViewPage = () => {
  const { questionId } = useParams();
  const [questionDetail, setQuestionDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAnswers, setShowAnswers] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestionDetail = async () => {
      try {
        const token = getToken();
        const response = await fetch(`${BACKEND_URL}/tool2/question/${questionId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            setQuestionDetail(data.interviewQuestion);
          } else {
            console.error('Failed to fetch question details:', data.message);
          }
        } else {
          console.error('Failed to fetch question details');
        }
      } catch (error) {
        console.error('Error fetching question details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestionDetail();
  }, [questionId]);

  const handleBackClick = () => {
    navigate('/mypage/saved-questions');
  };

  const toggleAnswersVisibility = () => {
    setShowAnswers(!showAnswers);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!questionDetail) {
    return <div>해당 질문을 찾을 수 없습니다.</div>;
  }

  return (
    <div className={styles.questionViewPage}>
      <h2>면접 예상질문</h2>
      <div className={styles.questionsContainer}>
        <div className={styles.toggleButtonContainer}>
          <img
            src={showAnswers ? OnStatus : OffStatus}
            alt="Toggle Answers"
            className={styles.toggleAnswersButton}
            onClick={toggleAnswersVisibility}
          />
        </div>
        {questionDetail.questions.map((qa, index) => (
          <div key={index} className={styles.questionAnswerPair}>
            <p className={styles.question}><strong>{index + 1}. {qa.question}</strong></p>
            <div className={styles.answer}>
              <img src={showAnswers ? answerOnIcon : answerOffIcon} alt="Answer Icon" className={styles.answerIcon} />
              {showAnswers && <span>{qa.answer}</span>}
            </div>
          </div>
        ))}
      </div>
      <button onClick={handleBackClick} className={styles.backButton}>목록으로 돌아가기</button>
    </div>
  );
};

export default QuestionViewPage;
