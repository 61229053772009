import React, { useState } from 'react';
import '../styles/components/Certificates.css';

const Certificates = ({ data, onChange }) => {
  const [certificate, setCertificate] = useState({
    name: '',
    issuer: '',
    acquisitionDate: '',
    expiryDate: ''
  });

  const handleAdd = () => {
    if (certificate.name && certificate.issuer && certificate.acquisitionDate) {
      onChange([...data, { ...certificate }]);
      setCertificate({
        name: '',
        issuer: '',
        acquisitionDate: '',
        expiryDate: ''
      });
    }
  };

  const handleDelete = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    onChange(newData);
  };

  return (
    <div className="certificates-section">
      <h3>자격증</h3>
      
      {/* 기존 자격증 목록 */}
      {data.map((cert, index) => (
        <div key={index} className="certificate-item">
          <div className="cert-info">
            <input
              type="text"
              value={cert.name}
              onChange={(e) => {
                const newData = [...data];
                newData[index].name = e.target.value;
                onChange(newData);
              }}
              placeholder="자격증명"
            />
            <input
              type="text"
              value={cert.issuer}
              onChange={(e) => {
                const newData = [...data];
                newData[index].issuer = e.target.value;
                onChange(newData);
              }}
              placeholder="발급기관"
            />
            <input
              type="date"
              value={cert.acquisitionDate ? new Date(cert.acquisitionDate).toISOString().split('T')[0] : ''}
              onChange={(e) => {
                const newData = [...data];
                newData[index].acquisitionDate = new Date(e.target.value).toISOString();
                onChange(newData);
              }}
              placeholder="취득일"
            />
            <input
              type="date"
              value={cert.expiryDate ? new Date(cert.expiryDate).toISOString().split('T')[0] : ''}
              onChange={(e) => {
                const newData = [...data];
                newData[index].expiryDate = new Date(e.target.value).toISOString();
                onChange(newData);
              }}
              placeholder="만료일"
            />
          </div>
          <button onClick={() => handleDelete(index)}>삭제</button>
        </div>
      ))}

      {/* 새 자격증 추가 폼 */}
      <div className="add-certificate-form">
        <input
          type="text"
          value={certificate.name}
          onChange={(e) => setCertificate({ ...certificate, name: e.target.value })}
          placeholder="자격증명"
        />
        <input
          type="text"
          value={certificate.issuer}
          onChange={(e) => setCertificate({ ...certificate, issuer: e.target.value })}
          placeholder="발급기관"
        />
        <input
          type="date"
          value={certificate.acquisitionDate ? new Date(certificate.acquisitionDate).toISOString().split('T')[0] : ''}
          onChange={(e) => setCertificate({ 
            ...certificate, 
            acquisitionDate: new Date(e.target.value).toISOString() 
          })}
          placeholder="취득일"
        />
        <input
          type="date"
          value={certificate.expiryDate ? new Date(certificate.expiryDate).toISOString().split('T')[0] : ''}
          onChange={(e) => setCertificate({ 
            ...certificate, 
            expiryDate: new Date(e.target.value).toISOString() 
          })}
          placeholder="만료일"
        />
        <button onClick={handleAdd}>자격증 추가</button>
      </div>
    </div>
  );
};

export default Certificates; 