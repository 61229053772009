import React, { useState, useEffect } from 'react';
import { getToken } from '../../utils/auth';
import './CommentSection.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const COMMENTS_PER_PAGE = 10;

const CommentSection = ({ postId }) => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [replyingTo, setReplyingTo] = useState(null);
    const [replyText, setReplyText] = useState('');
    const token = getToken();
    const [currentUser, setCurrentUser] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchComments();
        if (token) {
            fetchCurrentUser();
        }
    }, [postId, currentPage, token]);

    const fetchComments = async () => {
        try {
            const response = await fetch(`${BACKEND_URL}/posts/${postId}/comments?page=${currentPage}&limit=${COMMENTS_PER_PAGE}`);
            if (response.ok) {
                const data = await response.json();
                setComments(data.comments);
                setTotalPages(data.totalPages);
            } else {
                console.error('댓글을 가져오는데 실패했습니다');
            }
        } catch (error) {
            console.error('댓글 가져오기 오류:', error);
        }
    };

    const fetchCurrentUser = () => {
        if (token) {
            const payload = JSON.parse(atob(token.split('.')[1]));
            setCurrentUser(payload.user);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!token) {
            alert('댓글을 작성하려면 로그인이 필요합니다.');
            return;
        }
        try {
            const response = await fetch(`${BACKEND_URL}/posts/${postId}/comments`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token
                },
                body: JSON.stringify({ 
                    text: newComment
                })
            });
            if (response.ok) {
                setNewComment('');
                fetchComments();
            } else {
                console.error('댓글 작성에 실패했습니다');
            }
        } catch (error) {
            console.error('댓글 작성 오류:', error);
        }
    };

    const handleReplySubmit = async (e, parentId) => {
        e.preventDefault();
        if (!token) {
            alert('답글을 작성하려면 로그인이 필요합니다.');
            return;
        }
        try {
            const response = await fetch(`${BACKEND_URL}/posts/${postId}/comments/${parentId}/replies`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token
                },
                body: JSON.stringify({ 
                    text: replyText
                })
            });
            if (response.ok) {
                setReplyText('');
                setReplyingTo(null);
                fetchComments();
            } else {
                console.error('답글 작성에 실패했습니다');
            }
        } catch (error) {
            console.error('답�� 작성 오류:', error);
        }
    };

    const handleReaction = async (commentId, reactionType) => {
        if (!token) {
            alert('좋아요를 누르려면 로그인이 필요합니다.');
            return;
        }
        try {
            const response = await fetch(`${BACKEND_URL}/posts/${postId}/comments/${commentId}/reaction`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token
                },
                body: JSON.stringify({ reactionType })
            });
            if (response.ok) {
                fetchComments();
            } else {
                console.error('리액션 추가에 실패했습니다');
            }
        } catch (error) {
            console.error('리액션 추가 오류:', error);
        }
    };

    const handleDeleteComment = async (commentId) => {
        if (!token) {
            alert('댓글을 삭제하려면 로그인이 필요합니다.');
            return;
        }
        if (window.confirm('정말로 이 댓글을 삭제하시겠습니까?')) {
            try {
                const response = await fetch(`${BACKEND_URL}/posts/${postId}/comments/${commentId}`, {
                    method: 'DELETE',
                    headers: {
                        'x-auth-token': token
                    }
                });
                if (response.ok) {
                    fetchComments();
                } else {
                    console.error('댓글 삭제에 실패했습니다');
                }
            } catch (error) {
                console.error('댓글 삭제 오류:', error);
            }
        }
    };

    const handleReport = async (entityType, entityId) => {
        if (!token) {
            alert('신고하려면 로그인이 필요합니다.');
            return;
        }
        try {
            const response = await fetch(`${BACKEND_URL}/reports`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token
                },
                body: JSON.stringify({
                    entityType,
                    entityId
                })
            });
            if (response.ok) {
                alert('해당 댓글이 신고되었습니다.');
                fetchComments();
            } else {
                console.error('댓글 신고에 실패했습니다');
            }
        } catch (error) {
            console.error('댓글 신고 오류:', error);
        }
    };

    const renderComment = (comment, depth = 0) => (
        <div key={comment._id} className={`comment depth-${depth}`}>
            <div className="comment-content">
                <div className="comment-header">
                    <span className="comment-author">
                        {comment.user ? comment.user.username : '탈퇴한 사용자'}
                    </span>
                    <span className="comment-date">{new Date(comment.createdAt).toLocaleString()}</span>
                </div>
                <p className={`comment-text ${comment.isDeleted ? 'deleted' : ''}`}>
                    {comment.isDeleted ? '삭제된 댓글입니다.' : comment.text}
                </p>
                {!comment.isDeleted && (
                    <div className="comment-actions">
                        <button onClick={() => handleReaction(comment._id, 'like')} className="reaction-button">
                            👍 {comment.likes ? comment.likes.length : 0}
                        </button>
                        {token && (
                            <>
                                <button onClick={() => setReplyingTo(comment._id)} className="reply-button">
                                    {depth === 0 ? '💬 답글' : '↪️ 답글'}
                                </button>
                                {currentUser && comment.user && currentUser.id !== comment.user._id && (
                                    <button onClick={() => handleReport('comment', comment._id)} className="report-button">
                                        🚨 신고
                                    </button>
                                )}
                            </>
                        )}
                        {currentUser && comment.user && currentUser.id === comment.user._id && (
                            <button onClick={() => handleDeleteComment(comment._id)} className="delete-button">
                                🗑️ 삭제
                            </button>
                        )}
                    </div>
                )}
            </div>
            {replyingTo === comment._id && token && (
                <form onSubmit={(e) => handleReplySubmit(e, comment._id)} className="reply-form">
                    <textarea
                        value={replyText}
                        onChange={(e) => setReplyText(e.target.value)}
                        placeholder="답글을 입력하세요"
                        required
                    />
                    <div className="reply-form-actions">
                        <button type="submit">답글 작성</button>
                        <button type="button" onClick={() => setReplyingTo(null)}>취소</button>
                    </div>
                </form>
            )}
            {comment.replies && comment.replies.length > 0 && (
                <div className="replies">
                    {comment.replies.map(reply => renderComment(reply, depth + 1))}
                </div>
            )}
        </div>
    );

    return (
        <div className="comment-section">
            <h3>댓글 {comments.length}</h3>
            {token ? (
                <form onSubmit={handleSubmit} className="comment-form">
                    <textarea
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        placeholder="댓글을 입력하세요"
                        required
                    />
                    <button type="submit">댓글 작성</button>
                </form>
            ) : (
                <p>댓글을 작성하려면 <a href="/login">로그인</a>이 필요합니다.</p>
            )}
            <div className="comments-list">
                {comments.map(comment => renderComment(comment))}
            </div>
            {totalPages > 1 && (
                <div className="pagination">
                    {/* 페이지네이션 컨트롤 추가 */}
                </div>
            )}
        </div>
    );
};

export default CommentSection;
