import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import { FaStar, FaExclamationTriangle, FaLightbulb, FaRobot, FaArrowLeft } from 'react-icons/fa';
import { getToken } from '../../../utils/auth';
import './ResumeEvaluationDetail.css';
import ResumeEvaluationResult from './ResumeEvaluationResult';
import SpinningLogo from '../../../components/SpinningLogo';

const ResumeEvaluationDetail = () => {
  const { id } = useParams();
  const [evaluation, setEvaluation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvaluation = async () => {
      try {
        const token = getToken();
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/resume-evaluation/${id}`, {
          headers: { 'x-auth-token': token }
        });
        
        console.log('서버 응답:', response.data);
        
        if (response.data) {
          setEvaluation(response.data);
        } else {
          setError('평가 결과 데이터가 없습니다.');
        }
        setLoading(false);
      } catch (error) {
        console.error('평가 결과 불러오기 실패:', error);
        setError(error.response?.data?.error || '평가 결과를 불러오는데 실패했습니다.');
        setLoading(false);
      }
    };

    fetchEvaluation();
  }, [id]);

  if (loading) {
    return (
      <div className="loading-overlay">
        <SpinningLogo text="로딩 중..." />
      </div>
    );
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!evaluation || !evaluation.scores) {
    return <div className="error">평가 결과를 찾을 수 없습니다.</div>;
  }

  return (
    <motion.div 
      className="evaluation-detail-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <ResumeEvaluationResult 
        evaluation={evaluation} 
        readOnly={true}
      />
    </motion.div>
  );
};

export default ResumeEvaluationDetail;