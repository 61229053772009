import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken } from '../../utils/auth';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ReportManagement = () => {
    const [reports, setReports] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [status, setStatus] = useState('pending');

    const fetchReports = async () => {
        try {
            const response = await axios.get(
                `${BACKEND_URL}/admin/reports?page=${currentPage}&status=${status}`,
                {
                    headers: { 'x-auth-token': getToken() }
                }
            );
            setReports(response.data.reports);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('신고 목록 조회 실패:', error);
        }
    };

    const handleReport = async (reportId, action) => {
        try {
            await axios.post(
                `${BACKEND_URL}/admin/reports/${reportId}`,
                { action },
                {
                    headers: { 'x-auth-token': getToken() }
                }
            );
            fetchReports();
        } catch (error) {
            console.error('신고 처리 실패:', error);
        }
    };

    useEffect(() => {
        fetchReports();
    }, [currentPage, status]);

    return (
        <div className="report-management">
            <h2>신고 관리</h2>
            <div className="status-filter">
                <select value={status} onChange={(e) => setStatus(e.target.value)}>
                    <option value="pending">처리 대기</option>
                    <option value="resolved">처리 완료</option>
                    <option value="rejected">반려</option>
                </select>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>신고 대상</th>
                        <th>신고 사유</th>
                        <th>신고자</th>
                        <th>신고일</th>
                        <th>관리</th>
                    </tr>
                </thead>
                <tbody>
                    {reports.map(report => (
                        <tr key={report._id}>
                            <td>{report.target_type === 'post' ? '게시글' : '댓글'}</td>
                            <td>{report.reason}</td>
                            <td>{report.reporter.username}</td>
                            <td>{new Date(report.createdAt).toLocaleDateString()}</td>
                            <td>
                                {report.status === 'pending' && (
                                    <>
                                        <button onClick={() => handleReport(report._id, 'resolve')}>
                                            처리
                                        </button>
                                        <button onClick={() => handleReport(report._id, 'reject')}>
                                            반려
                                        </button>
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ReportManagement; 