import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../utils/auth';
import './AccountTab.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const AccountTab = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;
  const navigate = useNavigate();

  const isFormValid = passwordRegex.test(newPassword) && newPassword === confirmPassword;

  const handleCheckPassword = async () => {
    const token = getToken();
    try {
      const response = await fetch(`${BACKEND_URL}/auth/check-pw`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify({ currentPassword }),
      });

      const data = await response.json();
      if (response.ok && data.valid) {
        return true;
      } else {
        alert('현재 비밀번호가 일치하지 않습니다.');
        return false;
      }
    } catch (error) {
      console.error('Error checking password:', error);
      alert('서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
      return false;
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (currentPassword === newPassword) {
      alert('새 비밀번호가 현재 비밀번호와 동일합니다.');
      return;
    }

    const isPasswordCorrect = await handleCheckPassword();
    if (!isPasswordCorrect) return;

    const token = getToken();
    try {
      const response = await fetch(`${BACKEND_URL}/auth/change-pw`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify({ newPassword }),
      });

      const data = await response.json();
      if (response.ok&& data.valid) {
        alert('비밀번호가 성공적으로 변경되었습니다.');
        navigate('/mypage');
      } else {
        alert(data.msg);
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      alert('서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  return (
    <div className="account-tab">
      <h2>회원정보 관리</h2>
      <form onSubmit={handleChangePassword}>
        <div className="form-group">
          <label htmlFor="currentPassword">현재 비밀번호</label>
          <input
            type="password"
            id="currentPassword"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="newPassword">새 비밀번호</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          {newPassword && (
            <div className={!passwordRegex.test(newPassword) ? 'option-fail-test' : ''}>
              {!passwordRegex.test(newPassword)
                ? '비밀번호는 8자~20자, 특수문자 1개 이상, 영문, 숫자를 포함해야 합니다.'
                : ''}
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">새 비밀번호 확인</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          {confirmPassword && (
            <div className={newPassword === confirmPassword ? '' : 'option-password-mismatch'}>
              {newPassword === confirmPassword ? '' : '패스워드가 틀립니다'}
            </div>
          )}
        </div>
        <button type="submit" className="submit-button" disabled={!isFormValid}>
          비밀번호 변경
        </button>
      </form>
    </div>
  );
};

export default AccountTab;