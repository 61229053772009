import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ResumeEvaluationResult from './ResumeEvaluationResult';
import SpinningLogo from '../../../components/SpinningLogo';
import { getToken } from '../../../utils/auth';
import './ResumeEvaluationPage.css';
import { FaPencilAlt, FaFileAlt, FaHeading, FaBriefcase } from 'react-icons/fa';

const ResumeEvaluation = () => {
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [field, setField] = useState('');
    const [resumeText, setResumeText] = useState('');
    const [evaluation, setEvaluation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showResult, setShowResult] = useState(false);
    const [showTokenAlert, setShowTokenAlert] = useState(false);
    const [inputType, setInputType] = useState('text'); // 'text' 또는 'coverLetter'
    const [coverLetters, setCoverLetters] = useState([]);
    const [selectedCoverLetter, setSelectedCoverLetter] = useState('');

    useEffect(() => {
        // 사용자의 자소서 목록 불러오기
        const fetchCoverLetters = async () => {
            try {
                const token = getToken();
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/tool1/coverletters`, {
                    headers: {
                        'x-auth-token': token
                    }
                });
                
                // 완료된 자기소개서만 필터링
                const completedCoverLetters = response.data.filter(
                    coverLetter => coverLetter.contentStatus === 'published'
                );
                
                setCoverLetters(completedCoverLetters);
            } catch (error) {
                console.error('자기소개서 목록 불러오기 실패:', error);
                setError('자기소개서 목록을 불러오는데 실패했습니다.');
            }
        };

        fetchCoverLetters();
    }, []);

    const handleCoverLetterSelect = async (coverLetterId) => {
        try {
            const token = getToken();
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/tool1/${coverLetterId}`, {
                headers: {
                    'x-auth-token': token
                }
            });

            const coverLetter = response.data;
            if (!coverLetter.content || coverLetter.content.length === 0) {
                alert('선택한 자기소개서의 내용이 없습니다.');
                return;
            }

            // 자기소개서 내용을 하나의 문자열로 변환
            const resumeContent = coverLetter.content
                .map(item => `${item.question}\n${item.answer}`)
                .join('\n\n');

            setResumeText(resumeContent);
            setSelectedCoverLetter(coverLetterId); // 선택된 자소서 ID 저장
        } catch (error) {
            console.error('자기소개서 불러오기 실패:', error);
            alert('자기소개서를 불러오는데 실패했습니다.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const token = getToken();
            if (!token) {
                setError('인증 토큰이 없습니다. 로그인 후 다시 시도해 주세요.');
                return;
            }

            let requestData = {
                title,
                field
            };

            // 입력 타입에 따라 다른 데이터 전송
            if (inputType === 'coverLetter') {
                if (!selectedCoverLetter) {
                    setError('자기소개서를 선택해주세요.');
                    return;
                }
                requestData.coverLetterId = selectedCoverLetter;
            } else {
                if (!resumeText.trim()) {
                    setError('자기소개서 내용을 입력해주세요.');
                    return;
                }
                requestData.resumeText = resumeText;
            }

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/resume-evaluation/evaluate`,
                requestData,
                {
                    headers: {
                        'x-auth-token': token,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.success) {
                setEvaluation(response.data.evaluation);
                setShowResult(true);
            } else {
                throw new Error(response.data.error);
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleError = (error) => {
        console.error("API 요청 중 오류 발생:", error);
        const errorMessage = error.response?.data?.error || error.message;
        
        if (errorMessage.includes('토큰이 부족합니다')) {
            setShowTokenAlert(true);
        } else {
            setError(errorMessage || '자소서 평가 중 오류가 발생했니다.');
        }
    };

    const handleRecharge = () => {
        navigate('/payment');
    };

    const handleReset = () => {
        setTitle('');
        setField('');
        setResumeText('');
        setEvaluation(null);
        setError(null);
        setShowResult(false);
    };

    const handleInputTypeChange = (type) => {
        setInputType(type);
        setResumeText(''); // 텍스트 초기화
        setSelectedCoverLetter(''); // 선택된 자소서 초기화
    };

    if (showResult) {
        return (
            <div className="resume-evaluation">
                <ResumeEvaluationResult evaluation={evaluation} onReset={handleReset} />
            </div>
        );
    }

    return (
        <div className="resume-evaluation-page">
            <div className="page-header">
                <h1>자기소개서 평가</h1>
                <p>AI가 당신의 자기소개서를 분석하고 개선점을 제안해드립니다.</p>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="input-fields">
                    <div className="input-field">
                        <label htmlFor="title">
                            <FaHeading /> 평가 제목
                        </label>
                        <input
                            type="text"
                            id="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="평가 제목을 입력하세요"
                            required
                            minLength="2"
                            maxLength="50"
                        />
                    </div>
                    <div className="input-field">
                        <label htmlFor="field">
                            <FaBriefcase /> 지원 분야
                        </label>
                        <input
                            type="text"
                            id="field"
                            value={field}
                            onChange={(e) => setField(e.target.value)}
                            placeholder="지원 분야를 입력하세요"
                            required
                            minLength="2"
                            maxLength="30"
                        />
                    </div>
                </div>

                <div className="input-selection-container">
                    <div 
                        className={`input-option ${inputType === 'text' ? 'selected' : ''}`}
                        onClick={() => handleInputTypeChange('text')}
                    >
                        <div className="input-option-header">
                            <div className="input-option-icon">
                                <FaPencilAlt />
                            </div>
                            <h3 className="input-option-title">직접 입력</h3>
                        </div>
                        <p className="input-option-description">
                            자기소개서 내용을 직접 입력하여 평가받습니다.
                            새로운 자기소개서나 수정된 내용을 평가받고 싶을 때 선택하세요.
                        </p>
                    </div>

                    <div 
                        className={`input-option ${inputType === 'coverLetter' ? 'selected' : ''}`}
                        onClick={() => handleInputTypeChange('coverLetter')}
                    >
                        <div className="input-option-header">
                            <div className="input-option-icon">
                                <FaFileAlt />
                            </div>
                            <h3 className="input-option-title">저장된 자기소개서</h3>
                        </div>
                        <p className="input-option-description">
                            이전에 작성한 자기소개서를 선택하여 평가받습니다.
                            저장된 자기소개서를 바로 평가받고 싶을 때 선택하세요.
                        </p>
                    </div>
                </div>

                {inputType === 'text' && (
                    <div className="text-input-area">
                        <textarea
                            value={resumeText}
                            onChange={(e) => {
                                if (e.target.value.length <= 5000) {
                                    setResumeText(e.target.value);
                                }
                            }}
                            placeholder="자기소개서 내용을 입력해주세요. (최대 5000자)"
                            maxLength={5000}
                        />
                        <div className="character-count">
                            {resumeText.length} / 5000자
                        </div>
                    </div>
                )}

                {inputType === 'coverLetter' && (
                    <div className="cover-letter-cards">
                        {coverLetters.length === 0 ? (
                            <div className="no-coverletter">
                                <p>저장된 자소서가 없습니다.</p>
                                <button onClick={() => navigate('/tools/resume-generator/setinfo')}>
                                    자소서 작성하러 가기
                                </button>
                            </div>
                        ) : (
                            coverLetters.map((letter) => (
                                <div 
                                    key={letter._id} 
                                    className={`cover-letter-card ${selectedCoverLetter === letter._id ? 'selected' : ''}`}
                                    onClick={() => handleCoverLetterSelect(letter._id)}
                                >
                                    <div className="card-header">
                                        <h4>{letter.title}</h4>
                                        <span className="company">{letter.companyName}</span>
                                    </div>
                                    <div className="card-body">
                                        <p className="job-info">{letter.jobTitle}</p>
                                        <p className="field-info">{letter.jobCategory}</p>
                                    </div>
                                    <div className="card-footer">
                                        <span className="date">
                                            {new Date(letter.updatedDt).toLocaleDateString()}
                                        </span>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                )}

                <div className="evaluation-button-container">
                    <button type="submit" className="submit-button" disabled={loading}>
                        {loading ? '평가 중...' : '평가하기'}
                    </button>
                </div>
            </form>
            {error && <p className="error">{error}</p>}
            {loading && (
                <div className="loading-overlay">
                    <SpinningLogo text="평가 중..." />
                </div>
            )}
            {showTokenAlert && (
                <div className="token-alert-overlay">
                    <div className="token-alert">
                        <h3>토큰 부족</h3>
                        <p>자소서 평가를 위한 토큰이 부족합니다. 충전이 필요합니다.</p>
                        <div className="token-alert-buttons">
                            <button onClick={handleRecharge}>충전하러 가기</button>
                            <button onClick={() => setShowTokenAlert(false)}>닫기</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ResumeEvaluation;