import React from 'react';

const RevenueStatsPage = () => {
  return (
    <div className="admin-page">
      <h1>매출 통계</h1>
      <div className="stats-container">
        <div className="stats-card">
          <h3>일일 매출</h3>
          <p className="stats-number">준비중</p>
        </div>
        <div className="stats-card">
          <h3>주간 매출</h3>
          <p className="stats-number">준비중</p>
        </div>
        <div className="stats-card">
          <h3>월간 매출</h3>
          <p className="stats-number">준비중</p>
        </div>
      </div>
    </div>
  );
};

export default RevenueStatsPage; 