import React, { useState } from 'react';
import PostList from './PostList';
import './CommunityPage.css';
import { Link } from 'react-router-dom';
import { getToken } from '../../utils/auth';

const CommunityPage = () => {
  const [currentTab, setCurrentTab] = useState('전체');
  const [sortBy, setSortBy] = useState('인기순');
  const isLoggedIn = !!getToken();

  const tabs = ['전체', '인기', '문의', '신청', '고민', '학사', '여행', 'Q&A', '자유'];

  return (
    <div className="community-page">
      <h1>잡커뮤니티</h1>
      <div className="community-controls">
        <div className="tabs">
          {tabs.map(tab => (
            <button
              key={tab}
              onClick={() => setCurrentTab(tab)}
              className={currentTab === tab ? 'active' : ''}
            >
              {tab}
            </button>
          ))}
        </div>
        <div className="sort-write">
          <select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
            <option value="인기순">인기순</option>
            <option value="최신순">최신순</option>
          </select>
          {isLoggedIn ? (
            <Link to="/community/writePost" className="write-button">
              글쓰기
            </Link>
          ) : (
            <Link to="/login" className="write-button">
              로그인하여 글쓰기
            </Link>
          )}
        </div>
      </div>
      <PostList category={currentTab === '전체' ? null : currentTab} sortBy={sortBy} />
    </div>
  );
};

export default CommunityPage;
