import React from 'react';
import ReportManagement from '../../components/admin/ReportManagement';

const ReportsManagementPage = () => {
  return (
    <div className="admin-page">
      <h1>신고 관리</h1>
      <ReportManagement />
    </div>
  );
};

export default ReportsManagementPage; 