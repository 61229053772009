import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken } from '../utils/auth';
import { initialUserDetailState, validateUserDetail } from '../utils/userDetailUtils';
import styles from './UserDetailEdit.module.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// axios 인스턴스 생성
const api = axios.create({
  baseURL: BACKEND_URL,
  withCredentials: true
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['x-auth-token'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const UserDetailEdit = ({ isOpen, onClose, userDetail, onSave }) => {
  const [selectedField, setSelectedField] = useState(userDetail?.major || '');
  const [selectedExperience, setSelectedExperience] = useState(userDetail?.experience || '');
  const [selectedEmploymentType, setSelectedEmploymentType] = useState(userDetail?.employmentType || '');
  const [selectedRegion, setSelectedRegion] = useState(userDetail?.preferredRegion || '');
  const [careerStatus, setCareerStatus] = useState(userDetail?.careerStatus || '');
  const [education, setEducation] = useState(userDetail?.education || '');
  const [yearsOfExperience, setYearsOfExperience] = useState(userDetail?.yearsOfExperience || '');
  const [recentPosition, setRecentPosition] = useState(userDetail?.recentPosition || '');
  const [recentCompany, setRecentCompany] = useState(userDetail?.recentCompany || '');
  const [salaryRange, setSalaryRange] = useState(userDetail?.salaryRange || [20000000, 45000000]);
  const [startDate, setStartDate] = useState(userDetail?.startDate || '');
  const [workHours, setWorkHours] = useState(userDetail?.workHours || '09:00-18:00');
  const [remoteWork, setRemoteWork] = useState(userDetail?.remoteWork || '유연 근무 불가능');
  const [portfolioLinks, setPortfolioLinks] = useState(userDetail?.portfolioLinks || []);
  const [careerHighlights, setCareerHighlights] = useState(userDetail?.careerHighlights || ['', '', '']);
  const [skills, setSkills] = useState(userDetail?.skills || ['', '', '']);
  const [certifications, setCertifications] = useState(userDetail?.certifications || ['', '']);
  const [isNewGraduate, setIsNewGraduate] = useState(userDetail?.isNewGraduate || false);
  const [graduationYear, setGraduationYear] = useState(userDetail?.graduationYear || '');
  const [gpa, setGpa] = useState(userDetail?.gpa || '');
  const [majorExperience, setMajorExperience] = useState(userDetail?.majorExperience || '');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const allUserData = {
        major: selectedField,
        experience: selectedExperience,
        employmentType: selectedEmploymentType,
        preferredRegion: selectedRegion,
        careerStatus,
        education,
        yearsOfExperience,
        recentPosition,
        recentCompany,
        minSalary: salaryRange[0],
        maxSalary: salaryRange[1],
        startDate,
        workHours,
        remoteWork,
        portfolioLinks: portfolioLinks.filter(link => link.trim() !== ''),
        careerHighlights: careerHighlights.filter(highlight => highlight.trim() !== ''),
        skills: skills.filter(skill => skill.trim() !== ''),
        certifications: certifications.filter(cert => cert.trim() !== ''),
        isNewGraduate,
        graduationYear,
        gpa,
        majorExperience,
      };

      const errors = validateUserDetail(allUserData);
      if (Object.keys(errors).length > 0) {
        const errorMessage = Object.values(errors).join('\n');
        toast.error(errorMessage);
        return;
      }

      const response = await api.post('/user/detail', allUserData);
      
      if (response.data.success) {
        toast.success('프로필이 성공적으로 업데이트되었습니다.');
        onSave(allUserData);
        onClose();
      } else {
        throw new Error(response.data.message || '서버 응답이 실패했습니다.');
      }
    } catch (error) {
      console.error('Error saving user details:', error);
      toast.error(error.message || '프로필 저장 중 오류가 발생했습니다.');
    }
  };

  const handleFieldChange = (e) => setSelectedField(e.target.value);
  const handleExperienceChange = (e) => setSelectedExperience(e.target.value);
  const handleEmploymentTypeChange = (e) => setSelectedEmploymentType(e.target.value);
  const handleRegionChange = (e) => setSelectedRegion(e.target.value);
  const handleCareerStatusChange = (e) => setCareerStatus(e.target.value);
  const handleEducationChange = (e) => setEducation(e.target.value);
  const handleYearsOfExperienceChange = (e) => setYearsOfExperience(e.target.value);
  const handleRecentPositionChange = (e) => setRecentPosition(e.target.value);
  const handleRecentCompanyChange = (e) => setRecentCompany(e.target.value);
  const handleSalaryRangeChange = (index) => (e) => {
    const newSalaryRange = [...salaryRange];
    newSalaryRange[index] = parseInt(e.target.value);
    setSalaryRange(newSalaryRange);
  };
  const handleStartDateChange = (e) => setStartDate(e.target.value);
  const handleWorkHoursChange = (e) => setWorkHours(e.target.value);
  const handleRemoteWorkChange = (e) => setRemoteWork(e.target.value);
  const handleIsNewGraduateChange = (e) => setIsNewGraduate(e.target.checked);
  const handleGraduationYearChange = (e) => setGraduationYear(e.target.value);
  const handleGpaChange = (e) => setGpa(e.target.value);
  const handleMajorExperienceChange = (e) => setMajorExperience(e.target.value);

  const handleArrayFieldChange = (field, setter) => (index, value) => {
    setter(prev => {
      const newArray = [...prev];
      newArray[index] = value;
      return newArray;
    });
  };

  const handleArrayFieldAdd = (field, setter, maxLength) => () => {
    setter(prev => {
      if (prev.length >= maxLength) {
        toast.warning(`최대 ${maxLength}개까지만 추가할 수 있습니다.`);
        return prev;
      }
      return [...prev, ''];
    });
  };

  const handleArrayFieldRemove = (field, setter) => (index) => {
    setter(prev => prev.filter((_, i) => i !== index));
  };

  if (!isOpen) return null;

  const renderArrayInput = (fieldArray, setFieldArray, label, placeholder) => (
    <div className={styles.formGroup}>
      <label className={styles.label}>{label}</label>
      <div className={styles.arrayInputContainer}>
        {fieldArray.map((item, index) => (
          <div key={index} className={styles.arrayInputItem}>
            <input
              className={styles.input}
              type="text"
              value={item}
              onChange={(e) => {
                const newArray = [...fieldArray];
                newArray[index] = e.target.value;
                setFieldArray(newArray);
              }}
              placeholder={placeholder}
            />
            <button 
              type="button" 
              className={styles.removeButton} 
              onClick={() => {
                const newArray = fieldArray.filter((_, i) => i !== index);
                setFieldArray(newArray);
              }}
            >
              🗑
            </button>
          </div>
        ))}
        <div className={styles.toolbar}>
          <button 
            type="button" 
            className={styles.addButton} 
            onClick={() => setFieldArray([...fieldArray, ''])}
          >
            +
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>상세 정보 수정</h2>
          <button className={styles.closeButton} onClick={onClose}>&times;</button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label className={styles.label} htmlFor="major">전공</label>
            <input
              className={styles.input}
              type="text"
              id="major"
              name="major"
              value={selectedField}
              onChange={handleFieldChange}
              placeholder="전공을 입력하세요"
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label} htmlFor="experience">경력</label>
            <select
              className={styles.select}
              id="experience"
              name="experience"
              value={selectedExperience}
              onChange={handleExperienceChange}
            >
              <option value="">선택해주세요</option>
              <option value="신입">신입</option>
              <option value="경력">경력</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label} htmlFor="employmentType">직종</label>
            <input
              className={styles.input}
              type="text"
              id="employmentType"
              name="employmentType"
              value={selectedEmploymentType}
              onChange={handleEmploymentTypeChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label} htmlFor="preferredRegion">선호 지역</label>
            <input
              className={styles.input}
              type="text"
              id="preferredRegion"
              name="preferredRegion"
              value={selectedRegion}
              onChange={handleRegionChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label} htmlFor="education">최종 학력</label>
            <select
              className={styles.select}
              id="education"
              name="education"
              value={education}
              onChange={handleEducationChange}
            >
              <option value="">선택해주세요</option>
              <option value="고졸">고졸</option>
              <option value="전문대졸">전문대졸</option>
              <option value="대졸">대졸</option>
              <option value="석사">석사</option>
              <option value="박사">박사</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label} htmlFor="yearsOfExperience">경력 연수</label>
            <input
              className={styles.input}
              type="number"
              id="yearsOfExperience"
              name="yearsOfExperience"
              value={yearsOfExperience}
              onChange={handleYearsOfExperienceChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label} htmlFor="recentPosition">최근 직무</label>
            <input
              className={styles.input}
              type="text"
              id="recentPosition"
              name="recentPosition"
              value={recentPosition}
              onChange={handleRecentPositionChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label} htmlFor="recentCompany">최근 근무지</label>
            <input
              className={styles.input}
              type="text"
              id="recentCompany"
              name="recentCompany"
              value={recentCompany}
              onChange={handleRecentCompanyChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label} htmlFor="minSalary">최소 희망 연봉</label>
            <input
              className={styles.input}
              type="number"
              id="minSalary"
              name="minSalary"
              value={salaryRange[0]}
              onChange={handleSalaryRangeChange(0)}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label} htmlFor="maxSalary">최대 희망 연봉</label>
            <input
              className={styles.input}
              type="number"
              id="maxSalary"
              name="maxSalary"
              value={salaryRange[1]}
              onChange={handleSalaryRangeChange(1)}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label} htmlFor="startDate">근무 시작 가능일</label>
            <input
              className={styles.input}
              type="date"
              id="startDate"
              name="startDate"
              value={startDate}
              onChange={handleStartDateChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label} htmlFor="workHours">근무 가능 시간대</label>
            <select
              className={styles.select}
              id="workHours"
              name="workHours"
              value={workHours}
              onChange={handleWorkHoursChange}
            >
              <option value="">선택해세요</option>
              <option value="09:00-18:00">09:00-18:00 (일반)</option>
              <option value="08:00-17:00">08:00-17:00 (조기)</option>
              <option value="10:00-19:00">10:00-19:00 (늦은)</option>
              <option value="13:00-22:00">13:00-22:00 (오후)</option>
              <option value="22:00-07:00">22:00-07:00 (야간)</option>
              <option value="flexible">유연근무제</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label} htmlFor="remoteWork">유연 근무제</label>
            <select
              className={styles.select}
              id="remoteWork"
              name="remoteWork"
              value={remoteWork}
              onChange={handleRemoteWorkChange}
            >
              <option value="">선택해주세요</option>
              <option value="유연 근무제 불가능">유연 근무제 불가능</option>
              <option value="유연 근무제 가능">유연 근무제 가능</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label} htmlFor="careerStatus">경력 상태</label>
            <select
              className={styles.select}
              id="careerStatus"
              name="careerStatus"
              value={careerStatus}
              onChange={handleCareerStatusChange}
            >
              <option value="">선택해주세요</option>
              <option value="재직중">재직중</option>
              <option value="구직중">구직중</option>
              <option value="휴직중">휴직중</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>
              <input
                type="checkbox"
                checked={isNewGraduate}
                onChange={handleIsNewGraduateChange}
              />
              신입 졸업자
            </label>
          </div>
          {isNewGraduate && (
            <>
              <div className={styles.formGroup}>
                <label className={styles.label} htmlFor="graduationYear">졸업 연도</label>
                <input
                  className={styles.input}
                  type="text"
                  id="graduationYear"
                  name="graduationYear"
                  value={graduationYear}
                  onChange={handleGraduationYearChange}
                />
              </div>
              <div className={styles.formGroup}>
                <label className={styles.label} htmlFor="gpa">학점</label>
                <input
                  className={styles.input}
                  type="text"
                  id="gpa"
                  name="gpa"
                  value={gpa}
                  onChange={handleGpaChange}
                />
              </div>
              <div className={styles.formGroup}>
                <label className={styles.label} htmlFor="majorExperience">전공 경험</label>
                <textarea
                  className={styles.textarea}
                  id="majorExperience"
                  name="majorExperience"
                  value={majorExperience}
                  onChange={handleMajorExperienceChange}
                />
              </div>
            </>
          )}
          {renderArrayInput(skills, setSkills, '스킬', '스킬을 입력하세요')}
          {renderArrayInput(certifications, setCertifications, '자격증', '자격증을 입력하세요')}
          {renderArrayInput(portfolioLinks, setPortfolioLinks, '포트폴리오 링크', '링크를 입력하세요')}
          {renderArrayInput(careerHighlights, setCareerHighlights, '경력 하이라이트', '경력 하이라이트를 입력하세요')}
          <div className={styles.formActions}>
            <button type="button" onClick={onClose} className={styles.cancelButton}>취소</button>
            <button type="submit" className={styles.saveButton}>저장</button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UserDetailEdit;
