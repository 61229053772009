import React from 'react';
import styled from 'styled-components';

const AnswerFeedback = ({ feedback }) => {
  if (!feedback) return null;

  return (
    <FeedbackContainer>
      <ScoreSection>
        <ScoreTitle>답변 평가 점수</ScoreTitle>
        <ScoreValue>{feedback.score}/100</ScoreValue>
      </ScoreSection>

      <FeedbackSection>
        <FeedbackTitle>피드백</FeedbackTitle>
        <FeedbackList>
          <FeedbackItem>
            <Label>강점:</Label>
            <Content>{feedback.strengths}</Content>
          </FeedbackItem>
          <FeedbackItem>
            <Label>개선점:</Label>
            <Content>{feedback.improvements}</Content>
          </FeedbackItem>
          <FeedbackItem>
            <Label>추천사항:</Label>
            <Content>{feedback.recommendations}</Content>
          </FeedbackItem>
        </FeedbackList>
      </FeedbackSection>
    </FeedbackContainer>
  );
};

const FeedbackContainer = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
`;

const ScoreSection = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
`;

const ScoreTitle = styled.h3`
  color: #666;
  margin-bottom: 0.5rem;
`;

const ScoreValue = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  color: #2E74FF;
`;

const FeedbackSection = styled.div``;

const FeedbackTitle = styled.h3`
  color: #333;
  margin-bottom: 1rem;
`;

const FeedbackList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FeedbackItem = styled.div`
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 5px;
`;

const Label = styled.span`
  font-weight: bold;
  color: #2E74FF;
  margin-right: 0.5rem;
`;

const Content = styled.p`
  margin: 0.5rem 0 0 0;
  color: #444;
  line-height: 1.5;
`;

export default AnswerFeedback; 