import React from 'react';

const NewMemberStatsPage = () => {
  return (
    <div className="admin-page">
      <h1>신규 회원 통계</h1>
      <div className="stats-container">
        <div className="stats-card">
          <h3>오늘 가입자</h3>
          <p className="stats-number">준비중</p>
        </div>
        <div className="stats-card">
          <h3>이번 주 가입자</h3>
          <p className="stats-number">준비중</p>
        </div>
        <div className="stats-card">
          <h3>이번 달 가입자</h3>
          <p className="stats-number">준비중</p>
        </div>
      </div>
    </div>
  );
};

export default NewMemberStatsPage; 