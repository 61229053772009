const jobRolesAndTasks = {
    "기획•전략": {
      "경영•비즈니스 기획": ["사업 전략 수립", "시장 조사 및 분석", "비즈니스 모델 개발"],
      "웹기획": ["웹사이트 구조 설계", "사용자 경험 개선", "프로젝트 일정 관리"],
      "마케팅기획": ["마케팅 전략 수립", "고객 세그먼트 분석", "프로모션 기획"],
      "PL•PM•PO": ["프로젝트 관리 및 일정 조율", "제품 로드맵 개발", "팀 간 커뮤니케이션 관리"],
      "컨설턴트": ["기업 문제 분석", "솔루션 제안", "성과 평가 및 개선 방안 제시"],
      "CEO•COO•CTO": ["회사 경영 전략 수립", "조직 관리", "혁신적인 기술 방향 설정"]
    },
    
    "인사•HR": {
      "인사담당자": ["채용 계획 수립", "인재 관리 및 평가", "복리후생 운영"],
      "HRD•HRM": ["인재 개발 및 교육 프로그램 운영", "성과 관리 시스템 운영", "인적 자원 관리 정책 개발"],
      "노무관리자": ["근로 계약 관리", "노사 관계 관리", "산업 안전 관리"],
      "잡매니저": ["채용 공고 작성", "후보자 면접 진행", "입사자 관리"],
      "헤드헌터": ["고급 인재 발굴", "기업 및 후보자 매칭", "이직 컨설팅"],
      "직업상담사": ["직업 상담 및 직업 선택 지원", "재취업 지원", "커리어 컨설팅"]
    },
    
    "마케팅•광고MD": {
      "광고기획자": ["광고 캠페인 전략 수립", "매체 선정 및 운영", "광고 성과 분석"],
      "브랜드마케터": ["브랜드 이미지 관리", "브랜드 전략 개발", "고객 접점 관리"],
      "퍼포먼스마케터": ["디지털 광고 운영", "광고 성과 최적화", "ROI 분석"],
      "CRM마케터": ["고객 관계 관리", "고객 데이터 분석", "고객 유지 전략 수립"],
      "온라인마케터": ["소셜 미디어 관리", "콘텐츠 기획 및 운영", "검색 엔진 최적화(SEO)"],
      "콘텐츠마케터": ["콘텐츠 전략 수립", "콘텐츠 제작 및 배포", "콘텐츠 성과 분석"]
    },
    
    "디자인": {
      "그래픽디자이너": ["로고 및 브랜드 디자인", "광고물 디자인", "제품 패키지 디자인"],
      "3D디자이너": ["3D 모델링", "렌더링 및 애니메이션 제작", "제품 디자인 및 시각화"],
      "제품디자이너": ["제품 컨셉 개발", "디자인 목업 제작", "사용자 테스트"],
      "산업디자이너": ["산업용 제품 설계", "소재 및 생산 공정 연구", "제품 테스트 및 개선"],
      "광고디자이너": ["광고 배너 디자인", "브랜딩 비주얼 개발", "프로모션 시각 자료 제작"],
      "시각디자이너": ["시각적 콘텐츠 제작", "웹 및 앱 인터페이스 디자인", "사용자 경험 디자인"]
    },
    
    "운전•운송•배송": {
      "납품•배송기사": ["물품 배송 및 전달", "배송 일정 관리", "배송 차량 유지관리"],
      "배달기사": ["식음료 배달", "고객 응대", "배달 경로 최적화"],
      "수행•운전기사": ["임원 수행 운전", "차량 점검 및 유지관리", "안전 운행 관리"],
      "화물•중장비기사": ["화물 운송", "중장비 조작", "물류 창고 관리"],
      "버스기사": ["정기 노선 운행", "승객 안전 관리", "운행 기록 관리"],
      "택시기사": ["승객 운송", "운행 경로 최적화", "고객 서비스 제공"]
    },
    
    "고객상담•TM": {
      "인바운드상담원": ["고객 문의 응대", "문제 해결 및 정보 제공", "콜 기록 관리"],
      "아웃바운드상담원": ["고객 피드백 수집", "제품 및 서비스 소개", "고객 설문 진행"],
      "고객센터관리자": ["상담원 관리 및 교육", "고객 불만 처리", "서비스 개선 방안 마련"]
    },
    
    "법무•사무•총무": {
      "경영지원": ["경영진 지원 업무", "회의 준비 및 자료 관리", "일반 행정 업무"],
      "사무담당자": ["서류 작성 및 정리", "문서 관리", "사무 기기 유지보수"],
      "총무": ["사무용품 관리", "시설 유지 관리", "행사 및 복리후생 운영"],
      "사무보조": ["사무 지원 업무", "자료 정리 및 파일링", "전화 응대"],
      "법무담당자": ["계약 검토 및 법률 자문", "법적 이슈 관리", "법적 문서 작성"],
      "비서": ["일정 관리", "전화 및 이메일 관리", "비서 업무"]
    },
    
    "회계•세무": {
      "회계담당자": ["재무 보고서 작성", "회계 데이터 분석", "자산 관리"],
      "경리": ["입출금 관리", "자금 흐름 분석", "급여 계산"],
      "세무담당자": ["세금 신고 및 계산", "세금 절감 방안 수립", "법인세 및 부가세 관리"],
      "재무담당자": ["자산 및 부채 관리", "재무 분석", "재무 계획 수립"],
      "감사": ["내부 통제 점검", "리스크 평가", "감사 보고서 작성"],
      "IR•공시": ["투자자 소통", "공시 정보 작성", "주주 정보 관리"]
    },
    
    "개발•데이터": {
      "백엔드개발자": ["서버 사이드 로직 개발", "API 개발 및 관리", "데이터베이스 설계"],
      "프론트엔드개발자": ["UI 및 UX 개발", "웹 애플리케이션 개발", "React, Vue 등 활용"],
      "웹개발자": ["웹사이트 개발 및 유지관리", "HTML, CSS, JavaScript 활용", "서버 연동 작업"],
      "앱개발자": ["모바일 앱 개발", "iOS 및 Android 개발", "사용자 경험 최적화"],
      "시스템엔지니어": ["서버 설치 및 유지보수", "네트워크 관리", "보안 설정"],
      "네트워크엔지니어": ["네트워크 설계 및 관리", "보안 관리", "트래픽 모니터링"],
      "DBA": ["데이터베이스 관리", "백업 및 복구", "데이터 최적화"],
      "데이터엔지니어": ["데이터 파이프라인 구축", "데이터 처리", "ETL 설계"],
      "데이터사이언티스트": ["데이터 분석 및 모델링", "머신러닝 모델 개발", "비즈니스 인사이트 도출"],
      "보안엔지니어": ["시스템 보안 강화", "취약점 분석", "보안 솔루션 구축"],
      "소프트웨어개발자": ["소프트웨어 설계 및 개발", "코드 최적화", "버그 수정"],
      "게임개발자": ["게임 디자인 및 개발", "게임 엔진 활용", "플레이어 경험 최적화"],
      "하드웨어개발자": ["하드웨어 설계", "회로 개발", "테스트 및 개선"],
      "머신러닝엔지니어": ["머신러닝 모델 개발", "데이터 전처리", "모델 평가 및 최적화"],
      "블록체인개발자": ["블록체인 네트워크 구축", "스마트 계약 개발", "보안 강화"],
      "클라우드엔지니어": ["클라우드 인프라 설계", "AWS, GCP 등 관리", "클라우드 보안 설정"],
      "웹퍼블리셔": ["웹 콘텐츠 퍼블리싱", "HTML, CSS 코딩", "크로스 브라우징 최적화"],
      "IT컨설팅": ["IT 시스템 분석", "기술 솔루션 제안", "비용 절감 방안 수립"],
      "QA": ["품질 검사 및 테스트", "테스트 계획 수립", "결함 보고 및 수정"]
    },
    
    "물류•무역": {
      "물류관리자": ["재고 관리", "물류 프로세스 개선", "물류 비용 절감"],
      "구매관리자": ["구매 계획 수립", "공급업체 관리", "가격 협상"],
      "자재관리자": ["자재 수급 관리", "재고 수준 최적화", "공급망 관리"],
      "유통관리자": ["유통 전략 수립", "상품 배송 관리", "물류 파트너 협력"],
      "무역사무원": ["수출입 서류 작성", "통관 절차 진행", "운송 및 보험 관리"]
    },
    
    "영업": {
        "제품영업": ["제품 설명 및 데모 제공", "고객 요구 분석", "판매 목표 달성"],
        "서비스영업": ["서비스 소개 및 상담", "고객 맞춤형 제안", "고객 유지 관리"],
        "해외영업": ["해외 고객 관리", "수출입 업무 처리", "해외 시장 조사"],
        "광고영업": ["광고 상품 판매", "매체별 광고 전략 수립", "광고 성과 분석"],
        "금융영업": ["금융 상품 설명", "고객 투자 상담", "금융 솔루션 제공"],
        "법인영업": ["법인 고객 대상 영업", "고객 니즈 파악", "맞춤형 솔루션 제공"],
        "IT•기술영업": ["IT 솔루션 제안", "기술 요구 사항 분석", "기술 지원 제공"],
        "영업관리": ["판매 실적 관리", "영업 목표 설정 및 달성", "영업팀 지원"],
        "영업지원": ["영업 자료 준비", "고객 문의 처리", "계약서 작성 및 관리"]
      },
      
      "금융•보험": {
        "금융사무": ["금융 거래 처리", "서류 검토 및 작성", "데이터 관리"],
        "보험설계사": ["보험 상품 소개", "고객 맞춤형 설계", "보험 계약 체결"],
        "손해사정사": ["보험 청구 조사", "손해 평가", "보험금 산정"],
        "심사": ["신용 평가", "위험 분석", "대출 심사"],
        "은행원•텔러": ["입출금 업무 처리", "고객 상담", "금융 상품 소개"],
        "계리사": ["보험료 산정", "보험 위험 평가", "통계 및 확률 분석"],
        "펀드매니저": ["투자 포트폴리오 관리", "시장 동향 분석", "수익률 최적화"],
        "애널리스트": ["시장 조사 및 분석", "기업 평가", "투자 리포트 작성"]
      },
      
      "식•음료": {
        "요리사": ["음식 조리 및 개발", "재료 준비 및 관리", "주방 위생 관리"],
        "조리사": ["조리 작업 보조", "재료 손질", "주방 정리"],
        "제과제빵사": ["빵 및 케이크 제작", "재료 계량 및 혼합", "제과 레시피 개발"],
        "바리스타": ["커피 및 음료 제조", "고객 응대", "커피 머신 관리"],
        "셰프•주방장": ["주방 총괄 관리", "메뉴 기획 및 개발", "요리팀 관리"],
        "카페•레스토랑매니저": ["매장 운영 관리", "고객 서비스 관리", "매출 및 재고 관리"],
        "홀서버": ["고객 주문 접수 및 서빙", "테이블 정리", "고객 응대"],
        "주방보조": ["주방 보조 작업", "재료 준비", "청소 및 정리"],
        "소믈리에•바텐더": ["와인 및 칵테일 추천", "음료 제조", "와인 및 주류 관리"],
        "영양사": ["식단 계획 및 영양 상담", "식재료 관리", "건강 식단 개발"],
        "식품연구원": ["신제품 연구 개발", "식품 안정성 검사", "영양 성분 분석"],
        "푸드스타일리스트": ["음식 스타일링", "촬영용 음식 준비", "비주얼 연출"]
      },
      
      "엔지니어링•설계": {
        "전기•전자엔지니어": ["회로 설계 및 개발", "전자 제품 테스트", "문제 해결 및 개선"],
        "기계엔지니어": ["기계 설계 및 제작", "시스템 개선", "기계 부품 개발"],
        "설계엔지니어": ["CAD를 사용한 설계", "구조 분석 및 해석", "설계 도면 작성"],
        "설비엔지니어": ["설비 설치 및 유지관리", "설비 점검", "설비 개선 작업"],
        "반도체엔지니어": ["반도체 설계 및 개발", "제조 공정 관리", "품질 검사"],
        "화학엔지니어": ["화학 공정 설계", "제품 개발", "화학 안전 관리"],
        "공정엔지니어": ["제조 공정 설계", "공정 최적화", "생산 품질 관리"],
        "하드웨어엔지니어": ["하드웨어 설계 및 개발", "테스트 및 유지보수", "회로 설계"],
        "통신엔지니어": ["네트워크 설계", "통신 장비 설치 및 유지", "통신 시스템 개선"],
        "RF엔지니어": ["RF 회로 설계", "무선 통신 테스트", "신호 분석"],
        "필드엔지니어": ["현장 기술 지원", "설비 점검 및 유지보수", "고객 지원"],
        "R&D•연구원": ["신기술 연구 및 개발", "제품 성능 테스트", "데이터 분석"]
      },
      
      "교육": {
        "유치원•보육교사": ["어린이 돌봄 및 교육", "교육 프로그램 운영", "아이들 안전 관리"],
        "학교•특수학교교사": ["수업 계획 및 진행", "학생 지도", "교육 자료 준비"],
        "대학교수•강사": ["강의 준비 및 진행", "연구 및 논문 작성", "학생 평가"],
        "학원강사": ["강의 준비 및 진행", "학생 관리", "진도 체크 및 성적 관리"],
        "외국어강사": ["외국어 수업 진행", "학생 연습 관리", "교재 및 자료 준비"],
        "기술•전문강사": ["전문 기술 교육", "실습 지도", "교재 및 자료 준비"],
        "학습지•방문교사": ["학생 방문 교육", "학습 계획 수립", "진도 관리 및 피드백"],
        "학원상담•운영": ["학원 운영 관리", "학생 및 학부모 상담", "학원 등록 관리"],
        "교직원•조교": ["교수 업무 보조", "자료 정리 및 관리", "학생 지원 업무"],
        "교재개발•교수설계": ["교육 자료 개발", "커리큘럼 설계", "수업 자료 제작"]
      },
      
      "의료•바이오": {
        "의사": ["진료 및 치료 제공", "환자 상담", "진료 기록 관리"],
        "한의사": ["한방 진료 및 치료", "침술 및 약재 처방", "환자 관리"],
        "간호사": ["환자 돌봄", "의료 기록 관리", "환자 상태 모니터링"],
        "간호조무사": ["환자 지원 업무", "간호사 보조", "병실 정리 및 관리"],
        "약사•한약사": ["처방약 조제", "약물 상담", "약품 관리"],
        "의료기사": ["진단 검사 수행", "의료 기기 관리", "검사 결과 분석"],
        "수의사": ["동물 진료 및 치료", "예방 접종", "수술 및 응급 처치"],
        "수의테크니션": ["수의사 보조 업무", "동물 돌봄 및 관리", "의료 기기 관리"],
        "병원코디네이터": ["진료 일정 관리", "환자 접수", "병원 서비스 안내"],
        "원무행정": ["환자 등록 및 접수", "진료 기록 관리", "병원 재정 관리"],
        "기타의료종사자": ["기타 의료 서비스 제공", "환자 지원 업무", "기타 병원 업무"],
        "의료•약무보조": ["약물 관리 지원", "의료 기기 관리", "환자 지원"],
        "바이오•제약연구원": ["신약 개발 연구", "실험 데이터 분석", "생명공학 연구"],
        "임상연구원": ["임상 시험 계획", "임상 데이터 수집", "연구 결과 분석"]
      },
      
      "공공•복지": {
        "사회복지사": ["사회 복지 상담", "복지 프로그램 운영", "자원 연계 관리"],
        "요양보호사": ["노인 돌봄 및 관리", "생활 지원", "건강 상태 모니터링"],
        "환경미화원": ["공공 장소 청소", "쓰레기 수거", "환경 유지 관리"],
        "보건관리자": ["건강 검진 관리", "보건 프로그램 운영", "질병 예방 관리"],
        "사서": ["도서관 자료 관리", "이용자 지원", "자료 검색 및 정리"],
        "자원봉사자": ["지역 사회 봉사 활동", "이웃 지원", "행사 운영 지원"]
      },
      
      "고객서비스•리테일": {
        "설치•수리기사": ["기기 설치 및 수리", "고장 진단", "고객 상담"],
        "정비기사": ["차량 및 기계 정비", "정기 점검", "부품 교체"],
        "호텔종사자": ["호텔 객실 청소", "고객 응대", "호텔 시설 관리"],
        "여행에이전트": ["여행 상품 안내", "예약 및 발권", "고객 여행 계획 지원"],
        "매장관리사": ["매장 운영 관리", "재고 관리", "고객 서비스 제공"],
        "뷰티•미용사": ["헤어 및 메이크업", "피부 관리", "고객 상담"],
        "애견미용•훈련": ["반려동물 미용", "훈련 및 돌봄", "위생 관리"],
        "안내데스크•리셉셔니스트": ["방문객 응대", "전화 응대", "안내 및 지원"],
        "경호•경비": ["건물 및 행사 경비", "순찰 및 보안 유지", "위험 상황 대응"],
        "운영보조•매니저": ["매장 운영 보조", "직원 관리", "고객 불만 처리"],
        "이벤트•웨딩플래너": ["이벤트 기획 및 준비", "현장 관리", "고객 상담"],
        "주차•주유원": ["차량 주차 지원", "주유 서비스 제공", "고객 응대"],
        "스타일리스트": ["의상 및 액세서리 추천", "패션 스타일링", "개인 맞춤 코디"],
        "장례지도사": ["장례식 준비 및 진행", "유가족 상담", "장례 예식 관리"],
        "가사도우미": ["가사 지원 업무", "청소 및 정리", "음식 준비"],
        "승무원": ["항공 승객 서비스", "안전 점검", "비행 중 응대"],
        "플로리스트": ["꽃다발 및 장식물 제작", "꽃 관리 및 정리", "고객 맞춤 디자인"]
      },
      
      "제조•생산": {
        "생산직종사자": ["제품 조립 및 가공", "생산 라인 운영", "제품 검사"],
        "생산•공정관리자": ["생산 계획 수립", "품질 관리", "생산 라인 관리"],
        "품질관리자": ["품질 검사", "제품 표준 유지", "문제 해결"],
        "포장•기공담당자": ["제품 포장 및 기공", "포장 품질 관리", "포장 기기 관리"],
        "공장관리자": ["공장 운영 관리", "생산 계획 조정", "재고 및 물류 관리"],
        "용접사": ["금속 용접 및 가공", "설비 유지보수", "안전 수칙 준수"]
      },
      
      "건축•시설": {
        "건축가": ["건축 디자인 및 설계", "건축 도면 작성", "건축 자재 선택"],
        "건축기사": ["건축 현장 관리", "설계도 검토 및 조정", "현장 점검"],
        "시공기사": ["건설 현장 관리", "작업 일정 계획", "안전 관리"],
        "전기기사": ["전기 설비 설치 및 유지", "전기 안전 검사", "배선 관리"],
        "토목기사": ["토목 구조 설계", "토목 공사 관리", "현장 조사 및 분석"],
        "시설관리자": ["건물 유지 및 관리", "설비 점검", "안전 및 보안 관리"],
        "현장관리자": ["현장 일정 및 인력 관리", "자재 관리", "안전 관리"],
        "안전관리자": ["작업장 안전 점검", "위험 요인 분석", "안전 교육 진행"],
        "공무": ["건축 행정 처리", "허가 및 인허가 관리", "건축 규정 준수"],
        "소방설비": ["소방 시설 설치 및 관리", "소방 점검", "안전 규정 준수"],
        "현장보조": ["현장 작업 보조", "자재 운반", "정리 및 청소"],
        "감리원": ["건축 감리 및 검토", "공사 품질 점검", "설계도서 검토"],
        "도시•조경설계": ["도시 계획 설계", "조경 디자인", "환경 조화 설계"],
        "환경기사": ["환경 안전 검사", "환경 오염 관리", "환경 보호 방안 수립"],
        "비파괴검사원": ["비파괴 검사 수행", "검사 보고서 작성", "장비 유지 보수"],
        "공인중개사": ["부동산 매물 관리", "고객 상담 및 중개", "부동산 계약 관리"],
        "감정평가사": ["부동산 가치 평가", "감정 보고서 작성", "시세 조사"],
        "분양매니저": ["부동산 분양 관리", "고객 상담", "분양 계획 수립"]
      },
      
      "미디어•문화•스포츠": {
        "PD•감독": ["프로그램 기획 및 연출", "촬영 및 편집 관리", "방송 스케줄 조정"],
        "포토그래퍼": ["사진 촬영", "이미지 편집", "포토북 제작"],
        "영상편집자": ["영상 편집 및 연출", "효과 추가", "영상 품질 관리"],
        "사운드엔지니어": ["오디오 믹싱", "음향 조정", "녹음 관리"],
        "스태프": ["현장 지원 업무", "장비 관리", "스케줄 조정"],
        "출판•편집": ["출판물 기획", "원고 편집 및 교정", "출판 일정 관리"],
        "배급•제작자": ["콘텐츠 배급", "제작 일정 관리", "비즈니스 협상"],
        "콘텐츠에디터": ["콘텐츠 기획 및 편집", "원고 검토", "콘텐츠 배포"],
        "크리에이터": ["콘텐츠 기획 및 제작", "편집 및 배포", "팬 소통"],
        "기자": ["뉴스 취재 및 보도", "기사 작성", "인터뷰 진행"],
        "작가": ["원고 작성", "스토리텔링 개발", "캐릭터 설정"],
        "아나운서": ["뉴스 및 방송 진행", "대본 읽기", "인터뷰 진행"],
        "리포터•성우": ["현장 취재 및 보도", "성우 녹음", "대본 연기"],
        "MC•쇼호스트": ["행사 진행", "대본 작성", "무대 관리"],
        "모델": ["포즈 및 스타일링", "촬영 참여", "브랜드 홍보"],
        "연예인•매니저": ["스케줄 관리", "팬 소통", "홍보 활동"],
        "인플루언서": ["SNS 콘텐츠 제작", "팔로워 관리", "브랜드 협찬"],
        "통번역사": ["언어 번역 및 통역", "번역 원고 작성", "문화 차이 조정"],
        "큐레이터": ["전시 기획", "예술 작품 관리", "작가 및 작품 설명"],
        "음반기획": ["음반 기획 및 제작", "아티스트 발굴", "음반 프로모션"],
        "스포츠강사": ["스포츠 기술 지도", "운동 프로그램 계획", "개인별 트레이닝"]
      }
  };
  
  export default jobRolesAndTasks;