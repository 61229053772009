import React, { useState } from 'react';
import jobRolesAndTasks from '../pages/tools/resume/categories/job_v1';

const JobCategorySelector = ({ onSelect, onComplete }) => {
  const [selectedMainCategory, setSelectedMainCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedTasks, setSelectedTasks] = useState([]);

  const mainCategories = Object.keys(jobRolesAndTasks);

  const handleMainCategorySelect = (category) => {
    setSelectedMainCategory(category);
    setSelectedSubCategory(null);
    setSelectedTasks([]);
  };

  const handleSubCategorySelect = (subCategory) => {
    setSelectedSubCategory(subCategory);
    setSelectedTasks([]);
  };

  const handleTaskSelect = (task) => {
    if (selectedTasks.includes(task)) {
      setSelectedTasks(prev => prev.filter(t => t !== task));
    } else {
      setSelectedTasks(prev => [...prev, task]);
    }
    
    onSelect({
      mainCategory: selectedMainCategory,
      subCategory: selectedSubCategory,
      task: task
    });
  };

  return (
    <div className="category-selector-wrapper">
      <div className="category-column">
        <h4>직무 카테고리</h4>
        <div className="category-list">
          {mainCategories.map((category) => (
            <div
              key={category}
              className={`category-item ${selectedMainCategory === category ? 'active' : ''}`}
              onClick={() => handleMainCategorySelect(category)}
            >
              {category}
            </div>
          ))}
        </div>
      </div>

      {selectedMainCategory && (
        <div className="category-column">
          <h4>세부 직무</h4>
          <div className="category-list">
            {Object.keys(jobRolesAndTasks[selectedMainCategory]).map((subCategory) => (
              <div
                key={subCategory}
                className={`category-item ${selectedSubCategory === subCategory ? 'active' : ''}`}
                onClick={() => handleSubCategorySelect(subCategory)}
              >
                {subCategory}
              </div>
            ))}
          </div>
        </div>
      )}

      {selectedSubCategory && (
        <div className="category-column">
          <h4>주요 업무</h4>
          <div className="category-list">
            {jobRolesAndTasks[selectedMainCategory][selectedSubCategory].map((task) => (
              <div
                key={task}
                className={`category-item ${selectedTasks.includes(task) ? 'active' : ''}`}
                onClick={() => handleTaskSelect(task)}
              >
                {task}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default JobCategorySelector; 