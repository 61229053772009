import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import { isAuthenticated, removeToken } from '../utils/auth';
import headerLogo from '../assets/images/logos/headerlogo.svg';  // 로고 이미지 import

const Header = () => {
  const navigate = useNavigate();
  const authenticated = isAuthenticated();

  const handleLogout = () => {
    removeToken();
    navigate('/login');
  };

  const handleStartButton = () => {
    if (authenticated) {
      navigate('/main');
    } else {
      navigate('/signup');
    }
  };

  return (
    <header className="header__container">
      <div className="header__logo">
        {/* 로고를 클릭하면 메인(/)으로 이동 */}
        <Link to="/">
          <img src={headerLogo} alt="Jobmaker" />  {/* 로고 이미지로 변경 */}
        </Link>
      </div>
      <nav className="header__nav">
        <Link to="/main" className="header__link">홈</Link>
        <Link to="/recruit/info" className="header__link">취업 정보</Link>
        <Link to="/community" className="header__link">커뮤니티</Link>
        {authenticated && <Link to="/mypage" className="header__link">마이페이지</Link>}
      </nav>
      <div className="header__auth">
        {authenticated ? (
          <button onClick={handleLogout} className="header__link">로그아웃</button>
        ) : (
          <Link to="/login" className="header__link">로그인</Link>
        )}
        <button onClick={handleStartButton} className="header__start-button">
          {authenticated ? '메인페이지' : '시작하기'}
        </button>
      </div>
    </header>
  );
};

export default Header;
