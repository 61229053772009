import React from 'react';

const AdminDashboard = () => {
  return (
    <div className="admin-page">
      <h1>관리자 대시보드</h1>
      <div className="dashboard-content">
        <div className="dashboard-card">
          <h3>전체 사용자</h3>
          <p>통계 준비중</p>
        </div>
        <div className="dashboard-card">
          <h3>전체 게시글</h3>
          <p>통계 준비중</p>
        </div>
        <div className="dashboard-card">
          <h3>미처리 신고</h3>
          <p>통계 준비중</p>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard; 