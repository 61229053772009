import React, { useState } from 'react';

const NoticePage = () => {
  const [noticeData, setNoticeData] = useState({
    title: '',
    content: '',
    importance: 'normal'
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('공지사항 등록 기능 준비중입니다.');
  };

  return (
    <div className="admin-page">
      <h1>공지사항 등록</h1>
      <form onSubmit={handleSubmit} className="notice-form">
        <div className="form-group">
          <label>중요도</label>
          <select 
            value={noticeData.importance}
            onChange={(e) => setNoticeData({...noticeData, importance: e.target.value})}
          >
            <option value="normal">일반</option>
            <option value="high">높은</option>
            <option value="urgent">紧急</option>
          </select>
        </div>
        <div className="form-group">
          <label>제목</label>
          <input 
            type="text"
            value={noticeData.title}
            onChange={(e) => setNoticeData({...noticeData, title: e.target.value})}
          />
        </div>
        <div className="form-group">
          <label>내용</label>
          <textarea 
            value={noticeData.content}
            onChange={(e) => setNoticeData({...noticeData, content: e.target.value})}
          />
        </div>
        <button type="submit">등록하기</button>
      </form>
    </div>
  );
};

export default NoticePage; 