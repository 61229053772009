import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getToken } from '../../utils/auth';
import CommentSection from './CommentSection';
import './PostDetail.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const PostDetail = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [liked, setLiked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAuthor, setIsAuthor] = useState(false);
  const token = getToken();
  const navigate = useNavigate();

  useEffect(() => {
    fetchPostDetail();
    if (token) {
      fetchLikeStatus();
    }
  }, [postId, token]);

  const fetchPostDetail = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/posts/${postId}`);
      if (response.ok) {
        const data = await response.json();
        setPost(data);
        if (token) {
          const decodedToken = JSON.parse(atob(token.split('.')[1]));
          setIsAuthor(data.user._id === decodedToken.user.id);
        }
      } else {
        console.error('게��물을 가져오는데 실패했습니다');
      }
    } catch (error) {
      console.error('게시물 가져오기 오류:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchLikeStatus = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/posts/${postId}/like`, {
        headers: { 'x-auth-token': token }
      });
      if (response.ok) {
        const data = await response.json();
        setLiked(data.liked);
      }
    } catch (error) {
      console.error('좋아요 상태 가져오기 오류:', error);
    }
  };

  const handleLike = async () => {
    if (!token) {
      alert('좋아요를 누르려면 로그인이 필요합니다.');
      return;
    }
    try {
      const response = await fetch(`${BACKEND_URL}/posts/like`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token
        },
        body: JSON.stringify({ postId })
      });
      if (response.ok) {
        const data = await response.json();
        setLiked(data.liked);
        setPost(prev => ({ ...prev, likeCount: data.liked ? prev.likeCount + 1 : prev.likeCount - 1 }));
      }
    } catch (error) {
      console.error('좋아요 처리 오류:', error);
    }
  };

  const handleDelete = async () => {
    if (!token) {
      alert('게시물을 삭제할 권한이 없습니다.');
      return;
    }
    if (window.confirm('정말로 이 게시물을 삭제하시겠습니까?')) {
      try {
        const response = await fetch(`${BACKEND_URL}/posts/${postId}`, {
          method: 'DELETE',
          headers: { 'x-auth-token': token }
        });
        if (response.ok) {
          navigate('/community');
        } else {
          console.error('게시물 삭제에 실패했습니다');
        }
      } catch (error) {
        console.error('게시물 삭제 오류:', error);
      }
    }
  };

  const handleReport = async (targetType, targetId) => {
    if (!token) {
      alert('신고하려면 로그인이 필요합니다.');
      return;
    }

    const reason = prompt('신고 사유를 입력해주세요:');
    if (!reason) return;

    try {
      const response = await fetch(`${BACKEND_URL}/posts/report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token
        },
        body: JSON.stringify({
          targetType,
          targetId,
          reason
        })
      });

      if (response.ok) {
        alert('신고가 접수되었습니다.');
      } else {
        alert('신고 처리 중 오류가 발생했습니다.');
      }
    } catch (error) {
      console.error('신고 처리 오류:', error);
      alert('신고 처리 중 오류가 발생했습니다.');
    }
  };

  if (loading) {
    return <div>로딩 중...</div>;
  }

  if (!post) {
    return <div>게시물이 존재하지 않습니다.</div>;
  }

  return (
    <div className="post-detail-container">
      <Link to="/community" className="back-button">← 전체 게시글</Link>
      <div className="post-detail">
        <div className="post-header">
          <h1 className="post-title">{post.title}</h1>
          <span className="post-date">{new Date(post.createdAt).toLocaleString()}</span>
        </div>
        <div className="post-meta">
          <span className="author">
            {post.user ? post.user.username : '탈퇴한 사용자'}
          </span>
        </div>
        <p className="post-content">{post.text}</p>
        <div className="post-actions">
          <button onClick={handleLike} className={`action-button ${liked ? 'liked' : ''}`} disabled={!token}>
            👍 좋아요 {post.likeCount}
          </button>
          <span className="comment-count">💬 댓글 {post.commentCount}</span>
          <span className="views">👁 조회 {post.views}</span>
          {token && !isAuthor && (
            <button onClick={() => handleReport('post', post._id)} className="report-button">
              🚨 신고
            </button>
          )}
        </div>
        {isAuthor && (
          <div className="author-actions">
            <Link to={`/community/edit/${postId}`} className="edit-button">수정하기</Link>
            <button type="button" onClick={handleDelete} className="delete-button">삭제</button>
          </div>
        )}
      </div>
      <CommentSection postId={postId} isLoggedIn={!!token} />
    </div>
  );
};

export default PostDetail;
