import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { getToken } from '../../utils/auth';
import './SavedCommon.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const SavedResumes = () => {
  const [resumes, setResumes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchResumes = async () => {
      try {
        setIsLoading(true);
        const token = getToken();
        if (!token) {
          navigate('/login');
          return;
        }

        const response = await fetch(`${BACKEND_URL}/tool1/coverletters`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        });

        if (!response.ok) {
          throw new Error('자소서 목록을 불러오는데 실패했습니다.');
        }

        const data = await response.json();
        console.log('Fetched data:', data);
        setResumes(data);
      } catch (error) {
        console.error('Error fetching resumes:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchResumes();
  }, [navigate]);

  const handleResumeClick = async (coverLetterId) => {
    try {
      const token = getToken();
      const response = await fetch(`${BACKEND_URL}/user/getCoverletter/${coverLetterId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      });

      if (response.ok) {
        const data = await response.json();
        let redirectPath;
        try {
          redirectPath = new URL(data.redirectTo).pathname;
        } catch (error) {
          redirectPath = data.redirectTo;
        }
        navigate(redirectPath, { state: { coverLetter: data.coverLetter } });
      }
    } catch (error) {
      console.error('Error fetching cover letter details:', error);
    }
  };

  const handleDelete = async (coverLetterId, event) => {
    event.stopPropagation();
    const confirmDelete = window.confirm('정말로 이 자소서를 삭제하시겠습니까?');
    
    if (confirmDelete) {
      try {
        const token = getToken();
        const response = await fetch(`${BACKEND_URL}/user/deleteCoverletter/${coverLetterId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        });

        if (response.ok) {
          alert('자소서가 성공적으로 삭제되었습니다.');
          setResumes(resumes.filter(resume => resume._id !== coverLetterId));
        }
      } catch (error) {
        console.error('Error deleting cover letter:', error);
      }
    }
  };

  return (
    <div className="saved-container">
      <div className="saved-header">
        <Link to="/mypage" className="back-link">
          <FaArrowLeft className="back-icon" />
          <span>돌아가기</span>
        </Link>
        <h2>자소서 생성기</h2>
      </div>
      
      {isLoading ? (
        <div className="loading-state">
          <p>로딩 중...</p>
        </div>
      ) : error ? (
        <div className="error-state">
          <p>{error}</p>
        </div>
      ) : resumes.length === 0 ? (
        <div className="empty-state">
          <p>저장된 자소서가 없습니다.</p>
          <button 
            onClick={() => navigate('/tools/resume-generator')}
            className="create-button"
          >
            새 자소서 작성하기
          </button>
        </div>
      ) : (
        <div className="item-grid">
          {resumes.map((resume) => (
            <div 
              className="item-card" 
              key={resume._id}
              onClick={() => handleResumeClick(resume._id)}
            >
              <div className="card-header">
                <span className="card-title">{resume.title || '무제'}</span>
                <span className={`status-badge ${resume.infoStatus === 'draft' || resume.contentStatus === 'draft' ? 'draft' : 'published'}`}>
                  {resume.infoStatus === 'draft' || resume.contentStatus === 'draft' ? '임시저장' : '저장됨'}
                </span>
              </div>
              <span className="card-date">
                {new Date(resume.updatedDt).toLocaleDateString()}
              </span>
              <div className="card-actions">
                <button 
                  className="action-button delete-button"
                  onClick={(event) => handleDelete(resume._id, event)}
                >
                  삭제
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SavedResumes;