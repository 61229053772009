import React, { useState, useEffect, useCallback } from 'react';
import { getToken } from '../../utils/auth';
import { useLocation } from 'react-router-dom'; // useLocation 훅을 import
import * as PortOne from "@portone/browser-sdk/v2"; // PortOne SDK import
import './PaymentMethod.css'

const PaymentMethod = () => {
  const location = useLocation();
  const { planTitle, price } = location.state || { planTitle: '', price: 0 };

  // 회원 정보
  const [userid, setUserid] = useState('');       // 유저id
  const [username, setUsername] = useState('');   // 유저명
  const [userEmail, setUserEmail] = useState(''); // 이메일
  // const [tokens, setTokens] = useState(0);        // 사용 가능한 토큰 값

  // 결제 관련
  const [selectedMethod, setSelectedMethod] = useState(''); // 결제방법
  const [termsChecked, setTermsChecked] = useState(false);  // 동의체크여부
  const [loading, setLoading] = useState(false);

  // 환경 변수에서 프론트 백엔드 URL 가져오기
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  

  // 환경 변수에서 키 정보 가져오기
  const storeId = process.env.REACT_APP_PORTONE_STORE_ID;
  const kakaoChannelKey = process.env.REACT_APP_PORTONE_KAKAO_CHANNEL_KEY;
  const naverChannelKey = process.env.REACT_APP_PORTONE_NAVER_CHANNEL_KEY;
  const tossChannelKey = process.env.REACT_APP_PORTONE_TOSS_CHANNEL_KEY;
  const paycoChannelKey = process.env.REACT_APP_PORTONE_PAYCO_CHANNEL_KEY;

  // 유저 정보를 불러오는 함수
  const fetchUserData = useCallback(async () => {
    try {
      const token = getToken();
      const response = await fetch(`${BACKEND_URL}/user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setUserid(data._id);
        setUsername(data.username);
        setUserEmail(data.email);
      } else {
        console.error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [BACKEND_URL]);
  
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  // 결제 방법에 따른 채널 키를 설정하는 함수
  const getChannelKey = (method) => {
    switch (method) {
      case 'KAKAOPAY':
        return kakaoChannelKey;
      case 'NAVERPAY':
        return naverChannelKey;
      case 'TOSSPAY':
        return tossChannelKey;
      case 'PAYCO':
        return paycoChannelKey;
      case 'CARD':
      default:
        return ''; // 카드 결제 시 기본 채널 키 사용 (필요시 수정)
    }
  };

// 결제 방법에 따른 payMethod 설정 함수
const getPayMethod = (method) => {
  return method === 'CARD' ? 'CARD' : 'EASY_PAY';
  };

  const handleMethodChange = (method) => {
    setSelectedMethod(method);
  };

  const handleTermsChange = () => {
    setTermsChecked(!termsChecked);
  };

  const handleSubmit = async () => {
    if (!termsChecked || !selectedMethod) {
      alert('Please select a payment method and agree to the terms.');
      return;
    }

    setLoading(true);
    console.log(selectedMethod);

    try {
      // 결제 요청을 위한 채널 키 설정
      const channelKey = getChannelKey(selectedMethod);
      // 결제 방식
      const paymethod = getPayMethod(selectedMethod);

      // 결제 요청 데이터 설정
      const paymentData = {
        storeId: storeId,  // 환경 변수에서 가져온 Store ID
        channelKey: channelKey,  // 선택된 결제 수단에 따른 채널 키
        paymentId: `payment-${crypto.randomUUID()}`,
        orderName: `${planTitle} 구독 결제`,
        totalAmount: price,
        currency: "KRW",
        payMethod: paymethod,
        customer: {
          customerId: userid, // DB id로 사용
          fullName: username, // 실제 유저 정보 사용
          email: userEmail // 실제 유저 정보 사용
        },
        redirectUrl: "localhost:3000/main", // 결제 성공 후 리다이렉트할 URL
        noticeUrls: [`${BACKEND_URL}/payment/check`]  // 웹훅 URL 설정
      };

      // PortOne 결제 요청
      const response = await PortOne.requestPayment(paymentData);
      console.log(response);

      if (response.code != null) {
        // 결제 요청 실패 시
        alert(`Payment request failed: ${response.message}`);
      } else {
        // 결제 요청 성공 시
        console.log('결제 요청 성공:', response);
      }
    } catch (error) {
      console.error('결제 요청 중 오류 발생:', error);
      alert('결제 요청 중 오류가 발생했습니다. 다시 시도해주세요.');
    } finally {
      setLoading(false);
    }
  };

  return (
      <div className="paymentmethod-container">
        
        <div className="payment-method">
        <h2>결제 방법</h2>
          {/* 구독 정보와 가격을 화면에 표시 */}
          <div className="subscription-info">
            <p>
              구독 정보: {planTitle}&nbsp;&nbsp;
              금액: {price.toLocaleString()}원
            </p>
          </div>

          <div className="payment-options">
            <button
              className={`payment-option ${
                selectedMethod === 'CARD' ? 'active' : ''
              }`}
              onClick={() => handleMethodChange('CARD')}
            >
              신용/체크카드
            </button>
            <button
              className={`payment-option ${
                selectedMethod === 'NAVERPAY' ? 'active' : ''
              }`}
              onClick={() => handleMethodChange('NAVERPAY')}
            >
              네이버페이
            </button>
            <button
              className={`payment-option ${
                selectedMethod === 'KAKAOPAY' ? 'active' : ''
              }`}
              onClick={() => handleMethodChange('KAKAOPAY')}
            >
              카카오페이
            </button>
            <button
              className={`payment-option ${
                selectedMethod === 'TOSSPAY' ? 'active' : ''
              }`}
              onClick={() => handleMethodChange('TOSSPAY')}
            >
              토스페이
            </button>
            <button
              className={`payment-option ${
                selectedMethod === 'PAYCO' ? 'active' : ''
              }`}
              onClick={() => handleMethodChange('PAYCO')}
            >
              페이코
            </button>
          </div>

          <div className="terms">
            <label>
              <input
                type="checkbox"
                checked={termsChecked}
                onChange={handleTermsChange}
              />
              [필수] 결제 서비스 이용 약관, 개인정보 처리 동의
            </label>
          </div>

          <button className="submit-button" onClick={handleSubmit} disabled={loading}>
            {loading ? '결제 요청 중...' : '결제하기'}
          </button>
        </div>
      </div>
  );
};

export default PaymentMethod;