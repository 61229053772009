import React, { useState } from 'react';
import '../styles/components/Skills.css';

const Skills = ({ data, onChange }) => {
  const [newSkill, setNewSkill] = useState({
    category: '',
    items: []
  });
  const [newItem, setNewItem] = useState('');

  const handleAddCategory = () => {
    if (newSkill.category && newSkill.items.length > 0) {
      onChange([...data, { ...newSkill }]);
      setNewSkill({ category: '', items: [] });
      setNewItem('');
    }
  };

  const handleAddItem = () => {
    if (newItem.trim()) {
      setNewSkill({
        ...newSkill,
        items: [...newSkill.items, newItem.trim()]
      });
      setNewItem('');
    }
  };

  const handleDeleteCategory = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    onChange(newData);
  };

  const handleDeleteItem = (categoryIndex, itemIndex) => {
    const newData = [...data];
    newData[categoryIndex].items.splice(itemIndex, 1);
    onChange(newData);
  };

  return (
    <div className="skills-section">
      <h3>보유 기술</h3>
      
      {/* 기존 스킬 목록 */}
      {data.map((category, categoryIndex) => (
        <div key={categoryIndex} className="skill-category">
          <div className="category-header">
            <h4>{category.category}</h4>
            <button onClick={() => handleDeleteCategory(categoryIndex)}>삭제</button>
          </div>
          <div className="skill-items">
            {category.items.map((item, itemIndex) => (
              <div key={itemIndex} className="skill-item">
                <span>{item}</span>
                <button onClick={() => handleDeleteItem(categoryIndex, itemIndex)}>×</button>
              </div>
            ))}
          </div>
        </div>
      ))}

      {/* 새 스킬 추가 폼 */}
      <div className="add-skill-form">
        <input
          type="text"
          value={newSkill.category}
          onChange={(e) => setNewSkill({ ...newSkill, category: e.target.value })}
          placeholder="기술 분야 (예: 프로그래밍 언어)"
        />
        <div className="add-item-form">
          <input
            type="text"
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
            placeholder="기술 항목"
            onKeyPress={(e) => e.key === 'Enter' && handleAddItem()}
          />
          <button onClick={handleAddItem}>항목 추가</button>
        </div>
        <button onClick={handleAddCategory} className="add-category-button">
          기술 분야 추가
        </button>
      </div>
    </div>
  );
};

export default Skills; 