import React, { useState, useRef } from 'react';
import styled from 'styled-components';

const VoiceRecorder = ({ onRecordingComplete }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
        onRecordingComplete(audioBlob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('음성 녹음 에러:', error);
      alert('마이크 접근 권한이 필요합니다.');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
    }
  };

  return (
    <RecorderContainer>
      <RecordButton
        onClick={isRecording ? stopRecording : startRecording}
        isRecording={isRecording}
      >
        {isRecording ? '녹음 중지' : '음성 녹음 시작'}
      </RecordButton>
      
      {audioUrl && (
        <AudioPreview>
          <audio src={audioUrl} controls />
          <RetryButton onClick={() => setAudioUrl(null)}>
            다시 녹음하기
          </RetryButton>
        </AudioPreview>
      )}
    </RecorderContainer>
  );
};

const RecorderContainer = styled.div`
  margin: 1rem 0;
`;

const RecordButton = styled.button`
  padding: 1rem 2rem;
  background: ${props => props.isRecording ? '#ff4444' : '#2E74FF'};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  
  &:hover {
    background: ${props => props.isRecording ? '#cc3333' : '#1b5cd9'};
  }
`;

const AudioPreview = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  
  audio {
    width: 100%;
  }
`;

const RetryButton = styled.button`
  padding: 0.5rem;
  background: #666;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  
  &:hover {
    background: #555;
  }
`;

export default VoiceRecorder; 