import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import './ResumeGeneratorPage1.css';
import { getToken } from '../../../utils/auth';
import JobCategorySelector from '../../../components/JobCategorySelector';
import { Tooltip } from 'react-tooltip';

// 환경 변수에서 백엔드 URL 가져오기
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// SVG 아이콘 컴포넌트들
const JobIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#2E74FF" className="section-icon">
    <path d="M20 7H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16 7V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const ExperienceIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#2E74FF" className="section-icon">
    <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const KeywordIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#2E74FF" className="section-icon">
    <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <line x1="7" y1="7" x2="7.01" y2="7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const ResumeGeneratorPage1 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { coverLetterId } = useParams(); // URL에서 coverLetterId 가져오기
  const [formData, setFormData] = useState({
    title: '',
    jobCategory: '',
    jobTitle: '',
    jobTasks: [],
    companyName: '',
    experience: '',
    keywords: ''
  });

  const [experienceList, setExperienceList] = useState([]);
  const [keywordList, setKeywordList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(''); // 선택한 직종 카테고리
  const [selectedJob, setSelectedJob] = useState(''); // 선택한 직종
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false); // 직종 카테고리 모달 상태
  const [isJobModalOpen, setIsJobModalOpen] = useState(false); // 직종 모달 상태
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    // Check if coverLetter is provided via state
    if (location.state && location.state.coverLetter) {
      const { coverLetter } = location.state;
      setFormData({
        title: coverLetter.title || '',
        jobCategory: coverLetter.jobCategory || '',
        jobTitle: coverLetter.jobTitle || '',
        jobTasks: coverLetter.jobTasks || [],
        companyName: coverLetter.companyName || '',
        experience: '',
        keywords: ''
      });
      setExperienceList(coverLetter.experience ? coverLetter.experience.split(', ') : []);
      setKeywordList(coverLetter.keywords ? coverLetter.keywords.split(', ') : []);
    }
  }, [location.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleAddExperience = () => {
    if (formData.experience.trim()) {
      setExperienceList(prevList => [...prevList, formData.experience]);
      setFormData(prevState => ({ ...prevState, experience: '' }));
    }
  };

  const handleAddKeyword = () => {
    if (formData.keywords.trim()) {
      setKeywordList(prevList => [...prevList, formData.keywords]);
      setFormData(prevState => ({ ...prevState, keywords: '' }));
    }
  };

  const handleKeyPress = (e, fieldType) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (fieldType === 'experience') {
        handleAddExperience();
      } else if (fieldType === 'keywords') {
        handleAddKeyword();
      }
    }
  };

  const handleDeleteExperience = (index) => {
    setExperienceList(prevList => prevList.filter((_, i) => i !== index));
  };

  const handleDeleteKeyword = (index) => {
    setKeywordList(prevList => prevList.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const isFormValid = 
      formData.title.trim() !== '' &&
      formData.jobCategory.trim() !== '' &&
      formData.jobTitle.trim() !== '' &&
      formData.jobTasks.length > 0 &&
      formData.companyName.trim() !== '' &&
      experienceList.length > 0 &&
      keywordList.length > 0;

    setIsButtonDisabled(!isFormValid);
  }, [formData, experienceList, keywordList]);

  const handleSave = async (isTemporary) => {
    // 임시저장이 아닐 경우에만 유효성 검사
    if (!isTemporary) {
      if (formData.title.trim() === '') {
        alert('제목을 입력해 주세요.');
        return;
      }
      if (formData.jobCategory === '') {
        alert('직군을 선택해 주세요.');
        return;
      }
      if (formData.jobTitle === '') {
        alert('직무를 선택해 주세요.');
        return;
      }
      if (formData.jobTasks.length === 0) {
        alert('주요 업무를 1개 이상 선택해 주세요.');
        return;
      }
      if (formData.companyName.trim() === '') {
        alert('기업명을 입력해 주세요.');
        return;
      }
      if (experienceList.length === 0) {
        alert('경력을 1개 이상 입력해 주세요.');
        return;
      }
      if (keywordList.length === 0) {
        alert('키워드를 1개 이상 입력해 주세요.');
        return;
      }
    }

    try {
      const token = getToken(); // 세션 스토리지에서 JWT 토큰 가져오기
      if (!token) {
        alert("로그인이 필요한 서비스입니다.");
        navigate("/login");
      }

      const requestBody = {
        title: formData.title,
        jobCategory: formData.jobCategory,
        jobTitle: formData.jobTitle,
        jobTasks: formData.jobTasks.join(', '),
        companyName: formData.companyName,
        experience: experienceList.join(', '),
        keywords: keywordList.join(', '),
        isTemporary: isTemporary
      };

      // Check if coverLetterId exists and set the appropriate URL
      let url = `${BACKEND_URL}/tool1/save`;
      if (coverLetterId) {
        url = `${BACKEND_URL}/tool1/save/${coverLetterId}`;
      }

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      if (isTemporary) {
        alert('임시저장이 완료되었습니다.');
        navigate('/main');
      } else {
        const newCoverLetterId = result.coverLetterId;
        navigate(`/tools/resume-generator/generate/${newCoverLetterId}`);
      }
    } catch (error) {
      console.error('Error saving cover letter info:', error.message);
    }
  };

  // 모달 열기/닫기 핸들러
  const openCategoryModal = () => setIsCategoryModalOpen(true);
  const closeCategoryModal = () => setIsCategoryModalOpen(false);

  const openJobModal = () => setIsJobModalOpen(true);
  const closeJobModal = () => setIsJobModalOpen(false);

  // 카테고리 선택 핸들러
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedJob('');
    closeCategoryModal(); // 카테고리 선택 후 모달 닫기
    openJobModal(); // 직종 달 열기
  };

  // 종 선택 핸들러
  const handleJobSelect = ({ mainCategory, subCategory, task }) => {
    setFormData(prev => ({
      ...prev,
      jobCategory: mainCategory,
      jobTitle: subCategory,
      jobTasks: prev.jobTasks.includes(task) 
        ? prev.jobTasks.filter(t => t !== task)  // 이미 선택된 task면 제거
        : [...prev.jobTasks, task]               // 새로운 task면 추가
    }));
  };

  return (
    <div className="resume-generator">
      <h2>자소서생성기</h2>
      
      {/* 진행 상태 표시바 추가 */}
      <div className="progress-bar-container">
        <div className="progress-bar">
          <div className="progress-step completed">1</div>
          <div className="progress-step">2</div>
        </div>
      </div>

      <div className="form-group">
        {/* 제목과 기업명 섹션 */}
        <div className="title-company-section">
          <div className="input-card">
            <div className="input-header">
              <label>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#2E74FF" className="section-icon">
                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                제목
              </label>
              <span 
                className="tooltip-icon" 
                data-tooltip-id="title-tip"
                data-tooltip-content="자소서의 제목을 입력해주세요."
              >
                ⓘ
              </span>
            </div>
            <div className="styled-input-group">
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                placeholder="예시) 언제나 사용자 입장에서 생각하는 디자이너입니다."
                maxLength="50"
              />
            </div>
          </div>

          <div className="input-card">
            <div className="input-header">
              <label>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#2E74FF" className="section-icon">
                  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <polyline points="9 22 9 12 15 12 15 22" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                기업명
              </label>
              <span 
                className="tooltip-icon" 
                data-tooltip-id="company-tip"
                data-tooltip-content="지원하고자 하는 기업명을 입력해주세요."
              >
                ⓘ
              </span>
            </div>
            <div className="styled-input-group">
              <input
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                placeholder="예시) 삼성전자"
                maxLength="30"
              />
            </div>
          </div>
          
          {/* 툴팁 컴포넌트 */}
          <Tooltip id="title-tip" place="top" effect="solid" />
          <Tooltip id="company-tip" place="top" effect="solid" />
        </div>

        {/* 직무 선택 */}
        <div className="form-group">
          <div className="input-header">
            <label>
              <JobIcon />
              직무 선택
            </label>
            <span 
              className="tooltip-icon" 
              data-tooltip-id="job-tip"
              data-tooltip-content="지원하고자 하는 직무를 선택하고 관련된 주요 업무를 선택해주세요."
            >
              ⓘ
            </span>
          </div>
          <div className="job-category-container">
            <JobCategorySelector 
              onSelect={handleJobSelect} 
              onComplete={closeJobModal}
            />
          </div>

          {/* 선택된 직무 정보 표시 */}
          {formData.jobCategory && (
            <div className="selected-job-info">
              <div className="job-info-content">
                <div className="job-info-item">
                  <span className="job-label">직군</span>
                  <span className="job-value">{formData.jobCategory}</span>
                </div>
                <div className="job-info-item">
                  <span className="job-label">직무</span>
                  <span className="job-value">{formData.jobTitle}</span>
                </div>
                {formData.jobTasks.length > 0 && (
                  <div className="job-info-item">
                    <span className="job-label">주요 업무</span>
                    <div className="job-tasks">
                      {formData.jobTasks.map((task, index) => (
                        <span key={index} className="task-tag">
                          {task}
                          <button 
                            type="button" 
                            onClick={() => handleJobSelect({ 
                              mainCategory: formData.jobCategory, 
                              subCategory: formData.jobTitle, 
                              task: task 
                            })}
                            aria-label="삭제"
                          >
                            <svg width="14" height="14" viewBox="0 0 16 16">
                              <path d="M12 4l-8 8M4 4l8 8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
                            </svg>
                          </button>
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/* 경력 기술과 키워드 섹션 */}
        <div className="experience-keyword-wrapper">
          <div className="section-container">
            {/* 경력 기술 섹션 */}
            <div className="card-section">
              <div className="section-header">
                <label>
                  <ExperienceIcon />
                  경력 기술
                </label>
                <span 
                  className="tooltip-icon" 
                  data-tooltip-id="experience-tip"
                  data-tooltip-content="보유하고 계신 경력이나 스킬을 입력해주세요. (예: Java 개발 3년, AWS 자격증 보유)"
                >
                  ⓘ
                </span>
              </div>
              <div className="input-group">
                <input
                  type="text"
                  name="experience"
                  value={formData.experience}
                  onChange={handleChange}
                  onKeyPress={(e) => handleKeyPress(e, 'experience')}
                  placeholder="예시) [디자인 툴] 사용 2년"
                  maxLength="30"
                />
                <button type="button" onClick={handleAddExperience}>추가</button>
              </div>
              <div className="tag-list">
                {experienceList.map((exp, index) => (
                  <span key={index} className="tag">
                    {exp}
                    <button type="button" onClick={() => handleDeleteExperience(index)}>×</button>
                  </span>
                ))}
              </div>
            </div>

            {/* 키워드 섹션 */}
            <div className="card-section">
              <div className="section-header">
                <label>
                  <KeywordIcon />
                  키워드
                </label>
                <span 
                  className="tooltip-icon" 
                  data-tooltip-id="keyword-tip"
                  data-tooltip-content="자신을 표현할 수 있는 핵심 키워드를 입력해주세요. (예: 책임감, 열정, 도전정신)"
                >
                  ⓘ
                </span>
              </div>
              <div className="input-group">
                <input
                  type="text"
                  name="keywords"
                  value={formData.keywords}
                  onChange={handleChange}
                  onKeyPress={(e) => handleKeyPress(e, 'keywords')}
                  placeholder="예시) 열정"
                  maxLength="30"
                />
                <button type="button" onClick={handleAddKeyword}>추가</button>
              </div>
              <div className="tag-list">
                {keywordList.map((keyword, index) => (
                  <span key={index} className="tag">
                    {keyword}
                    <button type="button" onClick={() => handleDeleteKeyword(index)}>×</button>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* 툴팁 컴포넌트 */}
        <Tooltip id="experience-tip" place="top" effect="solid" />
        <Tooltip id="keyword-tip" place="top" effect="solid" />

        {/* 섹션 구분선 추가 */}
        <div className="section-divider" />

        {/* Action 버튼 */}
        <div className="action-buttons">
          <button disabled={isButtonDisabled} onClick={() => handleSave(false)}>다음</button>
          <button onClick={() => handleSave(true)}>임시저장</button>
        </div>
      </div>
    </div>
  );
};

export default ResumeGeneratorPage1;