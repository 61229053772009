import React, { useEffect } from 'react';
import '../styles/components/Experience.css';

const Experience = ({ data = [], onChange }) => {
  useEffect(() => {
    // 초기에 데이터가 비어있으면 기본 경력 항목 추가
    if (data.length === 0) {
      onChange([{
        companyName: '',
        position: '',
        startDate: '',
        endDate: '',
        description: ['']
      }]);
    }
  }, []);

  const handleAddExperience = () => {
    onChange([
      ...data,
      {
        companyName: '',
        position: '',
        startDate: '',
        endDate: '',
        description: ['']
      }
    ]);
  };

  const handleRemoveExperience = (index) => {
    const newData = data.filter((_, i) => i !== index);
    onChange(newData);
  };

  const handleExperienceChange = (index, field, value) => {
    const newData = [...data];
    newData[index] = { ...newData[index], [field]: value };
    onChange(newData);
  };

  const handleAddDescription = (experienceIndex) => {
    const newData = [...data];
    newData[experienceIndex].description = [
      ...newData[experienceIndex].description,
      ''
    ];
    onChange(newData);
  };

  const handleRemoveDescription = (experienceIndex, descIndex) => {
    const newData = [...data];
    newData[experienceIndex].description = newData[experienceIndex].description
      .filter((_, i) => i !== descIndex);
    onChange(newData);
  };

  const handleDescriptionChange = (experienceIndex, descIndex, value) => {
    const newData = [...data];
    newData[experienceIndex].description[descIndex] = value;
    onChange(newData);
  };

  return (
    <div className="experience-section">
      <div className="experience-list">
        {data.map((experience, index) => (
          <div key={index} className="experience-item">
            <div className="experience-item-header">
              <h3 className="experience-item-title">경력 사항</h3>
              <p className="experience-item-subtitle">근무하신 회사와 직책에 대한 정보를 입력해주세요.</p>
            </div>
            
            <div className="experience-header">
              <div className="company-info">
                <input
                  type="text"
                  className="company-name-input"
                  placeholder="회사명"
                  value={experience.companyName}
                  onChange={(e) => handleExperienceChange(index, 'companyName', e.target.value)}
                />
                <input
                  type="text"
                  className="position-input"
                  placeholder="직책"
                  value={experience.position}
                  onChange={(e) => handleExperienceChange(index, 'position', e.target.value)}
                />
              </div>
              <button
                className="remove-experience-btn"
                onClick={() => handleRemoveExperience(index)}
              >
                삭제
              </button>
            </div>

            <div className="date-range">
              <input
                type="date"
                className="date-input"
                value={experience.startDate}
                onChange={(e) => handleExperienceChange(index, 'startDate', e.target.value)}
              />
              <span>~</span>
              <input
                type="date"
                className="date-input"
                value={experience.endDate}
                onChange={(e) => handleExperienceChange(index, 'endDate', e.target.value)}
              />
            </div>

            <div className="description-container">
              <div className="description-list">
                {experience.description.map((desc, descIndex) => (
                  <div key={descIndex} className="description-item">
                    <span className="bullet-point">•</span>
                    <textarea
                      className="description-input"
                      placeholder="주요 업무 및 성과를 입력하세요"
                      value={desc}
                      onChange={(e) => handleDescriptionChange(index, descIndex, e.target.value)}
                    />
                    <button
                      className="remove-description-btn"
                      onClick={() => handleRemoveDescription(index, descIndex)}
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
              <button
                className="add-description-btn"
                onClick={() => handleAddDescription(index)}
              >
                + 설명 추가
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="add-experience-wrapper">
        <button className="add-experience-btn" onClick={handleAddExperience}>
          +
        </button>
      </div>
    </div>
  );
};

export default Experience; 